/**
  注意文コンポーネント
*/
<template>
  <span class="c-notice">
    <slot></slot>
  </span>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({

  });
</script>

<style lang="scss">
.c-notice {
  color: $color-notice;
}
</style>
