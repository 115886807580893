/**
  チェックボックスコンポーネント
*/
<template>
  <label class="c-toggle">
    <input type="checkbox" class="c-toggle__input js-toggle" v-bind:data-toggle="values.key" >
    <span class="c-toggle__text">{{values.text}}</span>
  </label>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      values: Object
    }
  });
</script>

<style lang="scss">
.c-toggle {
  $this: &;

  input {
    &[type="checkbox"] {
      display: none;

      &:checked + #{$this}__text {
        background: $color-gray-5;
      }

      &:disabled + #{$this}__text {
        opacity: .7;
      }

      &.-active + #{$this}__text {
        color: $color-white;
        background: $color-blue-3;
      }
    }
  }

  &__text {
    cursor: pointer;
    font-weight: $bold;
    display: block;
    padding: 6px 15px 4px;
    border-radius: 50px;
    background: $color-gray-3;
    transition: background-color $transition, color $transition;

    &.-semi-active {
      background: $color-gray-7 !important;
    }
    &.-active {
      color: $color-white !important;
      background: $color-blue-3 !important;
    }  
  }
}
</style>
