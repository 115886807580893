/*
* Slack投稿用アイコン管理
* depts
  id: 1, name: 営業
  id: 2, name: マーケティング
  id: 3, name: その他
  id: 9, name: 営業戦略
  id: 15, name: イツザイ事業本部
  id: 21, name; 情報システム 

  prefectures
  id: 13, name: 東京都
  id: 23, name: 愛知県
  id: 27, name: 大阪府
  id: 40, name: 福岡県
  id: 48, name: 指定なし
*/
const slackIcons = {
  depts1_prefectures13: 'tokyo_sales.png',
  depts2_prefectures13: 'tokyo_market.png',
  depts9_prefectures13: 'tokyo_strategy.png',
  depts15_prefectures13: 'tokyo_itzai.png',
  depts21_prefectures13: 'tokyo_system.png',
  depts1_prefectures23: 'nagoya_sales.png',
  depts2_prefectures23: 'nagoya_market.png',
  depts9_prefectures23: 'nagoya_strategy.png',
  depts15_prefectures23: 'nagoya_itzai.png',
  depts21_prefectures23: 'nagoya_system.png',
  depts1_prefectures27: 'osaka_sales.png',
  depts2_prefectures27: 'osaka_market.png',
  depts9_prefectures27: 'osaka_strategy.png',
  depts15_prefectures27: 'osaka_itzai.png',
  depts21_prefectures27: 'osaka_system.png',
  depts1_prefectures40: 'fukuoka_sales.png',
  depts2_prefectures40: 'fukuoka_market.png',
  depts9_prefectures40: 'fukuoka_strategy.png',
  depts15_prefectures40: 'fukuoka_itzai.png',
  depts21_prefectures40: 'fukuoka_system.png',
  depts1_prefectures14: 'yokohama_sales.png',
  depts2_prefectures14: 'yokohama_market.png',
  depts9_prefectures14: 'yokohama_strategy.png',
  depts15_prefectures14: 'yokohama_itzai.png',
  depts21_prefectures14: 'yokohama_system.png',
  depts3: 'other.png',
}

export default class {
  constructor(param) {
  
    this.path = '/images/slack_icons/'
    this.img = ''

    if(param.depts_id && param.prefectures_id) {
      const id = `depts${param.depts_id}_prefectures${param.prefectures_id}`
      this.img = slackIcons[id]
    } else {
      this.img = slackIcons.depts3
    }
   
  }
 
  get() {
    return this.path + this.img
  }
}