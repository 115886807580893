/**
  説明コンポーネント
*/
<template>
  <p class="c-info">
    <slot></slot>
  </p>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({

  });
</script>

<style lang="scss">
.c-info {
  @include fz(12);
  margin-top: 4px;
}
</style>
