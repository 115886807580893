<template>
  <router-view></router-view>
  <Loading :class="{ '-active': isLoading }" :loaderText="loaderText"></Loading>
</template>

<script>

  //import gitHead from '../\.git/HEAD';

  // APIの設定など読み込み
  import conf from './sgpost.config';

  // Material Iconsを読み込む
  let head = document.getElementsByTagName("head")[0]
  let MaterialIconsLink = document.createElement("link")
  MaterialIconsLink.setAttribute("href", "https://fonts.googleapis.com/icon?family=Material+Icons")
  MaterialIconsLink.setAttribute("rel", "stylesheet")
  MaterialIconsLink.setAttribute("class", "materialize")
  head.appendChild(MaterialIconsLink)
 
  // SCSSを読み込む
  import './assets/scss/libs/jquery.timepicker.scss';

  import './assets/scss/core/_keyframes.scss';
  import './assets/scss/core/_reset.scss';
  import './assets/scss/core/_common.scss';

  // slackのアイコンを読み込む
  import slackIcons from './assets/js/icons/slackIcons.class.js';

  // JSを読み込む
  import './assets/js/libs/jquery.timepicker.min.js';

  import { defineComponent } from 'vue';
  import axios from 'axios';
  import firebase from 'firebase/compat/app';
  import formDataStore from "./assets/js/formDataStore";
  import { getAuth } from "firebase/auth";

  // コンポーネントを読み込む
  import Loading from './components/organisms/loading.vue';

  // firebaseの設定
  const firebaseConfig = conf.firebaseConfig;

  let envIsProd = location.href.match(/localhost|127\.0\.0\.1|192\.168|^219[\.0-9]+|dev|staging/)
  envIsProd = !envIsProd 

  let version = !envIsProd? conf.devVersion: conf.version;

  const demo = envIsProd ? "" : "Demo"
  const key = envIsProd ? conf.loginKey : conf.loginKeyDemo
  firebase.initializeApp(firebaseConfig);
  const auth = getAuth();

  // サイト名をセット
  formDataStore.commit('setSiteName', document.querySelector('title').textContent);

  // バージョンをセット
  formDataStore.commit('setVersion', version);

  // テスト用のグループに投稿できるアカウント
  formDataStore.commit('setTestAccount', conf.testAccount);

  // 各API URL
  const dbApiURL = conf.dbApiURL;
  const dbApiURLDemo = conf.dbApiURLDemo;
  const loginKey = conf.loginKey;
  const loginKeyDemo = conf.loginKeyDemo;
  const callinDomain = conf.callinDomain;

  formDataStore.commit('setApis', {
    //案件ID検索用URL
    searchSubjectsIDApiURL: conf.searchSubjectsIDApiURL,
    // 読み込むスプレッドシートのURL
    getFormGroupListURL: conf.getFormGroupListURL,

    // 案件ID検索とか理由カテゴリ取得、データ投稿するAPI
    // formDataHandleDev v3.0.0
    getDataApiURL: conf.getDataApiURL,

    // talknote投稿用メールサーバ
    talknotePOSTURL: callinDomain + 'js/tn_post/post.php',

    // slack投稿先URL
    slackPOSTURL: conf.slackPOSTURL,
    
    // slack token
    slackToken: conf.slackToken,

    // slack workspace url 
    slackWorkSpaceURL: conf.slackWorkSpaceURL,

    // カレンダー未反映通知グループURL
    calendarFailureApplyURL: conf.calendarFailureApplyURL,

    // カレンダー未反映通知slackチャンネルID
    calendarFailureApplySlackChannelID: conf.calendarFailureApplySlackChannelID,

    // 外部営業用 アポ獲得slackチャンネルID
    outsideSalesApGainSlackChannelID: conf.outsideSalesApGainSlackChannelID,

    // 外部営業用 リスキャンslackチャンネルID
    outsideSalesRescanSlackChannelID: conf.outsideSalesRescanSlackChannelID,

    // エラー通知slackチャンネルID
    errorPostSlackChannelID: conf.errorPostSlackChannelID,

    // demo 転送チャンネルID [情シス-demo-tnpost-転送1]
    demoForwardChannelID: conf.demoForwardChannelID,

    // カレンダー未反映通知用メールアドレス
    calendarFailureApplyMail: conf.calendarFailureApplyMail,

    // 業種カテゴリ取得用URL
    jobsCategoryJSONDataURL: callinDomain + 'js/v1/data/jobs_category.json',

    //スプレッドシート格納API
    spreadsheet : dbApiURL + "spreadsheet/insert",
    spreadsheetDemo : dbApiURLDemo + "spreadsheet/insert",

    //スプレッドシートアップデートAPI
    spreadsheetUpdate : dbApiURL + "spreadsheet/update",
    spreadsheetUpdateDemo : dbApiURLDemo + "spreadsheet/update",

    //アポ獲得カレンダー作成API
    calCreate : dbApiURL + "cal/create",
    calCreateDemo : dbApiURLDemo + "cal/create",

    //リスキャンカレンダー作成API
    calUpdate : dbApiURL + "cal/update",
    calUpdateDemo : dbApiURLDemo + "cal/update",

    //DB格納API
    dbInsert : dbApiURL + "db/insert",
    dbInsertDemo : dbApiURLDemo + "db/insert",

    //DBデータ取得API
    dbSelect : dbApiURL + "db/select",
    dbSelectDemo : dbApiURLDemo + "db/select",

    //リスキャンDB更新API
    dbUpdate : dbApiURL + "db/update",
    dbUpdateDemo : dbApiURLDemo + "db/update",

    //DBデータ取得API
    kintoneInsert : dbApiURL + "kintone/insert",
    kintoneInsertDemo : dbApiURLDemo + "kintone/insert",

    //ログインキー
    loginKey: loginKey,
    loginKeyDemo: loginKeyDemo,

    dbApiURL: dbApiURL,
    dbApiURLDemo: dbApiURLDemo
  });

  // バージョンチェック
  let versionCheckFlag = 0;
  let versionCheck = function(e) {
    if(versionCheckFlag) return;

    versionCheckFlag = true;

    console.log('バージョンチェック中');

    const verCol = envIsProd? 'version': 'dev_version'

    this.abortController = new AbortController()

    const params = {
      authC: {
        password: "W!z|a~.x.T~Tzca8CBCm",
        mail: 'system@sungrove.co.jp'
      },
      main_data: {
        tables:["sgpost_version"],
        query: `SELECT ${verCol} FROM sgpost_version`
      },
      support_data: {
        is_prod: envIsProd
      }
    }

    const getVesionApiURL = formDataStore.state.apis[`dbSelect${demo}`]

    fetch(getVesionApiURL, {
      signal: this.abortController.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': key
      },
      body: JSON.stringify(params)
    })
    .then(response => response.json())
    .then(function (response) {
      const testver = location.href.match(/testver=([0-9]+\.[0-9]+\.[0-9]+)/);
      let ls = window.localStorage;
      let localUserData = JSON.parse(ls.getItem('userData'));

      if(testver) {
        version = testver[1];
      }

      if(response[0][verCol] != version || localUserData.version != version) {
        alert('更新が必要です。\nwindowsは「Ctrl + F5」  macなら「shift + cmd + R」の\nショートカットキーを押し、リロードしてください。');
        let url = location.href.split('?');
        ls.removeItem('userData');
        ls.userData = JSON.stringify({version: version});
        location.href = url[0];
        // location.reload();
        return;
      }

      console.log('バージョンOK');
      window.removeEventListener('focus', versionCheck, false);
      window.removeEventListener('touchstart', versionCheck, false);
      window.removeEventListener('mousemove', versionCheck, false);
    })
    .catch((e) => {
      console.error(e.stack, e.message)
    })
  }

  window.addEventListener('focus', versionCheck, false);
  window.addEventListener('touchstart', versionCheck, false);
  window.addEventListener('mousemove', versionCheck, false);

  //ログインチェック
  let loginCheck = function(email) {
    const signin_params = {
      mail: email
    }

    const existsApiURL = formDataStore.state.apis[`dbApiURL${demo}`] + "api/exists/user"

    fetch(existsApiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': key
      },
      body: JSON.stringify(signin_params)
    })
    .then(response => response.json())
    .then(function (response) {
      console.log(response)
      if (response != 0) {
        document.body.innerHTML = '<div>このアカウントは使用できません<br>ログアウトします。</div>';
        setTimeout(function(){ 
          window.location.href = '/';
        }, 30000);
      }
    }).catch((e) => {
      console.log(e)
      document.body.innerHTML = '<div>エラーが発生しました<br>ログアウトします。</div>';
      setTimeout(function(){ 
        window.location.href = '/';
      }, 30000);
    })
  }


  export default defineComponent({
    name: 'App',

    components: {
      Loading
    },

    data () {
      return {
        isLogin: false,
        isLayout: false,
        isLoading: false,
        loaderText: '',
        auth: auth,
        userData: {
          id: 0,
          displayName: '',
          email: '',
          userID: 0,
          firstName: '',
          lastName: '',
          BN: '',
          emoji: '',
          depts_id: '',
          prefectures_id: '',
          section_name: '',
          team_name: ''
        },
        groupList: [],
        formType: "",
        version: version,
        dbApiURL: formDataStore.state.apis.dbApiURL,
        dbApiURLDemo: formDataStore.state.apis.dbApiURLDemo
      }
    },

    mounted: async function() {
      let ls = window.localStorage;
      let localUserData = JSON.parse(ls.getItem('userData'));

      if(!localUserData) {
        localUserData = {email: '', version: version};
      }

      this.store = formDataStore;

      await auth.onAuthStateChanged(user => {
        // ログインしたら
        if (user) {
          loginCheck(user.email)
          let noUser = false;
          this.isLogin = true;
          this.userData.displayName = user.displayName;
          this.userData.email = user.email;
          this.userData.version = version;
          this.store.commit('setUserData', ['displayName', user.displayName]);
          this.store.commit('setUserData', ['email', user.email]);

          // ユーザーデータの更新用
          this.modifyUserData = location.href.indexOf('modifyUserData=1') > -1;

          (async () => {
            this.viewLoader('個人データを取得中');

            if(localUserData.email != this.userData.email || localUserData.userID == 0 || this.modifyUserData) {
              // メアドでtalknoteのユーザーID取得

              this.abortController = new AbortController()

              const params = {
                authC: {
                  password: "W!z|a~.x.T~Tzca8CBCm",
                  mail: user.email
                },
                main_data: {
                  tables:["users"],
                  query: `SELECT
                            u.*,
                            s.name section_name,
                            GROUP_CONCAT(t.id) as teams_id,
                            GROUP_CONCAT(t.name) as team_name,
                            GROUP_CONCAT(gt.label_infos_id) as label_infos_ids
                            FROM
                            (
                              SELECT
                                id,
                                tn_id,
                                hire_date,
                                retirement_date,
                                mail,
                                business_name,
                                first_name,
                                last_name,
                                prefectures_id,
                                depts_id,
                                occupations_id,
                                sections_id,
                                slack_user_id,
                                office_id
                              FROM
                                users u
                              WHERE u.mail = "${user.email}" and u.retirement_date is null
                            ) u
                          LEFT JOIN sections s ON u.sections_id = s.id
                          LEFT JOIN group_teams gt ON u.id = gt.users_id AND gt.deleted_at IS NULL
                          LEFT JOIN teams t ON gt.teams_id = t.id
                          GROUP BY
                            u.id, u.hire_date, u.mail, u.business_name, u.first_name, u.last_name,
                            u.prefectures_id, u.depts_id, u.occupations_id, u.sections_id, u.slack_user_id, u.office_id, s.name`
                },
                support_data: {
                  is_prod: 1 // demoでも本番を参照する
                }
              }

              const searchApiURL = formDataStore.state.apis[`dbSelect${demo}`]

              fetch(searchApiURL, {
                signal: this.abortController.signal,
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'X-Cal-St-Api': key
                },
                body: JSON.stringify(params)
              })
              .then(res => res.json())
              .then(res => {
                console.log('個人データを取得', res);

                if(res.length == 0) {
                  alert(`こちらのユーザーは存在しません。
                  ${this.userData.email}`);
                  noUser = true;
                  this.viewLoader();
                  return;
                }

                res = res[0]
                /*
                if(res.retirement_date) {
                  alert('アクセス不可');
                  document.querySelector("html").remove();
                  return 
                }*/
                this.userData.id = res.id;
                this.userData.userID = res.tn_id;
                this.userData.slackUserID = typeof res.slack_user_id == 'undefined'? 'U05KVHTBBSN': res.slack_user_id;
                this.userData.firstName = res.first_name;
                this.userData.lastName = res.last_name;
                this.userData.BN = res.business_name;

                this.userData.depts_id = res.depts_id;
                this.userData.prefectures_id = res.prefectures_id;

                const slackIcon = new slackIcons({
                  depts_id: res.depts_id,
                  prefectures_id: res.prefectures_id,
                }).get();
                this.userData.emoji = conf.domain + slackIcon;
                console.log(slackIcon)
                this.userData.section_name = res.section_name;
                this.userData.team_name = res.team_name;
                this.userData.sections_id = res.sections_id;
                this.userData.teams_id = res.teams_id;
                this.userData.label_infos_ids = res.label_infos_ids;
                this.userData.office_id = res.office_id;

                this.userData.isAdministrator = res.is_administrator;

                this.store.commit('setUserData', ['id', res.id]);
                this.store.commit('setUserData', ['userID', res.tn_id]);
                this.store.commit('setUserData', ['slackUserID', res.slack_user_id]);
                this.store.commit('setUserData', ['firstName', res.first_name]);
                this.store.commit('setUserData', ['lastName', res.last_name]);
                this.store.commit('setUserData', ['BN', res.business_name]);


                this.store.commit('setUserData', ['emoji', conf.domain + slackIcon]);
                this.store.commit('setUserData', ['prefectures_id', res.prefectures_id]);
                this.store.commit('setUserData', ['depts_id', res.depts_id]);
                this.store.commit('setUserData', ['section_name', res.section_name]);
                this.store.commit('setUserData', ['team_name', res.team_name]);
                this.store.commit('setUserData', ['label_infos_ids', res.label_infos_ids]);
                this.store.commit('setUserData', ['sections_id', res.sections_id]);
                this.store.commit('setUserData', ['teams_id', res.teams_id]);
                this.store.commit('setUserData', ['office_id', res.office_id]);

                this.store.commit('setUserData', ['isAdministrator', res.is_administrator]);

                ls.userData = JSON.stringify(this.userData);
                this.store.commit('setFlag', 'userData');
                this.viewLoader();
                this.asyncGetFormList();
                alert('個人データの取得に成功しました');
              })
              .catch(err => {
                alert('個人データの取得に失敗しました。再取得します');
                this.viewLoader();
                document.querySelector("html").remove();
                console.log(err);
              });
            } else {
     
              try {
                this.userData.id = localUserData.id;
                this.userData.email = localUserData.email;
                this.userData.userID = localUserData.userID;
                this.userData.slackUserID = localUserData.slackUserID;
                this.userData.displayName = localUserData.displayName;
                this.userData.firstName = localUserData.firstName;
                this.userData.lastName = localUserData.lastName;
                this.userData.BN = localUserData.BN;
                this.userData.emoji = localUserData.emoji;
                this.userData.prefectures_id = localUserData.prefectures_id;
                this.userData.section_name = localUserData.section_name;
                this.userData.team_name = localUserData.team_name;
                this.userData.label_infos_ids = localUserData.label_infos_ids;
                this.userData.sections_id = localUserData.sections_id;
                this.userData.teams_id = localUserData.teams_id;
                this.userData.office_id = localUserData.office_id;

                this.userData.isAdministrator = localUserData.isAdministrator;
                this.store.commit('setUserData', ['id', localUserData.id]);
                this.store.commit('setUserData', ['userID', localUserData.userID]);
                this.store.commit('setUserData', ['displayName', user.displayName]);
                this.store.commit('setUserData', ['email', localUserData.email]);
                this.store.commit('setUserData', ['userID', localUserData.userID]);
                this.store.commit('setUserData', ['slackUserID', localUserData.slackUserID]);
                this.store.commit('setUserData', ['firstName', localUserData.firstName]);
                this.store.commit('setUserData', ['lastName', localUserData.lastName]);
                this.store.commit('setUserData', ['BN', localUserData.BN]);
                this.store.commit('setUserData', ['emoji', localUserData.emoji]);
                this.store.commit('setUserData', ['prefectures_id', localUserData.prefectures_id]);
                this.store.commit('setUserData', ['section_name', localUserData.section_name]);
                this.store.commit('setUserData', ['team_name', localUserData.team_name]);
                this.store.commit('setUserData', ['label_infos_ids', localUserData.label_infos_ids]);
                this.store.commit('setUserData', ['sections_id', localUserData.sections_id]);
                this.store.commit('setUserData', ['teams_id', localUserData.teams_id]);
                this.store.commit('setUserData', ['office_id', localUserData.office_id]);

                this.store.commit('setUserData', ['isAdministrator', localUserData.isAdministrator]);

                this.store.commit('setFlag', 'userData');
                console.log('個人データをブラウザから取得', localUserData);
                this.viewLoader();
                this.asyncGetFormList();
              } catch(e) {
                alert('ブラウザから個人データの取得に失敗しました');
                document.querySelector("html").remove();
                console.error(e.stack, e.message);
                return 
              };
            }
          })();
        } else {
          if(window.location.href.indexOf('login') == -1) {
            this.$router.push('/login');
          }
        }
      });
    },

    methods: {

      asyncGetFormList() {
        console.log('フォームリスト取得開始')
        this.getFormList();
      },

      async getFormList() {
        // talknoteグループのリスト取得
        let n = 0;
        this.viewLoader('フォームリストを取得中');

        await axios.get(this.store.state.apis.getFormGroupListURL)
        .then(res => {
   
          let data = res.data.values.splice(2, res.data.values.length);
          let ary = [];
          let reg = new RegExp(this.store.state.testAccount.join('|'), 'g');

          for(let i = 0; i < data.length; i++) {
            if(
              data[i][4] == 'しない' || 
               (!this.store.state.userData.email.match(reg) && data[i][0].indexOf('demo_') == 0)
            ) continue;

            if(!data[i][2]) {
              data[i][2] = 'javascript:void(0)';
              data[i][99] = 'javascript:void(0)';
            } else {
              data[i][99] =  '#';
            }

            if (this.store.state.userData.office_id == 7) {
              if (data[i][3] != "アポイントアポインター" && data[i][3] != "リスキャン") {
                continue;
              }
            }

            switch(data[i][3]) {
              case "アポイントアポインター":
              case "アポイント営業":
              case "アポイント運用":
                data[i][99] = `/apgain/${data[i][0]}`;
                break;
              case "アポ報告":
                data[i][99] = `/aporeport/${data[i][0]}`;
                break;
              case "再訪":
                data[i][99] = `/saihou/${data[i][0]}`;
                break;
              case "リスキャン":
                data[i][99] = `/rescan/${data[i][0]}`;
                break;
              case "受注前回答予定案件報告":
                data[i][99] = `/juchuumaekaitou/${data[i][0]}`;
                break;
              case "申込書報告":
                data[i][99] = `/applications/${data[i][0]}`;
                break;
              case "戦略室への要望":
                data[i][99] = `/request-shift/${data[i][0]}`;
                break;
              default:
                // alert('フォームが存在しません。');
                break;
            }

            ary.push(data[i]);
          }

          if(ary.length == 0) {
            let nodata = [];
            nodata[0] = 'アクセスできるグループがありません';
            nodata[1] = '#';
            nodata[2] = 'javascript:void(0)';
            nodata[99] = '#null';
            ary.push(nodata);
          }

          this.groupList = ary;
          this.store.commit('setGroupListData', ary);

          this.isLayout = true;
          this.store.commit('setFlag', 'list');

          this.viewLoader();
     
          if(this.$route.params.form) {
            const form = this.groupList.filter(group => group[0] == this.$route.params.form)[0];
            this.$router.replace('/');
            setTimeout(() => {
              this.viewForm(
                decodeURIComponent(form[0]),
                decodeURIComponent(form[1]),
                decodeURIComponent(form[2]),
                decodeURIComponent(form[3])
              )
            }, 100);
          }
        })
        .catch(err => {
          this.viewLoader();
          console.log(err);
        });

      },

      getFormType(group, url, talknoteGroupAddress, formType) {
        // TypeScriptではthis.$refs.layout.viewFormが参照できないため、as InstanceType<typeofで暗黙的に型変換している
        (this.$refs.layout).viewForm(group, url, talknoteGroupAddress, formType);
      },

      setFormType(formType) {
        this.formType = formType
      },

      logoClick() {
        (this.$refs.layout).reset(this.formType);
      },

      viewLoader(loaderText = '') {
        this.isLoading = !this.isLoading;
        this.loaderText = loaderText;
      },
    }
  });
</script>
