/**
  ボタンコンポーネント
*/
<template>
  <a class="c-button" v-if="tag === 'a'">
    <slot></slot>
  </a>
  <button class="c-button" v-else>
    <slot></slot>
  </button>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      tag: String
    }
  });
</script>

<style lang="scss">
.c-button {
  @include fz(14);
  font-weight: $bold;
  color: $color-white;
  background: $color-primary;
  padding: 14px 20px;
  min-width: 200px;
  max-width: 100%;
  text-align: center;
  display: inline-block;
  transition: opacity $transition;

  @include is-hover {
    opacity: .8;
  }

  &:not(.disabled):not(:disabled) {
    cursor: pointer;
  }

  &.-normal {
    background: $color-gray-4;
  }

  &.-remove {
    background: $color-notice;
  }

  &.-bd {
    color: $color-primary;
    border: 1px solid $color-primary;
    background: transparent;
  }

  &.-bd-normal {
    color: $color-gray-4;
    border: 1px solid $color-gray-4;
    background: transparent;
  }

  &.-bd-remove {
    color: $color-notice;
    border: 1px solid $color-notice;
    background: transparent;
  }

  &:disabled,
  &.disabled {
    background: $color-gray-5;
    opacity: 1;
  }

  &.pulse:not(.disabled):not(:disabled) {
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 99;
      pointer-events: none;
      animation: pulse 1s infinite;
    }
  }

  > * {
    vertical-align: middle;
  }

  &.edit {
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    background: #b80d0d;
    padding: 2px;
    min-width: 30px;
    border-radius: 4px;
    margin-left: 6px;
  }

  &.post {
    font-size: 1rem;
    font-weight: 400;
    color: #fff;
    padding: 2px;
    min-width: 30px;
    margin-left: 6px;
    border-radius: 4px;
  } 
}
</style>
