/*
  商談報告のフォーム用のデータ取得
*/

import formDataStore from "../../formDataStore"
import RequestBody from "../../requestBody.class"

export default class {
  constructor(obj) {
    this.formTypeForRequestBody = 'apoReport'

    this.parentObj = obj
    this.$ = this.parentObj.parentObj.$
    this.postID = obj.postID
    this.subjectsID = obj.subjectsID
    this.params = obj.params
    this.abortController = new AbortController()
    this.isProd = this.parentObj.isProd
    this.slackChannelID = this.postID.split("_")[0]
    this.demo = this.isProd ? "" : "Demo"
    this.key = formDataStore.state.apis[`loginKey${this.demo}`]
    
    // フォーム情報
    this.thisFormParams = this.parentObj.parentObj.vueObj
    .thisFormParams
    //this.groupURL = this.thisFormParams[1] // talknoteグループURL
    //this.groupID = this.groupURL.split('feed/')[1].split('/')[0] // 

    // 各種API
    this.spreadsheetInsertApi = formDataStore.state.apis[`spreadsheet${this.demo}`]
    this.spreadsheetUpdateApi = formDataStore.state.apis[`spreadsheet${this.demo}`].replace('insert', 'update')
    this.spreadsheetGetApi = formDataStore.state.apis[`spreadsheet${this.demo}`].replace('insert', 'get')
    this.DBInsertApi = formDataStore.state.apis[`dbInsert${this.demo}`]
    this.DBUpdateApi = formDataStore.state.apis[`dbUpdate${this.demo}`]

    this.requestFunc = new RequestBody.requestBody()
    this.nowTime = this.requestFunc.nowTime()
    this.createdAt = this.requestFunc.nowTime('created_at')

    // スプレッドシート用
    this.spreadData = this.requestFunc.setSpreadData(this.formTypeForRequestBody, this.isProd)
    this.spreadData.id = this.spreadData.spreadsheetID
    delete this.spreadData.spreadsheetID
    delete this.spreadData.is_prod
    
  }

  /**
   * データ取得
   * 
      ▼スプレッドシート対象カラム
      11 電話番号
      13 URL 
      55 全文（代表者名をスクレイピングで取得）
      
   */
  async getData() {
    // DBから取得　フォームに適用するのはこのデータ
    this.DBData = await this.getDBData()

    if(!this.DBData) {
      return false
    }
    

    // シートから取得　内容を
    await this.getSheetData()

    console.log(this.DBData, this.insertSheetData)
     
    if(this.DBData.length == 0) return false

    return true

  }

  /**
   * 商談報告で編集対象のデータをDBから取得 
   */
  async getDBData() {

    /*this.negotiationReports = sheetData.negotiationReports[0]
    this.negotiationSuggestions = sheetData.negotiationSuggestions
    this.negotiationPositions  = sheetData.negotiationPositions
    console.log(this.negotiationReports)

    this.negotiation_results_id = this.negotiationReports.negotiation_results_id
    return true*/

    try {

      // negotiation_reports
      this.params.main_data = {
        tables: ["negotiation_reports"],
        query: `SELECT * FROM negotiation_reports WHERE post_id = '${this.postID}' AND deleted_at IS NULL`
      }

      this.negotiationReports = await fetch(formDataStore.state.apis[`dbSelect${this.demo}`], {
        method: 'POST',
        signal: this.abortController.signal,
        headers: {
          'Content-Type': 'application/json',
          'X-Cal-St-Api': this.key
        },
        body: JSON.stringify(this.params)
      })
      .then(response => response.json())
      .then(response => {
        return response || false
      })

      this.negotiationReports = this.negotiationReports[0]
      this.report_id = this.negotiationReports.id
      this.negotiation_results_id = this.negotiationReports.negotiation_results_id

      // negotiation_suggestions
      this.params.main_data = {
        tables: ["negotiation_suggestions"],
        query: `SELECT * FROM negotiation_suggestions WHERE negotiation_reports_id = '${this.report_id}' AND deleted_at IS NULL`
      }

      this.negotiationSuggestions = await fetch(formDataStore.state.apis[`dbSelect${this.demo}`], {
        signal: this.abortController.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Cal-St-Api': this.key
        },
        body: JSON.stringify(this.params)
      })
      .then(response => response.json())
      .then(response => {
        return response || false
      })

      // negotiation_positions
      this.params.main_data = {
        tables: ["negotiation_positions"],
        query: `SELECT * FROM negotiation_positions WHERE negotiation_reports_id = '${this.report_id}' AND deleted_at IS NULL`
      }

      this.negotiationPositions = await fetch(formDataStore.state.apis[`dbSelect${this.demo}`], {
        signal: this.abortController.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Cal-St-Api': this.key
        },
        body: JSON.stringify(this.params)
      })
      .then(response => response.json())
      .then(response => {
        return response || false
      })

      
      return true
 
    } catch(e) {
      console.log('データ取得に失敗', e)
      alert('データ取得に失敗しました\nリロードします')
      //location.reload()
    } 

  }

  /**
   * 商談報告で編集対象のデータをスプレッドシートから取得 
   */
  async getSheetData() {   

    this.insertSheetData = {}
/*
    this.insertSheetData = sheetData['455639_tn-post-ee1fa76f'][0]

    return this.insertSheetData
*/
    this.getSheetDataQuery = {
      "spread": this.spreadData,
      "target_lines": [
        {"column": '投稿ID', "value": this.postID},
        {"column": '削除日時', "value": ''},
      ],
    }
 
    await fetch(this.spreadsheetGetApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.getSheetDataQuery)
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.length > 0) {
        this.insertSheetDataTmp = res[0]
        console.log('シートデータ取得成功', this.insertSheetDataTmp )

        for(let key in this.insertSheetDataTmp) {
          this.insertSheetData[key] = {"id": "", "val": this.insertSheetDataTmp[key]}
        }
      } else {
        console.log('該当するシートデータがありません')
        alert('該当するシートデータがありません\nリロードします')
        //location.reload()
        return false
      }
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

    return this.insertSheetData
  }

  
   /**
   * フォームにデータ適用 
   */
  applyDataToForm() {
    const $ = this.$

    
    // モデル
    this.editableItems = this.parentObj.parentObj.vueObj.editableItems
    
    const notEdit = document.querySelectorAll('.js-notEditable') 
    notEdit.forEach((elm) => {
      elm.style.display = 'none'
    })

    $('.autoG, .item.js-editable, .js-editable .item, .form_wrap').slideDown()

    document.querySelector('.tuikyaku.js-editable').style.display = 'none'
    
    // 案件ID
    this.subjectsID = this.negotiationReports.subjects_id
    this.parentObj.parentObj.addSubjectsIDBox(this.negotiationReports.subjects_id)
    this.editableItems.subjectID = this.subjectsID
   
    // 商談結果
    setTimeout(() => {
      const ResultElm = $(`#Result`)
      const ResultElmVal = ResultElm.find(`option[data-id="${this.negotiationReports.negotiation_results_id}"]`).val()
      ResultElm.val(ResultElmVal).trigger('change')

      this.editableItems.ContactDeadline = this.insertSheetData['追客期日'].val.split('/').join('-')
    }, 2000)
    

    // 提案内容　削除と追加
    {
      this.negotiationSuggestions.forEach((obj) => {
        $(`[name="Suggestion"][data-id="${obj.suggestions_id}"]`).click()
      })

      this.suggestToDB = {}

      const elms = document.querySelectorAll(`[name="Suggestion"]`)
      
      elms.forEach((elm, i) => {
        this.suggestToDB[elm.value] = {
          name: elm.value,
          suggestions_id: elm.dataset.id? elm.dataset.id - 0: '',
          initData: elm.checked,
          addFlag: 0,
          deletedFlag: 0
        }

        elm.addEventListener('change', (elm) => {
          const suggest = elm.target.value

          // 選択：true 既存なし
          if(elm.target.checked) { 
            this.suggestToDB[suggest].deletedFlag = 0
            this.suggestToDB[suggest].addFlag = 1
          }

          // 選択：false 既存あり
          if(!elm.target.checked) { 
            this.suggestToDB[suggest].deletedFlag = 1
            this.suggestToDB[suggest].addFlag = 0
          }
          console.log(this.suggestToDB)
        })
      })
    }


    //商談相手　削除と追加
    {
      this.negotiationPositions.forEach((obj) => {
        console.log(obj)
        $(`[name="MtgPerson"][data-id="${obj.client_positions_id}"]`).click()
      })

      this.positionsToDB = {}

      const elms = document.querySelectorAll(`[name="MtgPerson"]`)
      
      elms.forEach((elm, i) => {
        this.positionsToDB[elm.value] = {
          name: elm.value,
          positions_id: elm.dataset.id? elm.dataset.id - 0: '',
          initData: elm.checked,
          addFlag: 0,
          deletedFlag: 0
        }

        elm.addEventListener('change', (elm) => {
          const position = elm.target.value

          // 選択：true 既存なし
          if(elm.target.checked) { 
            this.positionsToDB[position].deletedFlag = 0
            this.positionsToDB[position].addFlag = 1
          }

          // 選択：false 既存あり
          if(!elm.target.checked) { 
            this.positionsToDB[position].deletedFlag = 1
            this.positionsToDB[position].addFlag = 0
          }
        })
      })
    }

    
     // 入金
     // 決済進捗
    setTimeout(() => {
      const PayStatusElm = $(`#PayStatus`)
      const PayStatusElmVal = PayStatusElm.find(`option[data-id="${this.negotiationReports.payment_statuses_id}"]`).val()
      PayStatusElm.val(PayStatusElmVal).trigger('change')

      this.editableItems.SalesPrice = this.insertSheetData['受注金額'].val.replace('円', '')

      this.editableItems.Balance = this.insertSheetData['残高'].val.replace('円', '')

      this.editableItems.BalanceDeadline = this.insertSheetData['残高期日'].val.split('/').join('-')

      $('#wrapEvaluationReason').hide()

      $(`input[name="guide_campaign"][value="${this.insertSheetData['キャンペーン案内有無'].val}"]`).prop('checked', true)

      $(`input[name="guide_subsidy"][value="${this.insertSheetData['助成金の案内有無'].val}"]`).prop('checked', true)

      $('#last_suggestion_price').val(this.insertSheetData['最終提案金額'].val.replace('円', ''))

      $('#passer_name').val(this.insertSheetData['パサー名前'].val)

    }, 2000)

  }


  /**
   * クエリ構築
   */
  buildQuery() {
    /*DB*/
    try {
      // 案件ID
      let editedSubjectID = document.getElementById('editSubjectsID')

      editedSubjectID = editedSubjectID.value
      // 回答期日
      const contactDeadlineDate = this.editableItems.ContactDeadline.split('/').join('-')

      // 提案内容クエリ
      this.buildSuggestionsQuery()
      // 商談相手クエリ
      this.buildPositionsQuery()

      // DB用
      let updateQueryObj = [
        {
          "table_name": "negotiation_reports",
          "non_sub_query": { 
            "set":
            {
              // 案件ID
              "subjects_id": editedSubjectID,
              // 商談結果ID
              "negotiation_results_id": this.parentObj.getOptionsDataset('#Result', 'id') - 0,
              // 回答期日
              "contact_deadline_date": contactDeadlineDate,
            },
            "where": {
              "post_id": this.postID
            }
          },
          "sub_query": {
          },
          "support_data": this.params.support_data,
          "authC": this.params.authC
        }
      ]

      let payment_statuses_id = this.parentObj.getOptionsDataset('#PayStatus', 'id')

      if (payment_statuses_id != undefined) {
        // 支払ステータス
        updateQueryObj[0].non_sub_query.set.payment_statuses_id = payment_statuses_id
      }

      updateQueryObj = [...updateQueryObj, ...this.suggestionsQuery.update, ...this.positionsQuery.update]


      let WeekChars = [ "日", "月", "火", "水", "木", "金", "土" ]
   
      // シート用
      this.insertSheetData['投稿日時'].val = this.createdAt

      this.insertSheetData['案件ID'].val = editedSubjectID

      this.insertSheetData['商談結果'].val = document.querySelector('#Result').value

      this.insertSheetData['追客期日'].val = this.editableItems.ContactDeadline
      
      this.insertSheetData['提案内容'].val = this.suggestionsQuery.sheet

      this.insertSheetData['商談相手'].val = this.positionsQuery.sheet

      this.insertSheetData['決済進捗'].val = document.querySelector('#PayStatus').value

      this.insertSheetData['受注金額'].val = this.editableItems.SalesPrice? this.editableItems.SalesPrice + '円': ''

      this.insertSheetData['残高'].val = this.editableItems.Balance? this.editableItems.Balance + '円': ''
      
      this.insertSheetData['残高期日'].val = this.editableItems.BalanceDeadline.split('-').join('/')

      this.insertSheetData['キャンペーン案内有無'].val = document.querySelector('input[name="guide_campaign"]:checked').value

      this.insertSheetData['助成金の案内有無'].val = document.querySelector('input[name="guide_subsidy"]:checked').value

      this.insertSheetData['最終提案金額'].val = document.querySelector('#last_suggestion_price').value + "円"

      this.insertSheetData['パサー名前'].val = document.querySelector('#passer_name').value
      
      {
        let calText = this.insertSheetData['全文'].val

        calText = calText.replace(/◎案件情報 \[[^\]]+\]/, `◎案件情報 [${formDataStore.state.version}]`)

        calText = calText.replace(/案件ID：[^\\\n]+/, `案件ID：${this.insertSheetData['案件ID'].val}`)

        calText = calText.replace(/商談結果：[^\\\n]+/, `商談結果：${this.insertSheetData['商談結果'].val}`)

        // 曜日追加
        let ContactDeadline = ''
        if(this.editableItems.ContactDeadline != '') {
          ContactDeadline = this.editableItems.ContactDeadline + "（" + WeekChars[new Date(this.editableItems.ContactDeadline).getDay()]+"）"
        }
        calText = calText.replace(/回答期日：[^\\\n]+/, `回答期日：${ContactDeadline}`)

        calText = calText.replace(/提案内容：[^\\\n]+/, `提案内容：${this.suggestionsQuery.sheet}`)

        calText = calText.replace(/商談相手：[^\\\n]+/, `商談相手：${this.positionsQuery.sheet}`)

        calText = calText.replace(/決済進捗：[^\\\n]+/, `決済進捗：${this.insertSheetData['決済進捗'].val}`)

        calText = calText.replace(/受注金額：[^\\\n]+/, `受注金額：${this.insertSheetData['受注金額'].val}`)

        calText = calText.replace(/残高：[^\\\n]+/, `残高：${this.insertSheetData['残高'].val}`)

        // 曜日追加
        let BalanceDeadline = ''
        if(this.editableItems.BalanceDeadline != '') {
          BalanceDeadline = this.editableItems.BalanceDeadline + "（" + WeekChars[new Date(this.editableItems.BalanceDeadline).getDay()]+"）"
        }
        calText = calText.replace(/残高期日：[^\\\n]+/, `残高期日：${BalanceDeadline}`)

        calText = calText.replace(/評価結果：[^\\\n]+/, `評価結果：${this.insertSheetData['アポ評価 - 結果'].val}`)

        this.insertSheetData['全文'].val = calText
      }

      // シート格納用にクエリ生成
      const sheetData = this.requestFunc.createBody(this.formTypeForRequestBody, this.isProd, [this.insertSheetData]) 

      this.query = {
        toDBUpdate: updateQueryObj,
        toDBInsert: [...this.suggestionsQuery.insert, ...this.positionsQuery.insert],
        toSheetUpdate: {
          'spread': sheetData.spread,
          'target_lines': [
            {"column": "投稿ID", "value": this.postID}
          ],
          "update_cells": [
            {"column": "削除日時", "value": this.createdAt},
          ]
        },
        toSheetInsert: sheetData 
  
      }
  console.log(this.query)
      return this.query
    } catch(e) {
      alert('クエリ構築に失敗しました')
      console.log('クエリ構築に失敗しました')
      console.error(e)
    }
  }


  /**
   * 提案内容クエリ構築
   */
  buildSuggestionsQuery() {

    let queries = {
      update: [],
      insert: [],
      sheet: {}
    } 
  
    for(let i in this.suggestToDB) {
      const suggestItem = this.suggestToDB[i]

      // 削除　初期データがある場合のみ
      if(suggestItem.deletedFlag == 1) {
        // 提案内容削除
        let del = {
          "table_name": "negotiation_suggestions",
          "non_sub_query": { 
            "set": {
              "deleted_at": this.createdAt,
            },
            "where": {
              "suggestions_id": suggestItem.suggestions_id,
              "negotiation_reports_id": this.negotiationReports.id - 0, 
            },
          },
          "sub_query": {
          },
          "support_data": this.params.support_data,
          "authC": this.params.authC,
        }

        queries.update.push(del)
      }

      // 追加
      if(suggestItem.addFlag == 1) {

        // 提案内容追加
        if(suggestItem.suggestions_id) {
          let add = {
            "table_name": "negotiation_suggestions",
            "form": {
              "non_sub_query": {
                "negotiation_reports_id": this.negotiationReports.id - 0,
                "suggestions_id": suggestItem.suggestions_id - 0,
                "created_at": this.createdAt,
              },
              "sub_query": {
                "users": false,
              }
            },
            "sub_query": {
            },
            "support_data": this.params.support_data,
            "authC": this.params.authC,
          }

          queries.insert.push(add)
        }
      }
    }

    const suggestionsElms = document.querySelectorAll(`[name="Suggestion"]:checked`)
    let suggestionsToSheet = []
    suggestionsElms.forEach((elm) => {
      suggestionsToSheet.push(elm.value)
    })

    queries.sheet = suggestionsToSheet.join('/')
    this.suggestionsQuery = queries
    console.log(this.suggestionsQuery)
  }

    /**
   * 商談相手クエリ構築
   */
    buildPositionsQuery() {

      let queries = {
        update: [],
        insert: [],
        sheet: {}
      } 
      console.log(this.positionsToDB)
    
      for(let i in this.positionsToDB) {
        const positionsItem = this.positionsToDB[i]
  
        // 削除　初期データがある場合のみ
        if(positionsItem.deletedFlag == 1) {
          // 提案内容削除
          let del = {
            "table_name": "negotiation_positions",
            "non_sub_query": { 
              "set": {
                "deleted_at": this.createdAt,
              },
              "where": {
                "client_positions_id": positionsItem.positions_id - 0,
                "negotiation_reports_id": this.negotiationReports.id - 0, 
              },
            },
            "sub_query": {
            },
            "support_data": this.params.support_data,
            "authC": this.params.authC,
          }
  
          queries.update.push(del)
        }
  
        // 追加
        if(positionsItem.addFlag == 1) {
  
          // 商談相手追加
          if(positionsItem.positions_id) {
            let add = {
              "table_name": "negotiation_positions",
              "form": {
                "non_sub_query": {
                  "negotiation_reports_id": this.negotiationReports.id - 0,
                  "client_positions_id": positionsItem.positions_id - 0,
                  "created_at": this.createdAt,
                },
                "sub_query": {
                  "users": false,
                }
              },
              "sub_query": {
              },
              "support_data": this.params.support_data,
              "authC": this.params.authC,
            }
  
            queries.insert.push(add)
          }
        }
      }

      const positionsElms = document.querySelectorAll(`[name="MtgPerson"]:checked`)
      let positionsToSheet = []
      positionsElms.forEach((elm) => {
        positionsToSheet.push(elm.value)
      })
  
      queries.sheet = positionsToSheet.join('/')
      this.positionsQuery = queries
      console.log(this.positionsQuery)
    }

  /**
   * 論理削除用クエリ構築
   */
  async buildDeleteQuery() {
    // DB用
    const tables = ["negotiation_reports", "negotiation_suggestions", "negotiation_positions"]
    this.delQueries = []

    tables.forEach((val) => {
      const query = {
        "table_name": val,
        "non_sub_query": { 
          "set": {
            "deleted_at": this.createdAt,
          },
          "where": {
            "id": this.report_id
          }
        },
        "sub_query": {
        },
        "support_data": this.params.support_data,
        "authC": this.params.authC,
      }

      if(val != 'negotiation_reports') {
        delete query.non_sub_query.where.id
        query.non_sub_query.where.negotiation_reports_id = this.report_id
      }

      this.delQueries.push(query)
    })

  
    // スプレッドシート用
    this.delSheetQuery = {
      'spread': this.spreadData,
      'target_lines': [
        {"column": "投稿ID", "value": this.postID},
        {"column": "削除日時", "value": ''},
      ],
      "update_cells": [
        {"column": "削除日時", "value": this.createdAt},
      ]
    }


  }

  /*
    DBをアップデート
  */
  async updateDB() {
    return fetch(this.DBUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toDBUpdate)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'DBアップデート失敗 Discordメッセージ確認'
      }

      console.log('DBアップデート成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }

  /*
    DBに挿入
  */
  async insertDB() {
    if(this.query.toDBInsert.length == 0) return true

    return fetch(this.DBInsertApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toDBInsert)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'DB追加失敗 Discordメッセージ確認'
      }

      console.log('DB追加成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }

  /*
    シートをアップデート
  */
  async updateSheet() {
    if(this.query.toSheetUpdate.length == 0) return true

    return fetch(this.spreadsheetUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toSheetUpdate)
    })
    .then((res) => res.text())
    .then((res) => {

      if(res - 0 == 99) {
        throw 'シート更新失敗 Discordメッセージ確認'
      }

      console.log('シート更新成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

  }

  /*
    シートに挿入
  */
  async insertSheet() {
    if(this.query.toSheetInsert.length == 0) return true

    return fetch(this.spreadsheetInsertApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toSheetInsert)
    })
    .then((res) => res.text())
    .then((res) => {

      if(res - 0 == 99) {
        throw 'シート追加失敗 Discordメッセージ確認'
      }

      console.log('シート追加成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

  }

  /*
    DBのデータを論理削除
  */
  async deleteDBData() {

    return fetch(this.DBUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.delQueries)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'DBデータ削除日時セット失敗 Discordメッセージ確認'
      }

      console.log('DBデータ削除日時セット成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

  }

  /*
    シートのデータを論理削除
  */
  async deleteSheetData() {
    return fetch(this.spreadsheetUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.delSheetQuery)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'シートデータ削除日時セット失敗 Discordメッセージ確認'
      }

      console.log('シートデータ削除日時セット成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }
}