class apGainOperation {
  constructor(vueObj, $, func, moment) {

    try {
      this.isProd = vueObj.isProd
      this.vueObj = vueObj // Vueインスタンス
      this.store = vueObj.store
      this.$ = $ // jQuery
      this.func = func // フォーム関連関数
      this.moment = moment // momentインスタンス
      this.thisFormParams = vueObj.thisFormParams // props this.props = this.vueObj.getProps()
      // 投稿先slackチャンネル情報
      this.slackChannel = vueObj.slackChannel
      this.demo = this.isProd ? "" : "Demo"
      this.key = this.store.state.apis[`loginKey${this.demo}`]
      this.getDataApiURL = this.store.state.apis.getDataApiURL // 案件ID検索とか理由カテゴリ取得するAPI
      this.talknotePOSTURL = this.store.state.apis.talknotePOSTURL // talknote投稿用RL
      this.jobsCategoryJSONDataURL = this.store.state.apis.jobsCategoryJSONDataURL // 業種カテゴリをCallinから取得するAPI

      this.groupURL = this.slackChannel.workSpaceURL + this.slackChannel.id

      //this.groupURL = this.thisFormParams[1] // talknoteグループURL
      //this.groupID = this.groupURL.split('feed/')[1].split('/')[0] // talknoteグループURLからグループIDを取得
      this.calendarFailureApplySlackChannelID = this.isProd? this.store.state.apis.calendarFailureApplySlackChannelID: this.store.state.apis.demoForwardChannelID

      this.groupTitle = this.thisFormParams[0].replace(/\n/gi,"").replace(/\t/gi,"") // talknoteグループタイトル
      this.talknoteGroupAddress = this.thisFormParams[2].replace(/\n/gi,"").replace(/\t/gi,"") // talknote投稿先アドレス
      this.talknoteFailureApplyGroupAddress = this.store.state.apis.calendarFailureApplyURL // カレンダー未反映通知グループアドレス
      this.talknoteFailureApplyMailAddress = this.store.state.apis.calendarFailureApplyMail // カレンダー未反映通知メールアドレス

      // 変数定義
      this.CHECKED_COLOR = "teal lighten-2"
      this.NOW_URL = location.href
      this.inputType = ''
      this.inputComName = ''
      this.inputMtgDate = ''
      this.inputMtgDay = ''
      this.inputMtgEndTime = ''
      this.inputMtgStartTime = ''
      this.inputDuration = ''
      this.inputCeoName = ''
      this.inputMtgPerson = ''
      this.inputGender = ''
      this.inputSuggestion = ''
      this.inputApName = ''
      this.inputEvaluation = ''
      this.inputResult = ''
      this.inputContactDeadline = ''
      this.inputContactDeadlineDay = ''
      this.inputSalesPrice = ''
      this.inputPayStatus = ''
      this.inputPaid = ''
      this.inputBalance = ''
      this.inputBalanceDeadline = ''
      this.inputBalanceDeadlineDay = ''
      this.inputEvaluationReason = ''
      this.inputDetail = ''
      this.inputMtgDetail = ''
      this.inputFBGood = ''
      this.inputFBMore = ''
      this.inputCostomerEmail = ''
      this.inputSubjectId = ''
      this.inputRemark = ''
      this.inputRemarks = ''
      this.inputResultId = ''
      this.inputSuggestionId = ''
      this.inputMtgPersonId = ''
      this.inputRecord = ''

      // [残額期日]
      this.today = moment()
      this.today = this.today.format('YYYY-MM-DD')

      this.dataIDs = {
        user_sg_id: '',
        negotiation_result: '',
        suggestion_id: '',
        positions_id: '',
        gender_id: '',
        payment_status_id: '',
        ap_evaluation: ''
      }

      // 業種カテゴリデータ展開格納用
      this.jobsCategoryData = [
        {id: 'hg_rcms', name: 'リロCMS', data: [], options: ''},
        {id: 'hg_rec', name: 'リロEC', data: [], options: ''},
        {id: 'hg_wcms', name: 'ウェルCMS', data: [], options: ''},
        {id: 'hg_wec', name: 'ウェルEC', data: [], options: ''},
        {id: 'webst', name: 'WEB直販', data: [], options: ''},
        {id: 'hg_itzi', name: 'イツザイ', data: [], options: ''},
        {id: 'hg_itzi_lite', name: 'イツザイライト', data: [], options: ''},
        {id: 'hg_subsidy', name: '助成金', data: [], options: ''},
      ]

      // 提案金額範囲設定
      this.costRange = [
        {id: 'normal_other', cost: [3, 10], options: ''},
        {id: 'normal_itzi', cost: [4, 15], options: ''},
        {id: 'outlet_other', cost: [3, 10], options: ''},
        {id: 'outlet_itzi', cost: [4, 15], options: ''},
        {id: 'normal_web', cost: [2, 15], options: ''},
        {id: 'sales', cost: [2, 15], options: ''},
        {id: 'subsidy', cost: [0, 6, 10, 15], options: ''},
      ]

      // 業種カテゴリデータ取得フラグ
      this.is_set_hook_option = false

      // 初期化
      this.init()

    } catch(e) {
      console.error(e.stack, e.message)

      let text = []

      if(!this.vueObj) {
        text.push('Vueインスタンスが取得できませんでした。')
      }

      if(!this.$) {
        text.push('jQueryが取得できませんでした。')
      }

      if(text.length == 0) {
        alert('予期せぬエラーが発生しました。')
      } else {
        alert(text.join('\n'))
      }

      return false
    }

  }

  init() {
    const $ = this.$;

    $('#BalanceDeadline, #ContactDeadline').attr('min', this.today)

    $('.autoG,.gotAP').css('display', 'none')

    this.actionInit()

    this.getJobsCategories()
  }

  actionInit() {
    const that = this
    const $ = this.$
    const func = this.func
    const moment = this.moment


/*
アポ獲得報告(運用)グループ用のファイル
*/

const bg_color = '#ffcdd2'
const color = '#212121'
const checked_color = "teal lighten-2"

var ApGainDataObj = {}
ApGainDataObj.subjects = ''
ApGainDataObj.ap_gains = ''
ApGainDataObj.ap_negotiations = ''
ApGainDataObj.ap_negotiations_outlets = ''
ApGainDataObj.ap_negotiations_necks = ''
let subjects_ary = {}
let ap_gains_ary = {}
let ap_negotiations_ary = {}
let ap_negotiations_outlets_ary = {}
let ap_negotiations_necks_ary = {}

var ApGainTesClosingObj = {} //テスクロ項目のvalとidを格納する配列
ApGainTesClosingObj.bfr_cost_yesno = ''
ApGainTesClosingObj.bfr_cost_goodbad = ''
ApGainTesClosingObj.afr_cost_type = ''
ApGainTesClosingObj.afr_cost_reaction = ''
ApGainTesClosingObj.afr_cost_not_reaction = ''
ApGainTesClosingObj.afr_cost_auto_type = ''
ApGainTesClosingObj.aft_cost_auto_yesno = ''
ApGainTesClosingObj.afr_cost_auto_result = ''
ApGainTesClosingObj.closing_talk_yesno = ''
ApGainTesClosingObj.closing_talk_raction = ''

/*
contentファイル配下の共通関数を記載したファイル
*/


/*
* 初期設定 - フォーム表示で必要なヘッダタグ設定の関数
**/
let setHead = () => {
  const left_menu = document.getElementById("left_menu")
  const url1= "https://company.talknote.com/j6T71X45Nc3fEL7xsCpBxQ/group/feed/227830/" //アポ獲得報告(アポインター用)
  const head = document.getElementsByTagName("head")[0]
  const link2 = document.createElement("link")
  link2.setAttribute("href", "https://fonts.googleapis.com/icon?family=Material+Icons")
  link2.setAttribute("rel", "stylesheet")
  link2.setAttribute("class", "materialize")

  const link3 = document.createElement("link")
  link3.setAttribute("rel", "preconnect")
  link3.setAttribute("href", "https://fonts.googleapis.com")

  const link4 = document.createElement("link")
  link4.setAttribute("rel", "preconnect")
  link4.setAttribute("href", "https://fonts.gstatic.com")
  link4.crossorigin = true

  const link5 = document.createElement("link")
  link5.setAttribute("rel", "stylesheet")
  link5.setAttribute("href", "https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&family=Fredoka+One&display=swap")

  head.appendChild(link2)
  head.appendChild(link3)
  head.appendChild(link4)
  head.appendChild(link5)

  left_menu.addEventListener("click", function() {
    const trgt_url = location.href
    if(trgt_url != url1) {
      $('.materialize').remove()
    }
  })

  $('input[name="q"]').css({
    'border': 'none',
    'width': '230px',
    'height': '20px',
    'font-size': '12px',
    'line-height': '26px',
    'padding-left': '5px',
    'color': '#444'
  })

  //通知一覧materialize解除
  $('#news_topic,#top_search_member_list').css({
    'box-sizing': 'content-box'
  })
}

/*
* 初期設定 - 投稿テンプレ削除関数
**/
let delTemplate = () => {
  const message_text = document.getElementById('message_text')
  message_text.style.display = "none"
  message_text.value = ""
}

/*
* 初期設定 - 投稿ボタン設定関数
**/
let limitBtn = () => {
  const sending_btn = document.getElementById('submit')
  sending_btn.className = 'common_button post table_cell disabled'
  sending_btn.setAttribute('disabled', "disabled")
}

// グループ情報
class Group {

  constructor() {
    this.title = $('.group_title_without_link').text().replace(/\n/gi, "").replace(/\t/gi, "")
    this.group_id = location.href.split('feed/')[1].split('/')[0]

    if(this.title.indexOf('demo') !== -1) {
      this.type = 'テスト'
    } else if (this.title.indexOf('アポ獲得報告') !== -1) {
      this.type = 'アポ獲得'
    } else if (this.title.indexOf('商談報告') !== -1) {
      this.type = '商談報告'
    } else if (this.title.indexOf('リスケ') !== -1) {
      this.type = 'リスキャン'
    } else if (this.title.indexOf('申込') !== -1) {
      this.type = '申込'
    }
  }

}

/*
* 必須項目を設定を外す関数
* @params {str} セレクタのID名
**/
let removeRequiredOp = (idName) => {
  $(`#${idName}`).removeClass("required");
}

/*
* timepicker - 開始・終了時間関数
* @params {str} 開始時間のID名
* @params {str} 終了時間のID名
**/
let startEndTime = (startIdName, endIdName) => {

  $('#' + endIdName).timepicker({
    'timeFormat': 'H:i',
    'step':'15',
    'minTime':'9:00',
    'maxTime':'21:30'
  })

  $('#' + startIdName).timepicker({
    'timeFormat': 'H:i',
    'step':'15',
    'minTime':'8:00',
    'maxTime':'20:00'
  }).on('changeTime', function() {
    let timepicker_start = $('#' + startIdName).val()
    const addedTime = moment('2022-04-01 ' + timepicker_start).add(1, 'h').format('HH:mm')
    $('#' +  endIdName).timepicker({
      'timeFormat': 'H:i',
      'step': '15',
      'minTime': addedTime,
      'maxTime': '21:30'
    })

  })

}

/*
* timepicker - 時間フォーマットの入力制限関数
* @params {str} セレクタ
**/
let checkTime = (selector) => {
  var regTime = /^([01][0-9]|2[0-3]):[0-5][0-9]$/
  var _this = $(selector)
  if (_this.val().match(regTime) != null | _this.val() == "") {
    return 1
  } else {
    return 0
  }
}

//土曜日・日曜日・祝日ならば1、平日ならば0を戻す関数
let checkIsHoliday = (selector) => {
  var date_this = new Date($(selector).val())
  var date_int = date_this.getDay()
  var holiday = JapaneseHolidays.isHoliday(date_this)
  if ((holiday) || (date_int == 0) || (date_int == 6)) {
    return 1
  } else {
    return 0
  }
}

//date_objの週の平日に祝日をDateオブジェクトの配列で戻す関数
let holidayList = (date_obj) => {
  const dateInt = date_obj.getDay()
  const week_sunday = new Date(date_obj.getFullYear(), date_obj.getMonth(), date_obj.getDate() - dateInt)
  let result_list = []

  for (var i = 1; i < 6; i++ ) {
    let weekday_date = new Date(week_sunday.getFullYear(), week_sunday.getMonth(), week_sunday.getDate() +  i)
    let holiday = JapaneseHolidays.isHoliday(weekday_date)

    if (holiday) {
      result_list.push(moment(weekday_date).format("YYYY/MM/DD"))
    }
  }

  return result_list
}


/*
* timepicker - 所要時間を算出する関数
* @params {Str} 開始時間(hh:mm)
* @params {Str} 終了時間(hh:mm)
* @return {Num} 戻り値が0の場合：NG=>開始時間・終了時間の値を空
**/
let duration = (fromT, toT) => {

  const dateFrom = moment('2020-04-01 ' + fromT + ':00') //開始時間
  const dateTo = moment('2020-04-01 ' + toT + ':00') //終了時間
  const diff = dateTo.diff(dateFrom, 'minutes')

  if(Math.sign(diff) == -1) {
    alert('[商談時間] 開始日時は終了日時より前にしてください。')
    $('#ChangeStart').timepicker({
      'timeFormat': 'H:i',
      'step':'15',
      'minTime':'8:00',
      'maxTime':'20:00'
    })
    return 0
  } else {
    return diff
  }

}

/*
* data-*属性を取得する関数
* @param {obj} 取得したいdata-*属性のDOM
* @param {str} * 部分の名前
* @return {ary} data-*の値取得した配列
**/
let getDataAttr = (selecter, AttrName) => {
  let ary = []
  selecter.each(function(i, ele){
    ary.push($(ele).data(AttrName))
  })
  return ary
}

/*
* 指定のセレクタのvalue, data-*属性を取得する関数（※選択肢その他の場合data-id=99にする）
* @param {Obj} 取得したいdata-*属性のDOM
* @param {Str} * 部分の名前
* @param {Str} 「その他」のID名
* @return {Obj} val, data-*の値の取得した配列
**/
let getValData = (selecter, AttrName, OtherIdName) => {
  let ary = {'id': '', 'value': '', 'other': ''}
  selecter.each(function(i, ele) {
    ary.id = $(ele).data(AttrName)
    ary.value = $(ele).val()
    if (ary.id == 99) {
      ary.other = $('#' + OtherIdName).val()
    }
  })
  return ary
}

/*
* 必須項目 - .requiredの値があるか確認する関数
* @return {Ary} valueが0:未入力、1:入力済み
**/
let checkRequired = () => {
  let key = ""
  let requiredArray = {}
  const required_dom = $('.required')
  const bg_color = '#ffcdd2'
  const color = '#212121'

  $('#submit').addClass('disabled').removeClass('pulse')
  $('.required').css({
    'background-color': bg_color,
    'color': color
  })
  // [形式チェック：商談住所]
  let format_flags = ""

  if($('#MtgAdd').prop('required')) {
    const trigger = $('[name="pref_name"]')
    const label_class = '.error_msg_pref'
    const label_tag = '<p class="c-info c-notice error_msg_pref">※ 都道府県名から入力してください</p>'
    const insertbfr = $('[name="pref_name"]')
    format_flags = format_check_pref(trigger, label_class, label_tag, insertbfr)
  }

  for(let num = 0; num < required_dom.length; num++ ) {
    if(required_dom[num].type == 'radio' || required_dom[num].type == 'checkbox') {
      key = required_dom[num].name
      requiredArray[key] = 0
      const name_dom = $('[name=' + key + ']')
      name_dom.next().css({
        'background-color': bg_color,
        'color': color
      })

      if(checkedVal(name_dom) != '') {
        requiredArray[key] = 1
        name_dom.next().css({'background-color': '#ffffff'})
      }

    } else if(required_dom[num].classList.contains('chip')) {
      key = "ResuCanReasonCate"
      requiredArray[key] = 0
      const name_dom = $('[name=' + key + ']')
      name_dom.css({
        'background-color': bg_color,
        'color': color
      })

      if(checked_chip($('div.teal.lighten-2')) != '') {
        requiredArray[key] = 1
        name_dom.css({'background-color': '#e0e0e0'})
      }

    } else {
      key = required_dom[num].id
      requiredArray[key] = 0

      if(required_dom[num].value !='') {
        requiredArray[key] = 1
        required_dom[num].style.backgroundColor = '#ffffff'
      }

    }
  }
  return requiredArray
}

/*
* 値取得 - チェックされた値を取得する関数
* @params {Obj} チェックするセレクタ
* @return {Str} aaa/bbb の形式で返す
**/
let checkedVal = (checkbox_op) => {
  let input_txt = ""
  for (let i = 0; i < checkbox_op.length; i++ ) {

    if( checkbox_op[i].checked ) input_txt += checkbox_op[i].value + "/"

  }
  return input_txt.slice(0, -1)
}

/*
* 値取得 - inpuタグで入力された値を取得する関数
* @params {Obj} チェックするセレクタ
* @return {Str} aaa/bbb の形式で返す
**/
let getInputData = (obj) => {
  let input_txt = ""
  for (let i = 0; i < obj.length; i++ ) {

    if(obj[i].value.indexOf('@') !== -1) input_txt += obj[i].value + "/"

  }
  return input_txt.slice(0, -1)
}

/*
* 変換 - 全角数字 => 半角数字の関数
* @params {Str} 全角数字
* @return {Str} 半角数字
**/
let hankakusuji = (str) => {
  return str.replace(/[０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 65248)
  })
}

/*
* 変換 - 全角英字 => 半角英字の関数
* @params {Str} 全角英字
* @return {Str} 半角英字
**/
let hankaku2Zenkaku = (str) => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
  })
}

/*
* コンマを削除する関数
* @params {Str} コンマあり文字列数字(1,000)
* @return {Num} 半角英字(1000)
**/
let _removeComma = (StrNumber) => {
  const removed = StrNumber.replace(/,/g, '')
  return parseInt(removed, 10)
}

/*
* 変換 - コンマあり数字string型 => コンマありNumber型
* @params {Str} コンマありStr型(1,000)
* @return {Num} コンマありNumber型(1,000)
**/
let commaStr2Num = (str) => {
  str = _removeComma(str)
  str = Number(str).toLocaleString()
  return str
}

/*
* 変換 - コンマ数字にする関数
* @params {Obj} コンマあり数字にするセレクタ
**/
function updateTextView(_obj){
  let num = getNumber(_obj.val());
  if(num == 0) {
    _obj.val('');
  } else {
    _obj.val(num.toLocaleString());
  }
}

function getNumber(_str){
  let arr = _str.split('');
  let out = new Array();
  for(let cnt = 0; cnt < arr.length; cnt++ ){
    if(isNaN(arr[cnt]) == false){
      out.push(arr[cnt]);
    }
  }
  return Number(out.join(''));
}

/*
* 変換 - テキストを処理
* @params {Str or Obj} 変換する文字列 or 連想配列
* @return {Str} 変換された文字列 or 連想配列を返す
**/

let validateText = (strObj) => {
  strObj = replaceQuot(strObj)
  strObj = validateNGWord(strObj)
 
  return strObj
}

/*
* 変換 - シングル・ダブルクォーテーションを全角に変換
* @params {Str or Obj} 変換する文字列 or 連想配列
* @return {Str} 変換された文字列 or 連想配列を返す
**/
let replaceQuot = (strObj) => {

  let type = typeof strObj == 'object' ? classOf(strObj) : 'string'

  switch (type) {
    case 'hash':

      for(let key in strObj) {
        if(typeof strObj[key] == 'number') continue
        strObj[key] = strObj[key].replace(/'/g, "’").replace(/"/g, "”")
      }
      return strObj

    case 'array':

      for(let i = 0; i < strObj.length; i++ ) {
        if(typeof strObj[i] == 'number') continue
        strObj[i] = strObj[i].replace(/'/g, "’").replace(/"/g, "”")
      }
      return strObj

    case 'string':
      return strObj.replace(/'/g, "’").replace(/"/g, "”")
  }
}

/*
* 変換 - NGワードの英字を全角文字に変換
* @params {Str or Obj} 変換する文字列 or 連想配列
* @return {Str} 変換された文字列 or 連想配列を返す
**/

let validateNGWord = (strObj) => {
  
  let reg = new RegExp('^R$|^W$|^SEO$','g')
  let type = typeof strObj == 'object' ? classOf(strObj) : 'string'

  switch (type) {
    case 'hash':

      for(let key in strObj) {
        if(typeof strObj[key] == 'number') continue
        if(strObj[key].match(reg)) {
          strObj[key] = strObj[key].replace(reg, function(s) {
            let str = ''
            for(let i = 0, n = s.length; i < n; i++) {
              str += String.fromCharCode(s.charCodeAt(i) + 0xFEE0);
            }
            return str
          })
        }

      }
      return strObj

    case 'array':

      for(let i = 0; i < strObj.length; i++ ) {
        if(typeof strObj[i] == 'number') continue
          if(strObj[key].match(reg)) {
          strObj[i] = strObj[i].replace(reg, function(s) {
            let str = ''
            for(let i = 0, n = s.length; i < n; i++) {
              str += String.fromCharCode(s.charCodeAt(i) + 0xFEE0);
            }
            return str
          })
        }
      }
      return strObj

    case 'string':

      return strObj.replace(reg, function(s) {
        let str = ''
        for(let i = 0, n = s.length; i < n; i++) {
          str += String.fromCharCode(s.charCodeAt(i) + 0xFEE0);
        }
        return str
      })
  }
}
/*
* 判定 - Arrayかhashかどうかを判定する関数
* @params {Obj} 判定するオブジェクト
* @return {Str} hash or array が返ってくる
**/
let classOf = (obj) => {

  if( (typeof obj) == "object" ) {

      if(obj.length !== undefined) return "array"

      else{ for(let t in obj){
          if(obj[t] !== undefined)return "hash"
          else return "object"
      }}

  }else {
    return (typeof obj)
  }

}

/*
* 文字の正規化をする関数
*  正規化①前後スペース削除
*  正規化②２つ以上のスペースを1つに
*  正規化③文字数が250以下かチェック=>NGは0を返す
* @params {Str} 正規化したいテキスト
* @return {Str} 正規化されたテキスト
**/
let normalizationText = ( text ) => {
  const exist_space_reg = /^[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]|[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]$/
  const space_reg = /[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]/g
  const spaces_reg = /[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]{2,}/g

  if( exist_space_reg.test(text) ) { //前後のスペース削除
    text = text.replace(space_reg, '')
  }

  text = text.replace(spaces_reg, ' ') //二つ以上のスペースを一つに

  if(text.length <= 250) {
    return text
  } else {
    return 0
  }

}

/*
* リセット - 連想配列をリセットする関数
* @params {Obj} リセットする連想配列
**/
let clearAry = (obj) => {
  for(let i in obj) {
    obj[i] = ''
  }
}

/*
* リセット - チェックボックスをリセットする関数
* @params {Obj} セレクタ
**/
let checkOut = (check_names) => {
  for(let i = 0; i < check_names.length; i++ ) {
    check_names[i].checked = false
    check_names[i].disabled = false
  }
}

/*
* リセット - テキストボックスをリセットする関数
* @params {Obj} セレクタ
**/
let clearInput = (clear_array) => {
  for(let i = 0; i < clear_array.length; i++ ) {
    clear_array[i].value = ""
  }
}

/*
* リセット - セレクトボックスをリセットする関数
* @params {Obj} セレクタ
**/
let clearSelect = (clear_array) => {
  for(let i = 0; i < clear_array.length; i++ ) {
    clear_array[i].selectedIndex = 0
  }
}

/*
* バリデーション - 適切に都道府県名が入力されているかチェックする関数
* @params {Obj} チェックするタグのセレクタ
* @params {Str} エラーメッセージのクラス名
* @params {Str} エラーメッセージのHTML要素
* @params {Obj} エラーメッセージを入れるセレクタ
**/
let format_check_pref = (trigger, label_class, label_tag, insertbfr) => {
  let format_flag = "",
  format_flags = ""
  const pref_list = ["青森", "岩手", "宮城", "秋田", "山形", "福島", "茨城", "栃木", "群馬", "埼玉", "千葉", "神奈川", "新潟", "富山", "石川", "福井", "山梨", "長野", "岐阜", "静岡", "愛知", "三重", "滋賀", "兵庫", "奈良", "和歌山", "鳥取", "島根", "岡山", "広島", "山口", "徳島", "香川", "愛媛", "高知", "福岡", "佐賀", "長崎", "熊本", "大分", "宮崎", "鹿児島", "沖縄"]

  if(trigger.next('.c-notice').length > 0) {
    trigger.next('.c-notice').remove()
  }

  for(let i = 0; i < trigger.length; i++ ) {
    if(trigger.get(i).validationMessage == "" && (trigger[i].value.startsWith("北海道") || trigger[i].value.startsWith("東京都") || trigger[i].value.startsWith("大阪府") || trigger[i].value.startsWith("京都府"))) {
      format_flag += "1"
    } else if(trigger.get(i).validationMessage == "") {
      let pref_ken = trigger[i].value.split('県')

      if(pref_list.find((v) => v === pref_ken[0]) == undefined) {
        format_flag += "0"
        $(label_tag).insertAfter(insertbfr[i])
      } else {
        format_flag += "1"
      }

    } else {
      format_flag += "0"
      $(label_tag).insertAfter(insertbfr[i])
    }
  }

  if(format_flag.indexOf('0') == -1) {
    format_flags += "1"
  } else {
    format_flags += "0"
  }
  return format_flags
}

/*
* 削除 - 絵文字を削除する関数
* @params {Str} 絵文字あり文字列
* @return {Str} 絵文字なし文字列
**/
let delEmoji = (Ele) => {
  const emoji_reg = new RegExp(/[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]/, 'g')
  Ele = Ele.replace(emoji_reg, "")
  return Ele
}

/*
* 投稿時 - Talknoteの投稿ボタンクリックする関数
**/
let clickPostBtn = () => {
  let sending_btn = $('#submit')
  sending_btn.addClass('common_button post table_cell')
  sending_btn.removeAttr('disabled')
  sending_btn.click()
  // $('#submit').remove()
}

/*
* 投稿時 - コンテンツファイル => backgroundの関数
* @params {Ary} backgroundへ送信する配列
**/
let send2back = (params) => {
  chrome.runtime.sendMessage(params,
    (receive) => {
      console.log(receive)
    }
  )
}

/*
* 案件ID検索 - タイトルから前確判定する関数
* @params {Str} 判定するタイトル
**/
let checkConfirm_ap = (title) => {
  let splited_title = title.split('///')[0]

  if(splited_title.indexOf('前不') !== -1) {
    return '＊'
  } else if(splited_title.indexOf('★') !== -1) {
    return '★'
  } else if(splited_title.indexOf('☆') !== -1) {
    return '☆'
  }
}

/*
* 案件ID検索 - YYYY年MM月DD日（曜日）HH時mm分〜HH時mm分に変換する関数
* @params {Str} 開始日時
* @params {Str} 終了日時
* @return {Str} 日時
**/
let resDate = (st, et) => {
  let date, day, start, end, mtg_date
  let WeekChars = [ "日", "月", "火", "水", "木", "金", "土" ]
  day = WeekChars[new Date(st).getDay()]
  let format = 'YYYY/MM/DD HH:mm'
  startTime = moment(st).format(format)
  endTime = moment(et).format(format)
  date = startTime.split(' ')[0]
  start = startTime.split(' ')[1]
  end = endTime.split(' ')[1]
  mtg_date = date +  "（" + day + "）" + start + "〜" + end
  return mtg_date
}

/*
* 案件ID検索 - V1(旧)タイトルを要素別に分解
* @params {Obj} 引数objは https://agile-extension-338603.de.r.appspot.com/ のレスポンス
* @return {Obj} 各要素の配列
**/
let v1Title = (obj) => {
  let resultv1 = {}
  let regApName = /\/.*】/

  resultv1.ap_type_name = obj.description.split(/アポ】.*\[/)[0].split('【投稿】')[1].split('【')[1] + 'アポ'
  // ap_type
  let splitedTitle = obj.title.split('///')[0]
  const regApType = /【.*ア\/.*】/

  if(splitedTitle.indexOf('♻') !== -1 || splitedTitle.match(regApType) !== null) {
    resultv1.ap_type = 'アウトレット'
    resultv1.ap_type_name = resultv1.ap_type_name.replace('アポ',resultv1.ap_type + 'アポ')
  } else if(splitedTitle.indexOf('自アポ') !== -1) {
    resultv1.ap_type = '自'
    resultv1.confirm_ap = '-'
    resultv1.ap_type_name = resultv1.ap_type_name
  }else {
    resultv1.ap_type = '通常'
  }

  resultv1.visit_area = ''
  resultv1.subject_id = $('#SubjectId').val()
  let spltEle = ""
  if(splitedTitle.indexOf('自アポ') == -1) {
    resultv1.confirm_ap = checkConfirm_ap(obj.title)
    spltEle = resultv1.confirm_ap
  } else {
    spltEle = '自アポ'
  }
  resultv1.ap_name = obj.title.split(spltEle)[1].split('】')[0].split('/').slice(-1)[0]
  resultv1.com_name = obj.title.split(resultv1.ap_name + '】')[1].split('///')[0]
  resultv1.mtg_date = resDate(obj.starttime,obj.endtime)

  // mtg_type
  let ap_type_name_mtg_type = ""

  if(splitedTitle.indexOf('対面') !== -1) {
    ap_type_name_mtg_type = resultv1.mtg_type = '対面'
  } else if(splitedTitle.indexOf('来社') !== -1 && obj.cal_id == 'sungrove.co.jp_i4bi184g2d2r4jkf1mmi7k0suo@group.calendar.google.com') {
    resultv1.mtg_type = '対面'
    resultv1.visit_area = '関西'
    ap_type_name_mtg_type = resultv1.visit_area +  resultv1.mtg_type
  } else if(splitedTitle.indexOf('来社') !== -1 && obj.cal_id == 'sungrove.co.jp_uvli783d0c6rj70akera24hvkc@group.calendar.google.com') {
    resultv1.mtg_type = '対面'
    resultv1.visit_area = '関東'
    ap_type_name_mtg_type = resultv1.visit_area +  resultv1.mtg_type
  } else if(splitedTitle.indexOf('🎦') !== -1) {
    resultv1.mtg_type = '🎦'
    ap_type_name_mtg_type = 'オンライン'
  }

  if(resultv1.ap_type_name.indexOf('関西来社') !== -1 || resultv1.ap_type_name.indexOf('関東来社') !== -1) {
    resultv1.ap_type_name = resultv1.ap_type_name.replace(/関西来社|関東来社/, ap_type_name_mtg_type)
  } else if(resultv1.ap_type_name.indexOf('対面') !== -1) {
    resultv1.ap_type_name = resultv1.ap_type_name.replace('対面', ap_type_name_mtg_type)
  } else if(resultv1.ap_type_name.indexOf('オンライン') !== -1) {
    resultv1.ap_type_name = resultv1.ap_type_name.replace('オンライン', ap_type_name_mtg_type)
  }

  // hook
  if(resultv1.ap_type_name.indexOf('リロ') !== -1) {
    resultv1.hook = 'リロ'
  } else if(resultv1.ap_type_name.indexOf('ウェル') !== -1) {
    resultv1.hook = 'ウェル'
  } else if(resultv1.ap_type_name.indexOf('イツザイ') !== -1) {
    resultv1.hook = 'イツザイ'
  }else {
    resultv1.hook = 'WEB直販'
  }

  // suggestion
  if(resultv1.ap_type_name.indexOf('イツザイ') !== -1) {
    resultv1.suggestion = 'イツザイ'
  } else if(resultv1.ap_type_name.indexOf('EC') !== -1) {
    resultv1.suggestion = 'EC'
  } else if(resultv1.ap_type_name.indexOf('CMS') !== -1) {
    resultv1.suggestion = 'CMS'
  }
  return resultv1
}

/*
* 案件ID検索 - V2(新)タイトルを要素別に分解
* @params {Obj} 引数objは https://agile-extension-338603.de.r.appspot.com/ のレスポンス
* @return {Obj} 各要素の配列
**/
let v2Title = (obj) => {
  let resultv2 = {}
  let reg_title = /\[.*\/.*\/.*\]/
  let titleEnd = ""
  if (obj.title.indexOf("///*") !== -1) {
    titleEnd = "///*"
  } else if (obj.title.indexOf("*///") !== -1) {
    titleEnd = "*///"
  }
  let main_ele = obj.title.split(titleEnd)[0].split('】')[0].split('【')[0].match(reg_title)[0] //[-/-][☆/通常/W/CMS/対面] の形式
  let ApName_ComName = obj.title.split(main_ele)[1] // 【田中】サングローブ株式会社///*
  resultv2.visit_area = resultv2.jiapo=resultv2.outlet=''
  resultv2.mtg_date = resDate(obj.starttime,obj.endtime)
  resultv2.ap_name = ApName_ComName.split('】')[0].split('【')[1]
  resultv2.com_name = ApName_ComName.split(resultv2.ap_name + '】')[1].split('///*')[0]
  resultv2.subject_id = $('#SubjectId').val()
  let ele1 = obj.title.split('///')[0].split(/\[.{1}\/.{1}\]/)[1].split('【')[0]
  const reg1 = (/\[|\/|\]/g)
  const splited_ele1 = ele1.split(reg1)
  resultv2.confirm_ap = splited_ele1[1]
  resultv2.ap_type = splited_ele1[2]
  resultv2.suggestion = splited_ele1[4]
  resultv2.hook = splited_ele1[3]
  resultv2.mtg_type = splited_ele1[5].replace('🎦', 'オンライン')

  if(resultv2.mtg_type == '来社東') {
    resultv2.mtg_type = '来社'
    resultv2.visit_area = '関東'
  } else if(resultv2.mtg_type == '来社西') {
    resultv2.mtg_type = '来社'
    resultv2.visit_area = '関西'
  }

  if(resultv2.ap_type == 'ア♻') {
    resultv2.ap_type = resultv2.outlet = 'アウトレット'
  } else if(resultv2.ap_type == '自') {
    resultv2.jiapo ='自'
  }

  if(resultv2.hook == 'R') {
    resultv2.hook = 'リロ'
  } else if(resultv2.hook == 'W') {
    resultv2.hook = 'ウェル'
  }

  resultv2.ap_type_name = resultv2.hook +  resultv2.suggestion +  resultv2.visit_area +  resultv2.mtg_type +  resultv2.outlet + resultv2.jiapo + "アポ"

  if(resultv2.hook == "") {
    resultv2.hook  = resultv2.suggestion
  }
  return resultv2
}

/*
* 案件ID検索 - 再訪案件タイトルを要素別に分解
* @params {Obj} 引数objは https://agile-extension-338603.de.r.appspot.com/ のレスポンス
* @return {Obj} 各要素の配列
**/
let saihou = (obj) => {
  let resultS = {
    title:"",
    ap_type:"",
    ap_type_name:"",
    com_name: "",
    mtg_date: "",
    mtg_type: "",
    visit_area:"",
    hook: "",
    suggestion: "",
    confirm_ap:"-",
    outlet:""
  }
  resultS.mtg_date = resDate(obj.starttime, obj.endtime)
  const reg1 = /\[.*\]\[.*\]/g
  const reg2 = /【([^】] + )】/
  const reg3 = /\.*\/(打|商|決|打・決)\]/g
  resultS.ap_type = '再訪'
  resultS.title = obj.title
  resultS.subject_id = $('#SubjectId').val()
  resultS.com_name = obj.title.split(reg3).slice(-1)[0].split('///')[0]
  resultS.mtg_type = obj.title.match(reg1)[0].split('][')[1].split('/')[0].replace('🎦', 'オンライン')
  resultS.ap_type_name = obj.description.match(reg2)[1]

  if(resultS.ap_type_name.indexOf(resultS.mtg_type) == -1 && resultS.mtg_type == 'オンライン') {
    resultS.ap_type_name = resultS.ap_type_name.replace(/対面|来社/,resultS.mtg_type)
  } else if(resultS.ap_type_name.indexOf(resultS.mtg_type) == -1 && resultS.mtg_type == '対面') {
    resultS.ap_type_name = resultS.ap_type_name.replace(/オンライン|来社/,resultS.mtg_type)
  } else if(resultS.ap_type_name.indexOf(resultS.mtg_type) == -1 && resultS.mtg_type == '来社') {
    resultS.ap_type_name = resultS.ap_type_name.replace(/オンライン|対面/,resultS.mtg_type)
  }

  if(resultS.mtg_type.indexOf('来社') !== -1  && obj.cal_id == 'c_kboclvjjfdvspg5ffj6aqut0o0@group.calendar.google.com') {
    resultS.visit_area = '関東'
  } else if(resultS.mtg_type.indexOf('来社') !== -1 && obj.cal_id == 'c_ikpv1a5sm3ugvjdumslq7gjf6c@group.calendar.google.com') {
    resultS.visit_area = '関西'
  }
  return resultS
}

/*
* 案件ID検索 - 新旧リスケ案件を判定
* @params {Obj} 引数objは https://agile-extension-338603.de.r.appspot.com/ のレスポンス
* @return {Num} 旧リスケ案件あり、99は旧リスケ案件なし
**/
let checkCalID = (obj) => {
  let result = {}
  let index_nomal = ""
  const regSaihou = /\[.*再.*\]/
  for(var i = 0; i < obj.length; i++ ) {
    if(obj[i].cal_id == 'c_uflhjofuk794pidil3ic94itlg@group.calendar.google.com' && ! regSaihou.test(obj[i].title)) { //リスキャンカレンダー(再訪以外)
      result.exits_rc = 1
    } else if(obj[i].cal_id !== 'c_ikpv1a5sm3ugvjdumslq7gjf6c@group.calendar.google.com' && obj[i].cal_id !== 'c_kboclvjjfdvspg5ffj6aqut0o0@group.calendar.google.com' && obj[i].cal_id !== 'c_uflhjofuk794pidil3ic94itlg@group.calendar.google.com') {
      result.exits_nomarl = 1
      index_nomal = i
    }

    if(obj[i].title.indexOf('使用不可') !== -1) {
      result.do_not_use = 1
      index_nomal = i
    }
  }

  if((result.exits_rc == 1 && result.exits_nomarl == 1) || result.do_not_use == 1 ) {
    return index_nomal
  } else {
    return 99
  }
}

/*
* 案件ID検索 - ラジオボタン追加：複数案件が見つかった時に利用
* @params {Obj} 引数objは https://agile-extension-338603.de.r.appspot.com/ のレスポンス
**/
function createSubjectList(res) {
  for(let i = 0; i < res.length; i++ ) {
    let ele = ""
    let title = res[i].title
    res[i].title = replaceQuot(title)
    const regSaihou = /\[.*再.*\]/

    if(title.indexOf('///*') !== -1 || title.indexOf('*///') !== -1) {
      ele = v2Title(res[i])
    } else if(res[i].cal_id == 'c_ikpv1a5sm3ugvjdumslq7gjf6c@group.calendar.google.com' || res[i].cal_id == 'c_kboclvjjfdvspg5ffj6aqut0o0@group.calendar.google.com' || res[i].cal_id == 'c_rbgto9nqiqt9746qmj99bsvat0@group.calendar.google.com' || res[i].cal_id == 'c_e9acd590524711b7eccd4bdc65a453cabfa16561b30a482a4b578b4b47c542a9@group.calendar.google.com' || (res[i].cal_id == 'c_uflhjofuk794pidil3ic94itlg@group.calendar.google.com' && regSaihou.test(title))) {
      ele = saihou(res[i])
    } else if(title.indexOf('///') !== -1) {
      ele = v1Title(res[i])
    }

    const ele_input = '<input class="required" type="radio" name="ListEvents" value="' + i + '" data-about=\'{\"company_name\":\"' + ele.com_name + '\",\"cal_name\":\"' + res[i].cal_name + '\",\"mtg_date\":\"' + ele.mtg_date + '\",\"ap_type_name\":\"' + ele.ap_type_name + '\", \"subject_id\":\"' + ele.subject_id +'\", \"ap_name\":\"' + ele.ap_name + '\"}\' data-res=\'{\"cal_id\":\"' + res[i].cal_id + '\",\"event_id\":\"' + res[i].event_id + '\",\"mtg_type\":\"' + ele.mtg_type + '\",\"mtg_add\":\"' + res[i].location + '\",\"hook\":\"' + ele.hook + '\",\"suggestion\":\"' + ele.suggestion + '\",\"ap_type\":\"' + ele.ap_type + '\",\"visit_area\":\"' + ele.visit_area + '\",\"confirm_ap\":\"' + ele.confirm_ap + '\"}\'>'
    const ele_span = '<span >' + title + '</span>'
    $('<label class="pd5_rl events">' + ele_input + ele_span + '</label>').appendTo("#result_titles")
  }
}

var numPyphen = function (str) {
  return str.replace(/[０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 65248)
  }).replace(/[‐－―ー]/g, '-')
}

function initializeVariableAPgain() {
  clearAry(ApGainDataObj)
  clearAry(ApGainTesClosingObj)
  clearAry(subjects_ary)
  clearAry(ap_gains_ary)
  clearAry(ap_negotiations_ary)
  clearAry(ap_negotiations_outlets_ary)
  clearAry(ap_negotiations_necks_ary)
}


function clearChips() {
  $('.chip').removeClass('grey teal lighten-2')
  $('[id^="neck_"]').hide(400)
  $('[id^="neck_"]').removeClass("down")
  $('[id^="neck_"]').addClass("up")
}

function resetAddDel() {
  var copy_form_name = $(this).attr('id')
  var target = $(this).parent()
  var label = target.prev('label')

  if ($('#add_del_input_' +  copy_form_name).find('div').length > 1) {
    target.remove()
    label.remove()
  }
}

function initSuggestionPrice() {
  $('#sp_nondisplay').css('display', 'none')
  $('#s_price_btn').text('値段幅あり')
  $('#sp2_select').removeClass('required') //必須項目解除

  $('#sp1_select').css({
    'background-color': bg_color,
    'color': color
  })

  //値幅値クリア
  $('[name=suggestion_price2]').val('')
  $('[name=suggestion_price1]').val('')
  $('.sp_btn1').attr('class', 'waves-effect waves-light btn-small sp_btn')
}

function clearAllOption() {
  clearInput($('#form_ap').find('input[type="text"], textarea, input[type="tel"], input[type="url"], input[type="date"], input[type="Number"]'))
  clearSelect($('#form_ap').find('select'))
  checkOut($('#form_ap').find('input[type="radio"], input[type="checkbox"]'))
  clearChips()
  initSuggestionPrice()

  $('#ap_doing_date').prop('readonly', false)
  $('#outlet_reasons, #f_previous_ap_name, #add_del_input_url1, #field_prospect_person, #field_prospect_day, #f_device, #visit_area, #place_info, .fild_tescro').slideUp(100) //代電ID・初回見込み日時・使用デバイス・来社エリア・商談住所・最寄り駅・バス
  $('[id^="add_del_input_"]').find($('div.copyEle')).remove() //増減input削除
  $('#prospect_day, #prospect_person, [name="outlet"], [name="gender"], #device, #area_kanto, #pref_name2, #station, #station_min, #previous_ap_name, [name="hp_url"], .tescro')
  .removeClass('required').removeAttr('required',false).css({'background-color': ''})
  $('.int_required_color').removeClass('required_color').css("background", "")

  $('[name="cate_ops"]').css('display', 'none') //カテゴリ・業種非表示
  $('#f_suggestion_price_5').text('')

  $('#ap_start_time').timepicker({ //アポ時間初期化
    'timeFormat': 'H:i',
    'step':'15',
    'minTime':'8:00',
    'maxTime':'20:00'
  })
}

document.onclick = (e) => {
  let target = e.target;

  if(target.classList.contains('js-item-add')) {
    let parent = target.closest('.js-variables');
    let max = parent.dataset.max;
    let count = parent.childElementCount;

    if(count < max || undefined === max) {
      let clone = target.closest('.js-variables-item').cloneNode(true);
      clone.querySelectorAll('input').forEach((input) => {
        input.value = '';
      });
      clone.querySelector('.js-item-remove').disabled = false;
      parent.appendChild(clone);
    }
  }
  else if(target.classList.contains('js-item-remove')) {
    let parent = target.closest('.js-variables');
    let min = parent.dataset.min === undefined ? 1 : parent.dataset.min;
    let count = parent.childElementCount;
    let item = target.closest('.js-variables-item');

    if(count > min) {
      parent.removeChild(item);
    }
  }
};

document.querySelectorAll('.js-toggle').forEach((toggle) => {
  toggle.onchange = () => {
    let type = toggle.type;
    let name = toggle.name;

    if(type == 'checkbox') {
      let target = document.querySelector('[data-toggle-target="' +  toggle.dataset.toggle +  '"]');

      if(!target) return;

      if(toggle.checked) {
        target.style.display = 'block';
      }
      else {
        if(!toggle.classList.contains("-active")) {
          target.style.display = 'none';
        }
        else {
          toggle.checked = true;
        }
      }
    }
    else if(type == 'radio') {
      document.querySelectorAll('[name="' +  name +  '"]').forEach((radio) => {
        let target = document.querySelector('[data-toggle-target="' +  radio.dataset.toggle +  '"]');

        if(!target) return;

        if(radio.checked) {
          target.style.display = 'block';
        }
        else {
          target.style.display = 'none';
        }
      });
    }
  };
});

document.querySelectorAll('.js-toggle-target [type="checkbox"]').forEach((checkbox) => {
  checkbox.onchange = () => {
    let parent = checkbox.closest('.js-toggle-target');
    let length = parent.querySelectorAll('[type="checkbox"]:checked').length;
    let toggle = document.querySelector('[data-toggle="' +  parent.dataset.toggleTarget +  '"]');

    if(!toggle) return;

    if(length > 0) {
      toggle.classList.add('-active');
    }
    else {
      toggle.classList.remove('-active');
    }
  };
});

document.querySelectorAll('.js-toggle-target textarea').forEach((textarea) => {
  textarea.oninput = () => {
    let parent = textarea.closest('.js-toggle-target');
    let toggle = document.querySelector('[data-toggle="' +  parent.dataset.toggleTarget +  '"]');

    if(!toggle) return;

    if(textarea.value != '') {
      toggle.classList.add('-active');
    }
    else {
      toggle.classList.remove('-active');
    }
  };
});

document.querySelectorAll('.js-sync-checkbox').forEach((checkbox) => {
  checkbox.onchange = () => {
    document.querySelectorAll('[data-for="' +  checkbox.id +  '"]').forEach((sync) => {
      if(!sync) return;

      if(checkbox.checked) {
        sync.checked = true;
      }
      else {
        sync.checked = false;
      }
    });
  };
});

/*
アポ獲得報告(運用)グループ用のファイル
*/

    //チェックボックス確認function
    function check(checkbox_op) {
      var input_txt = ""

      for ( let i = 0; i < checkbox_op.length; i++ ) {
        if ( checkbox_op[i].checked ) {
          input_txt += checkbox_op[i].value + "/"
        }
      }
      return input_txt
    }

    // var now_url = location.href
    // var talknote_group = {
    //   id: now_url.split('feed/')[1].split('/')[0],
    //   name: "ot",
    //   title: $('.group_title_without_link').text().replace(/\n/gi,"").replace(/\t/gi,"")
    // }

    let form_group_name = "ap"
    if(that.groupTitle.indexOf('自営') > -1) form_group_name = "sales"
    if(that.groupTitle.indexOf('派遣') > -1) form_group_name = "tw"


    $('select').css("display","block")
    var input_ap_type = document.getElementById("ap_type")

    // アポ種別名
    $('#outlet_flag, [name="hook"], [name="suggestion"], #bussiness_formats,  #pref_name2,  #visit_area').off('input.flag change.flag click.flag').on('input.flag change.flag click.flag', function() {
      //フック・提案内容・商談形式
      var hook_1 = checkedVal(document.getElementsByName("hook"))
      var suggestion_1 = checkedVal(document.getElementsByName("suggestion"))
      var bf = $('#bussiness_formats').val()

      if( hook_1 != "" && suggestion_1 != "" && bf != null) {
        var ap_type_name = ""
        var array_block = {hook:"", suggestions:"", bussiness_format:"", area:"", outlet:""}

        array_block.hook = $('[name="f_hook"]').find('input[value="' + hook_1 + '"]').data('type')
        array_block.suggestions = suggestion_1
        array_block.bussiness_format = $('#bussiness_formats').find('option[value="' + bf + '"]').data('type')

        if(array_block.bussiness_format == "対面") {
          var pref_taimen = $('#pref_name2').val()
        }

        if(array_block.bussiness_format == "対面" && (pref_taimen.startsWith("大阪府") == true || pref_taimen.startsWith("滋賀") == true || pref_taimen.startsWith("兵庫") == true || pref_taimen.startsWith("和歌山") == true || pref_taimen.startsWith("京都府") == true || pref_taimen.startsWith("奈良") == true)) {
          array_block.area = "関西"
        } else if(array_block.bussiness_format == "対面" && (pref_taimen.startsWith("東京都")  == true || pref_taimen.startsWith("埼玉") == true || pref_taimen.startsWith("つくば") == true || pref_taimen.startsWith("千葉") == true || pref_taimen.startsWith("茨城") == true || pref_taimen.startsWith("神奈川") == true)) {
          array_block.area = "関東"
        }

        if(array_block.bussiness_format == "来社") {
          var visit_area = checkedVal($('[name="area"]'))
          array_block.area = $('#visit_area').find('input[value="' + visit_area + '"]').data('type')
        }

        if($('#outlet_flag').prop('checked')) {
          array_block.outlet = 'アウトレット'
        }

        if(array_block.hook == array_block.suggestions) { //イツザイ対応
          array_block.suggestions = ""
        }

        ap_type_name = array_block.hook +  array_block.suggestions +  array_block.area + array_block.bussiness_format +  array_block.outlet
        $('#ap_type').val(ap_type_name)
      }
    })

    // [所在地：フック制限]
    var r_ng_area = ['愛知県','三重県','岐阜県','香川県','徳島県','愛媛県','高知県','広島県','岡山県','山口県','島根県','鳥取県','福岡県','大分県','佐賀県','長崎県','鹿児島県','熊本県','宮崎県','沖縄県']

    $(document).on('input', '#address_all1', function() {
      $('#hook_r').prop('disabled', false)

      for(let i = 0; i < r_ng_area.length; i++ ) {
        if($('#address_all1').val().indexOf(r_ng_area[i]) != -1 ) {
          $('#hook_r').prop('disabled', true)
          $('#hook_r').prop('checked', false)
        }
      }
    })

  //[アポ日程：デフォ明日]
  var tomo = moment().add(1, 'days')
  let format = 'YYYY-MM-DD'
  let formated_tomo = tomo.format(format)
  $('#ap_doing_date').attr('min',formated_tomo)
  let momentToday = moment()

  //[当アポ自動入力]
  $('#today_ap').off('click.today_ap').on('click.today_ap',function() {
    if($('#today_ap').prop('checked')) {
      let formated_today = momentToday.format(format)
      $('#gender_women').addClass("required")
      $('#ap_doing_date').val(formated_today)
      $('#ap_doing_date').attr('readonly',true)
    } else {
      $('#gender_women').removeClass("required")
      $('#ap_doing_date').val("")
      $('#ap_doing_date').attr('readonly',false)
      $('.genderSpan').css({'background-color': '#ffffff'})
    }
  })

  $(document).on('input', '#add_del_input_tel1', function() {
    handleTelInput("tel")
  })

  $(document).on('input', '.copy_form_tel1', function() {
    $('.copyEle[name="tel"]').addClass('required')
    handleTelInput("tel")
    let inputValue = $(this).find('input[name="tel"]').val()

    if (inputValue == "") {
      $(this).find('input[name="tel"]').removeClass('required')
    }
  })

  $(document).on('input', '#add_del_input_other_tel1', function() {
    $('.other_tel_input').addClass('required')
    handleTelInput("other_tel_input")

    if ($('.other_tel_input').val() == "") {
      $('.other_tel_input').removeClass('required')
    }
  })

  $(document).on('input', '.copy_form_other_tel1', function() {
    $('.copyEle[name="other_tel_input"]').addClass('required')
    handleTelInput("other_tel_input")
    let inputValue = $(this).find('input[name="other_tel_input"]').val()

    if (inputValue == "") {
      $(this).find('input[name="other_tel_input"]').removeClass('required')
    }
  })

  function handleTelInput(inputName) {
    var tels = document.getElementsByName(inputName)

    for(let i = 0; i < tels.length; i++ ) {
      tels[i].value = hankaku2Zenkaku(tels[i].value)
      tels[i].value = $.trim(tels[i].value)
      tels[i].value = tels[i].value.replace(/[^0-9]/g,'-')
    }
  }

  // ・アポ種別・所在地・商談住所
  $(document).on('input', '#ap_type, #address_all1, #pref_name2', function() {
    let input_value = $(this).val()
    $(this).val(hankakusuji(input_value))
    $(this).val($.trim($(this).val()))
    $(this).val(numPyphen(input_value))
  })

  //HPのURL・最寄り駅・バス時間
  $(document).on('input', "#hp_1, #station_min, #bus3", function() {
    let input_value = $(this).val()
    $(this).val(hankaku2Zenkaku(input_value))
    $(this).val($.trim($(this).val()))
  })

  //既存顧客案件ID
  $(document).on('change', 'input[type=radio][name="apType"]', function() {
    if (this.value === "既存顧客からの追加商材アポ") {
      $('#subjectsForm').show()
      $('#subjectID').addClass('required')
      $('#referralCodeForm').hide()
      $('#referralCode').removeClass('required')
    } else {
      $('#subjectsForm').hide()
      $('#subjectID').removeClass('required')
      $('#referralCodeForm').show()
      $('#referralCode').addClass('required')
    }
  })

  //案件名
  $(document).on('input', "#comName, #ceo_name", function() {
    let input_value = $.trim($(this).val())
   
    let match = input_value.match(/WEB直|イツザイ|イツザイライト|助成金|来社西|来社東|来社名|来社福|対面|🎦|サングローブ|\/|\[|\]|【|】/) || []
  
    if(match[0]) {
      alert('案件名に使用できない文字列が含まれています\n' + match.join(' '))
      $(this).val('')
      return 
    }
   
    input_value = input_value.replace('（','(').replace('）',')')
    $(this).val(input_value)
  })

  // 紹介コード
  $(document).on('input', '#referralCode', function() {
    let uppercase = $(this).val().toUpperCase()
    uppercase = hankaku2Zenkaku(uppercase)
    $(this).val(uppercase)
  })

  //[提案金額：上限項目表示]
  $(document).on('change', '#sp1_select', function() {
    const sp2_select = document.getElementById("sp2_select")//sp1のselect親要素
    const sp2_start = document.getElementById("sp2_start")//sp1のop一番最初の要素
    let sp1_last_num = 15 //上限max値
    let sp1_select_val = $('#sp1_select').val() //上限min値
    sp1_select_val = sp1_select_val.replace(".3", "")
    sp1_select_val = Number(sp1_select_val)

    let a_num = []
    a_num.push(sp1_select_val + ".3")
    for(let i = sp1_select_val +  1; i < sp1_last_num +  1; i++ ) {
      a_num.push(String(i), String(i + ".3"))
    }

    a_num.reverse()
    //追加したoptionあったら削除
    if ($('#sp2_select').children('[name=added_op]').length > 0) {
      $('#sp2_select').children('[name=added_op]').remove()
    }

    madeoptions(a_num, sp2_start, sp2_select)
  })

  //[提案金額：値幅ありボタン]
  //表示
  $(document).on("click", ".sp_btn", function() {
    if($('#sp_nondisplay').css('display', 'none')) {
      $('#sp_nondisplay').css('display', 'flex')
      $('#s_price_btn').text('値段幅なし')
      $('#sp2_select').addClass('required') //必須項目設定
      $('.sp_btn').attr('class', 'waves-effect waves-light btn-small sp_btn1')
    }
  })

  //非表示
  $(document).off("click.sp_btn1").on("click.sp_btn1", ".sp_btn1", function() {
    if($('#sp_nondisplay').css('display', 'flex')) {
      $('#sp_nondisplay').css('display', 'none')
      $('#s_price_btn').text('値段幅あり')
      $('#sp2_select').removeClass('required') //必須項目解除
      const bg_color = '#ffcdd2'
      const color = '#212121'

      $('#sp1_select').css({
        'background-color': bg_color,
        'color': color
      })

      //値幅値クリア
      $('[name=suggestion_price2]').val('')
      $('[name=suggestion_price1]').val('')
      $('.sp_btn1').attr('class', 'waves-effect waves-light btn-small sp_btn')
    }
  })


  //[アポ日程：timepicker]
  let startIdName = 'ap_start_time'
  let endIdName = 'ap_end_time'
  startEndTime(startIdName, endIdName)

  //[ボタン：増減ボタン処理]
  $(document).off("click.btn_add").on("click.btn_add", ".btn_add", function() {
    var copy_form_name = $(this).attr('id') //tel.id= tel1
    var count = $(this).closest('.c-variables').find('.c-variables__item-controls').length
    var idValue  = this.getAttribute('id')
    if(idValue != "other_tel1" && count >= 3) return

    var target = $(this).closest('.c-variables__item')
    var clonedElement = $('.copy_form_'+copy_form_name).eq(0).clone(true).css('display','flex').insertAfter(target)

    if (idValue == "other_tel1") {
      clonedElement.find('input[name="other_tel_input"]').addClass('copyEle')
    } else {
      clonedElement.find('input[name="tel"]').addClass('copyEle')
    }
  })

  $(document).off("click.btn_del").on("click.btn_del", ".btn_del", function() {
    var copy_form_name = $(this).attr('id')
    var target = $(this).closest('.c-variables__item')
    var label = target.prev('label')

    if ($('#add_del_input_' +  copy_form_name).find('div').length > 1) {
      target.remove()
      label.remove()
    }
  })

  //[アポ確保時間:自動計算]
  var imput_duration = document.getElementById("duration")
  var ap_time = document.getElementById("ap_time")//開始時間・終了時間

  //アポ終了時間が変更されたら発火
  ap_time.onchange = function() {
    var start_time = document.getElementById("ap_start_time").value

    if(start_time.indexOf('時')!= -1) {
      start_time = start_time.replace(/(\d{2})時(\d{2})分/,'$1:$2')
    }

    if (start_time.indexOf(':') === -1) {
      start_time = ""
      document.getElementById("ap_start_time").value = start_time
    }

    var end_time = document.getElementById("ap_end_time").value

    if (end_time.indexOf(':') === -1) {
      end_time = ""
      document.getElementById("ap_end_time").value = end_time
    }

    if(start_time != "" && end_time != "") {
      var date1 = new Date('2020-04-01 ' + start_time + ':00')
      var date2 = new Date('2020-04-01 ' + end_time + ':00')
      var diff = date1.getTime() - date2.getTime()
      diff = Math.abs(diff)
      imput_duration.value = (diff / (60 * 1000))
    }
  }

  //[必須項目判定]
  $(document).off('change.form_ap input.form_ap click.form_ap mousemove.form_ap').on('change.form_ap input.form_ap click.form_ap mousemove.form_ap', '#form_ap', function() {
    var format_flag = ""
    var format_flags = ""

    function format_check (trigger, label_class, label_tag, insertbfr, mode) {
      if($(label_class).length > 0) {
        $(label_class).remove ()
      }

      format_flag = "" //初期化

      for(let i = 0; i < trigger.length; i++) {
      
        let chk = trigger.get(i).validationMessage == ""
        if(mode == 'tel') {
          chk = trigger.get(i).value.match(/^0[0-9]*-[0-9]*-([0-9]{3}|[0-9]{4})/)
        } 
  
        if(chk) {
          format_flag += "1"
        } else {
          format_flag += "0"
          $(label_tag).insertAfter(insertbfr[i])
        }
  
      }

      if(format_flag.indexOf('0') == -1) {
        format_flags += "1"
      } else {
        format_flags += "0"
      }

      return format_flags

      
    }

    // [形式チェック：商談住所]
    var trigger = $('[name="address_all1"]')
    var label_class = '.error_msg_pref'
    var label_tag = '<p class="c-info c-notice error_msg_pref">※ 都道府県名から入力してください</p>'
    var insertbfr = $('[name="address_all1"]')
    format_flags = format_check_pref(trigger, label_class, label_tag, insertbfr)

    //[形式判定：所在地]
    var trigger = $('[name="address_all"]')
    var label_class = '.error_msg_pref'
    var label_tag = '<p class="c-info c-notice error_msg_pref">※ 都道府県名から入力してください</p>'
    var insertbfr = $('[name="address_all"]')
    format_flags += format_check_pref(trigger, label_class, label_tag, insertbfr)

    //[形式判定：電話番号]
    var notice_text = '※ ハイフンありの半角数字で入力してください。（例）01-234-5678'
    var trigger = $('.required[name="tel"]')
    var label_class = '.error_msg_tel1'
    var label_tag = '<li class="c-variables__item c-info c-notice error_msg_tel1">' + notice_text + '</li>'
    var insertbfr = $('.required[name="tel"]').parent()
    format_check(trigger,label_class,label_tag,insertbfr, 'tel')

    //[形式判定：その他電話番号]
    var trigger = $('.required[name="other_tel_input"]')
    var label_class = '.error_msg_other_tel1'
    var label_tag = '<li class="c-variables__item c-info c-notice error_msg_other_tel1">' + notice_text + '</li>'
    var insertbfr = $('.required[name="other_tel_input"]').parent()
    format_check(trigger,label_class,label_tag,insertbfr, 'tel')

    //[形式判定：商談日時]
    var trigger = $('#ap_doing_date')
    var label_class = '.error_msg_date'
    var label_tag = '<p class="c-info c-notice error_msg_date">※ 本日以降の日付を入力してください</p>'
    var insertbfr = $('#ap_doing_date').parent()
    format_check(trigger,label_class,label_tag,insertbfr)

    //[形式チェック：URL]
    trigger = $('[name="hp_url"]')
    label_class = '.error_msg_url'
    label_tag = '<p class="c-variables__item c-info c-notice error_msg_url">※ "http" or "https"から始まるURL形式で入力してください</p>'
    insertbfr = $('[name="hp_url"]').parent()
    format_check(trigger,label_class,label_tag,insertbfr)

    //[形式チェック：案件名]
    trigger = $('[name="company_name"]')
    label_class = '.error_msg_company_name'
    label_tag = '<p class="c-info c-notice error_msg_company_name">※ http形式 or 電話番号形式(xx-xxx-xxx)以外の形式で入力してください。</p>'
    insertbfr = $('[name="company_name"]').parent()
    format_check(trigger,label_class,label_tag,insertbfr)

    if($('#pref_name2').hasClass('required')) {
      trigger = $('[name="pref_name"]')
      label_class = '.error_msg_pref'
      label_tag = '<p class="c-info c-notice error_msg_pref">※ 都道府県名から入力してください</p>'
      insertbfr = $('[name="pref_name"]')
      format_flags = format_check_pref(trigger, label_class, label_tag, insertbfr)
    }

    let resultRequiredArray = checkRequired()
    let required_NG = 0
    let check_required = 0
    for(var key in resultRequiredArray) {
      resultRequiredArray[key] == 0 ? required_NG = 1 : check_required = 1
    }

    // console.log(check_required, required_NG,format_flags );

    if(check_required == 1 && required_NG == 0 && format_flags.indexOf('0') == -1) {
      $('#submit').prop('disabled', false).removeClass('disabled').addClass('pulse')
    }

  })

//[商談形式：商談形式に応じた項目表示]
var bussiness_format = document.getElementsByName('bussiness_format') //判定要素達
bussiness_format.forEach(function(e) {
  e.addEventListener("input", function() {
    var bf_value = $('#bussiness_formats').val()
    var bf = $('#bussiness_formats').find('option[value="' + bf_value + '"]').data('type')

    // [初期化:値]
    var device = document.getElementById('device')
    device.value=""

    $('#pref_name2, #station, #station_min, #bus1, #bus2, #bus3').val("")

    var visit_area = $('#visit_area').find('[name="area"]')
    for(let i = 0; i < visit_area.length; i++ ) {
      visit_area[i].checked = false
    }

    //[初期化：非表示]
    $('#visit_area, #f_device, #place_info').hide()
    //[初期化：必須設定]
    $('#area_kanto, #device, #pref_name2, #station, #station_min').removeAttr('required')
    //[初期化：クラス外す]
    $('#area_kanto, #area_kansai, #area_nagoya, #area_hukuoka, #area_yokohama, #device, #pref_name2, #station, #station_min').removeClass('required')

    //要素の表示・クラス名・required設定
    if(bf == "来社") {
      $('#visit_area').show()
      $('#area_kanto, #area_kansai, #area_nagoya, #area_hukuoka, #area_yokohama').attr("required", "true").addClass('required')

    } else if(bf == "オンライン") {
      $('#f_device').show()
      $('#device').attr("required", "true").addClass('required')

    } else if(bf == "対面") {
      $('#place_info').show()
      $('#pref_name2, #station, #station_min').attr("required", "true").addClass('required')
    }
  })
})

  //[HPのURL：URL項目表示]
  //HPのURL（有）確認処理
  var url_exists = document.getElementsByName('url_exists') //判定要素達
  var add_del_input_url1 = document.getElementById('add_del_input_url1')//表示させる要素
  var hp_url = document.getElementsByName("hp_url")//表示した要素（vlaue）
  var hp_1 = document.getElementById("hp_1") //必須項目の設定に利用
  var input_url_exists = "無"

  url_exists.forEach(function(e) {
    e.addEventListener("click", function() {
      for (let i = 0; i< url_exists.length; i++ ) {
        if ( url_exists[i].checked && url_exists[i].value == "有") {
          add_del_input_url1.style.display="block"
          hp_1.className= "required "
          input_url_exists = ""
        } else if (url_exists[i].checked && url_exists[i].value == "無" && add_del_input_url1.style.display=="block") {
          add_del_input_url1.style.display="none"
          input_url_exists = url_exists[i].value
          hp_1.required = false
          hp_1.removeAttribute("class")

          //入力されたURLをクリア
          for ( let i = 0; i < hp_url.length; i++ ) {
              if ( hp_url[i].value != "") {
                hp_url[i].value=""
              }
          }
        }
      }
    })
  })

  //[フック・提案内容：チェック項目の連動]
  var hook = document.getElementsByName("hook")
  var s_not_itzi = document.getElementsByClassName("not_itzi")
  var s_itzi = document.getElementById("itzi")
  var s_itzi_lite = document.getElementById("itzi_lite")
  var suggestion = document.getElementsByName("suggestion")
  var seo = document.getElementById("seo")
  var hook_option_check_inteval_obj = {}
  var is_set_hook_option = false

  hook.forEach(function(e) {
    e.addEventListener("click", function() {
      $('[name="suggestion"]').addClass("required")

      let checking_hooks = () => {
        for (let i = 0; i < hook.length; i++ ) {
          var f_suggestion_price_5 = document.getElementById("f_suggestion_price_5")
  
          if(hook[i].checked && (hook[i].value =="R" || hook[i].value =="W")) {
            if(hook[i].value =="W") {
              f_suggestion_price_5.textContent = "5万円＋"
            } else {
              f_suggestion_price_5.textContent = ""
            }
  
            for(let i = 0; i < suggestion.length; i++ ) {
              suggestion[i].checked = false
              suggestion[i].disabled = false
            }
  
            $('#category').val("")
            $('#sub_cate').val("")
            s_itzi.setAttribute('disabled','disabled') //提案内容：イツザイ選択不可
            s_itzi_lite.setAttribute('disabled','disabled') //提案内容：イツザイライト選択不可
  
          } else if(hook[i].checked && hook[i].value =="イツザイ") {
  
            f_suggestion_price_5.textContent = ""
            for(let i = 0; i < suggestion.length; i++ ) {
              suggestion[i].checked = false
              suggestion[i].disabled = false
            }

            $('#category').val("")
            $('#sub_cate').val("")
  
            for(let i = 0; i < s_not_itzi.length; i++ ) {
              s_not_itzi[i].setAttribute('disabled','disabled')
            }
  
            //提案内容：SEO選択不可
            seo.setAttribute('disabled','disabled')
  
          } else if(hook[i].checked && hook[i].value == "助成金Lab") { //助成金項目追加
  
            f_suggestion_price_5.textContent = "40万円＋"
            for(let i = 0; i < suggestion.length; i++) {
              suggestion[i].checked = false
              suggestion[i].disabled = false
            }
  
            $('#s_sub').prop({
              checked: true,
              disabled: true
            })
  
            $('#category').val("")
            $('#sub_cate').val("")
    
            for(let i = 0; i < s_not_itzi.length; i++) {
              s_not_itzi[i].setAttribute('disabled','disabled')
            }
    
            seo.setAttribute('disabled','disabled')//提案内容：SEO選択不可
            s_itzi.setAttribute('disabled', 'disabled') //提案内容：イツザイ選択不可
            s_itzi_lite.setAttribute('disabled','disabled') //提案内容：イツザイライト選択不可
    
          } else if(hook[i].checked && hook[i].value =="なし") {
  
            f_suggestion_price_5.textContent = ""
  
            for(let i = 0; i < suggestion.length; i++ ) {
              suggestion[i].checked = false
              suggestion[i].disabled = false
            }
  
            $('#itzi').prop("disabled", true)
            $('#s_sub').prop("disabled", true)
            $('#category').val("")
            $('#sub_cate').val("")
            s_itzi.setAttribute('disabled','disabled')//提案内容：イツザイ選択不可
            s_itzi_lite.setAttribute('disabled','disabled') //提案内容：イツザイライト選択不可
          }
        }
      }
      
      for(let i = 0; i < suggestion.length; i++ ) {
        suggestion[i].checked = false
      }

      if(!that.is_set_hook_option) {
        // Loading表示
        that.vueObj.viewLoader('業種カテゴリデータ取得中')
        
        let i = 0
        hook_option_check_inteval_obj = setInterval(() => {
          if(that.is_set_hook_option) {
            clearInterval(hook_option_check_inteval_obj )
            that.is_set_hook_option = true

            that.vueObj.viewLoader('取得完了')
            setTimeout(() => {
              that.vueObj.viewLoader()
              checking_hooks()
            }, 200)

          } else {
            i++
            if(i > 600) {
              clearInterval(hook_option_check_inteval_obj)
              that.vueObj.viewLoader()
              alert('業種カテゴリデータが取得できませんでした\nリロードします')
              location.reload()
            }
          }
        }, 50)

        return
      } else {
        checking_hooks()
      }

    })
  })

  $('[name="suggestion"]').click(function() {
    document.getElementById('category').value = ""
    document.getElementById('sub_cate').value = ""
  })

  //[カテゴリ：変動プルダウン]
  //カテゴリ変動プルダウン
  var cate_start = document.getElementById("cate_start")//カテゴリ一つ目の要素。ループくっつける
  var parentSelect = document.getElementById("category")//追加する親要素

  function madeoptions(array_op,start,parent) {
    for (let i = 0; i < array_op.length; i++ ) {
      var newElement = document.createElement("option")
      var newContent = document.createTextNode(array_op[i])
      newElement.appendChild(newContent)
      newElement.setAttribute("value",array_op[i])
      newElement.setAttribute("name","added_op")
      parent.insertBefore(newElement,start.nextSibling)
    }
  }


  //トリガー:フック・提案内容
  //フック・提案内容に適応するカテゴリ表示
  document.querySelectorAll('.js-hook-suggestion').forEach((hs) => {
    hs.addEventListener('click', () => {
      var hook = document.getElementsByName("hook")
      var suggestion = document.getElementsByName("suggestion")
      //var added_op = document.getElementsByName("added_op") // <= (これいる？)業種配下のadded_op
      var hooksuggestions =""
      var cate = document.getElementById('category')
      var cate_ops = document.getElementsByName("cate_ops") //カテゴリ選択のグループ
      if(cate_ops.length > 0) cate_ops[0].remove()
      
      hooksuggestions = checkedVal(hook) + checkedVal(suggestion)

      var catID = ''

      //フック・提案内容を判定してカテゴリ表示
      if(hooksuggestions.indexOf('RCMS') != -1) {
        catID = "hg_rcms"
      } else if(hooksuggestions.indexOf('REC') != -1) {
        catID = "hg_rec"
      } else if(hooksuggestions.indexOf('WCMS') != -1) {
        catID = "hg_wcms"
      } else if(hooksuggestions.indexOf('WEC') != -1) {
        catID = "hg_wec"
      } else if(hooksuggestions.indexOf('イツザイ') != -1) {
        catID = "hg_itzi"
      } else if(hooksuggestions.indexOf('助成金') != -1) {
        catID = "hg_subsidy"
      } else if(hooksuggestions.indexOf('なし') != -1) {
        catID = "webst"
      }

      //提案内容がMEOの時は強制的にカテゴリをWEB直販に変更
      const isCheckedMEO = document.getElementById("meo").checked
      if (isCheckedMEO) {
        catID = "webst"
      }

      // var className = "markdisplay"
      var catData = that.jobsCategoryData.filter(elm => elm.id == catID)

      if(catData.length == 0) return
      console.log(catData)
      const tempEl = document.createElement('div');
      tempEl.innerHTML = catData[0].options;
      cate.append(tempEl.firstElementChild)
    })
  });

  //配列生成(業種用)
  function made_jobarray(array_cate) {
    var array_job =[]

    for(let i = 0; i < array_cate.length;i++ ) {
      array_job.push(array_cate[i].name)
    }

    return array_job
  }

  //[業種：変動プルダウン表示]
  //トリガー：カテゴリが選択された時
  //カテゴリの選択された値から業種配列生成 => 業種op生成
  parentSelect.addEventListener("change", function() {
    var cate = document.getElementsByClassName("cate")
    var array_job = []
    //業種初期化
    var bg_color = '#ffcdd2'
    var color = '#212121'

    $('#sub_cate').val("").css({
      'background-color': bg_color,
      'color': color
    })

    for(let i = 0; i < cate.length; i++ ) {
      if(cate[i].selected) {
        array_job = cate[i].dataset.job //カテゴリで選択されたタグのdataを取得。
      }
    }

    array_job = JSON.parse(array_job || "null") //文字列から配列(オブジェクト)へ変換

    //追加したoptionあったら削除
    if ($('#sub_cate').children('[name=added_op]').length > 0) {
      $('#sub_cate').children('[name=added_op]').remove()
    }

    for(let i in array_job) {
      let option = $('<option>')
      option.attr({
        value: array_job[i].name,
        name: 'added_op',
        'data-id': array_job[i].id
      }).text(array_job[i].name)
  
      $('#sub_cate').append(option)
    }
  })

  //[提案内容:EC・CMS同時選択不可]
  $(function () {
    $('#s_ec').on('click', function () {
      if ($(this).prop('checked')) {
        $('#s_cms').prop('checked', false)
        $('#itzi').prop('checked', false)
        $('#itzi_lite').prop('checked', false)
        $(this).prop('checked', true)
      }
    })
  })

  $(function () {
    $('#s_cms').on('click', function () {
      if ($(this).prop('checked')) {
        $('#s_ec').prop('checked', false)
        $('#itzi').prop('checked', false)
        $('#itzi_lite').prop('checked', false)
        $(this).prop('checked', true)
      }
    })
  })

  $(function () {
    $('#itzi').on('click', function () {
      if ($(this).prop('checked')) {
        $('#s_ec').prop('checked', false)
        $('#s_cms').prop('checked', false)
        $(this).prop('checked', true)
      }
    })
  })

  // [ネック：表示非表示]
  $('.neck_li').click(function() {
    var id_name = $(this).attr('id')
    if($('#neck_' + id_name).hasClass("up")) {
      $(this).parent('div').addClass('grey')
      $('#neck_' + id_name).show(400)
      $('#neck_' + id_name).removeClass("up")
      $('#neck_' + id_name).addClass("down")
    } else if($('#neck_' + id_name).hasClass("down")) {
      $('#neck_' + id_name).hide(400)
      $(this).parent('div').removeClass('grey')
      $('#neck_' + id_name).removeClass("down")
      $('#neck_' + id_name).addClass("up")
    }
  })

  $(s_itzi).click(function() {
    s_itzi_lite.checked = false
  })

  $(s_itzi_lite).click(function() {
    s_itzi.checked = false
  })

  // [ネック：チェック動作]
  // $('[name="neck1"]').click(function() {
  //   var this_class_name = $(this).attr("class")
  //   var neck_flag = ""

  //   $('.' +  this_class_name).each(function(i, elem) {
  //     if($(elem).prop('checked')) {
  //       neck_flag += "1"
  //     } else {
  //       neck_flag += "0"
  //     }
  //   })

  //   if(neck_flag.indexOf('1') != -1) {
  //     // 一つでもチェックされている
  //     $(this).parent().parent().parent().removeClass("down")
  //     var id = $(this).parent().parent().parent().attr('id')
  //     var splited_id = id.split('_')[1]
  //     $('#' + splited_id).parent().removeClass('grey').addClass(checked_color)
  //   } else {
  //     // チェック外した
  //     $(this).parent().parent().parent().addClass("down")
  //     var id = $(this).parent().parent().parent().attr('id')
  //     var splited_id = id.split('_')[1]
  //     $('#' + splited_id).parent().removeClass(checked_color).addClass('grey')
  //   }
  // })

  $('#textarea_sonota').on('input',function() {
    $('#sonota').parent().removeClass('grey')

    if($('#textarea_sonota').val() != "") {
      $('#sonota').parent().addClass(checked_color)
    } else {
      $('#sonota').parent().removeClass(checked_color).addClass('grey')
    }
  })

  //[商談時刻制限(遡り防止)]
  $('#ap_doing_date').change(function() {
    let formated_today = momentToday.format(format)

    if ($('#ap_doing_date').val() < formated_today) {
      $('#ap_doing_date').val("")
    }
    
  })

  //送信用メッセ
  document.getElementById("submit").onclick = function exchange(e) {
    e.preventDefault();

    if($('#submit').hasClass('pulse')) {
      function hankaku2Zenkaku(str) {
        return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
        })
      }

      //[gas連携用]
      var start_time_c = $('#ap_start_time').val()
      var end_time_c = $('#ap_end_time').val()
      var day_c = $('#ap_doing_date').val()
      //変数
      var today = new Date()
      const today_year = today.getFullYear()
      const today_month = today.getMonth() +  1
      const today_date = today.getDate()
      let inputReferralCode = ""
      inputReferralCode = $('#referralCode').val()
      ///項目：アポ獲得日_日付
      var input_got_date = today_year + "年" + today_month + "月" + today_date + "日"
      var got_date_c = today_year + "-" + today_month + "-" + today_date;//got_date":"2021-12-16"
      //項目：アポ獲得日_曜日
      var input_DOW = new Date(today).getDay()
      //項目：アポ日程（商談日）
      var input_ap_doing_date = document.getElementById("ap_doing_date")
      var ap_doing_date_c = $('#ap_doing_date').val()
      //項目：アポ日程（商談日)_曜日
      var input_ap_doing_DOW = new Date(input_ap_doing_date.value).getDay()
      //項目：アポ日程（商談日)_開始時間
      var input_ap_start_time = document.getElementById("ap_start_time")
      //項目：アポ日程（商談日)_終了時間
      var input_ap_end_time = document.getElementById("ap_end_time")
      ///項目：案件名
      var input_company_name = document.getElementById("comName")
      input_company_name = validateText(hankaku2Zenkaku(input_company_name.value))
      ///項目：カテゴリ
      var input_category = document.getElementById("category")
      ///項目：業種
      var input_sub_cate = document.getElementById("sub_cate")
      //項目：所在地（市町村区）
      var input_address1 = document.getElementById("address1")
      //項目：電話
      var tel = document.getElementsByName("tel")
      //項目：その他の電話
      var other_tel = document.getElementsByName("other_tel_input")
      //項目：提案金額
      var imput_suggestion_price = document.getElementById("suggestion_price")
      var hp_url = document.getElementsByName("hp_url")
      //項目：備考
      var remarks = document.getElementById("txt_box1")
      var suggestion = document.getElementsByName("suggestion") //項目：提案内容
      var suggestion_price1 = document.getElementById("sp1_select") //項目：提案金額1
      var f_suggestion_price_5 = document.getElementById("f_suggestion_price_5") //項目：提案金額(5万＋)
      var suggestion_price2 = document.getElementById("sp2_select")//項目：提案金額2
      var today_ap = document.getElementById("today_ap")

      // 紹介種別
      let inputReferralType = check($('[name="referralType"]')).slice(0,-1)
      //[input：当アポ]
      var input_today_ap = ""

      if(today_ap.checked) {
        input_today_ap = "当アポ"
      } else {
        input_today_ap = ""
      }

      // [input:代表性別]
      var input_gender = ""
      input_gender = check($('[name="gender"]')).slice(0,-1)

      //[input：提案金額]
      if(suggestion_price2.value != "") {
        var input_suggestion_price2 = ""
        input_suggestion_price2 = "〜" + suggestion_price2.value
      } else {
        input_suggestion_price2 = suggestion_price2.value
      }

      //[input：曜日設定]
      var WeekChars = [ "日", "月", "火", "水", "木", "金", "土" ]

      //[input： 提案内容]
      var input_suggestions = ""
      var input_hooks = ""
      input_suggestions = check(suggestion)

      //[input： フック]
      input_hooks = check(hook)

      //[input：HPのURL]
      var input_hp_url = ""
      for ( let i = 0; i < hp_url.length; i++ ) {
        if ( hp_url[i].value != "" && hp_url[i].reportValidity()) {
          input_hp_url += hp_url[i].value + ","
        }
      }

      //[input：電話番号]
      // 判定条件に"エラーいない"を追加
      var input_tel = []
      for ( let i = 0; i < tel.length; i++) {
        if ( tel[i].value != "" && tel[i].reportValidity()) {
          input_tel.push(tel[i].value)
        }
      }
      input_tel = input_tel? input_tel.join(','): ''

      //[input：その他電話番号]
      // 判定条件に"エラーいない"を追加
      var input_other_tel = []
      for ( let i = 0; i < other_tel.length; i++) {
        if ( other_tel[i].value != "" && other_tel[i].reportValidity()) {
          input_other_tel.push(other_tel[i].value)
        }
      }
      input_other_tel = input_other_tel? input_other_tel.join(','): ''

      //[input:商談形式_来社]
      var input_bussiness_format = ""
      if($('#bussiness_formats').val() == "来社") {
        input_bussiness_format = $('#bussiness_formats').val()
      }

      //[input：最寄り駅]
      var station = $('#station')
      var station_min = $('#station_min')
      if(station.val().slice(-1) == '駅') {
        var edit_station = station.val().slice(0,-1)
        station.val(edit_station)
      }

      if(station.val() != "") {
        var input_station = station.val() + "駅  徒歩" + station_min.val() + "分"
      } else {
        var input_station = ""
      }

      //[input：バス]
      var imput_bus1 = $('#bus1')
      var imput_bus2 = $('#bus2')
      var imput_bus3 = $('#bus3')

      if (imput_bus1.val() != "") {
        var input_bus = imput_bus1.val() + "～" + imput_bus2.val() + "  バス＋徒歩" + imput_bus3.val() + "分"
      } else {
        var input_bus =""
      }

      //[整形：date]
      var input_ap_doing_date = input_ap_doing_date.value.replace(/(\d{4})-(\d{2})-(\d{2})/,'$1年$2月$3日')

      //[整形：time]
      //時分に変換
      var times = document.getElementsByClassName("f_time")
      for(let i = 0; i < times.length; i++ ) {
        times[i].value=times[i].value.replace(/(\d{2}):(\d{2})/,'$1時$2分')
      }

      // [整形：商談住所]
      var input_mtg_add = $('#pref_name2').val()
      input_mtg_add = delSpace(input_mtg_add)
      input_company_name = delSpace(input_company_name)
      inputReferralCode = delSpace(inputReferralCode)

      //[整形：末尾"/" or ","削除]
      //末尾のスラッシュ削除
      var input_suggestions = input_suggestions.slice(0,-1)
      var input_hp_url = input_hp_url.slice(0,-1)
      var input_hooks = input_hooks.slice(0,-1)

      // ネック処理
      var neck_ary = {}
      var necks = $('[name="neck1"]')
      for (let i = 0; i < necks.length; i++) {
        if (necks[i].checked) {
          if(typeof neck_ary[necks[i].dataset.key] == 'undefined') {
            neck_ary[necks[i].dataset.key] = {
              title: `[${necks[i].dataset.neck}]`,
              text: []
            }
          }
          neck_ary[necks[i].dataset.key].text.push(necks[i].value)
        }
      }
 
      //[クリック：投稿ボタン]
      var device_c = device.value
      var bussiness_format_c = $('#bussiness_formats').val()
      var add_c = $('#address_all1').val()
      var mtg_add_c = input_mtg_add

      //ネックテキスト構築
      let neck_text = []
      for(let i in neck_ary) {
        neck_text.push(`${neck_ary[i].title}
        ${neck_ary[i].text.join(' / ')}`)
      }

      neck_text = '\r\n' + neck_text.join('\r\n')
      
      //シート格納用
      var cost_c = typeof neck_ary.cost != 'undefined'? neck_ary.cost.text.join(' / '): ''
      var time_c = typeof neck_ary.time != 'undefined'? neck_ary.time.text.join(' / '): ''
      var timing_c = typeof neck_ary.timing != 'undefined'? neck_ary.timing.text.join(' / '): ''
      var soudansya_c = typeof neck_ary.soudansya != 'undefined'? neck_ary.soudansya.text.join(' / '): ''
      var insure_c = typeof neck_ary.insurance != 'undefined'? neck_ary.insurance.text.join(' / '): ''
      var try_c = typeof neck_ary.try != 'undefined'? neck_ary.try.text.join(' / '): ''
      var data_c = typeof neck_ary.deta != 'undefined'? neck_ary.deta.text.join(' / '): ''
      var capa_c = typeof neck_ary.capa != 'undefined'? neck_ary.capa.text.join(' / '): ''
      var side_c = typeof neck_ary['9-bis'] != 'undefined'? neck_ary['9-bis'].text.join(' / '): ''
      var ninsiki_c = typeof neck_ary.ninshiki != 'undefined'? neck_ary.ninshiki.text.join(' / '): ''
      var soketu_c = typeof neck_ary.soketu != 'undefined'? neck_ary.soketu.text.join(' / '): ''
      var web_c = typeof neck_ary.web != 'undefined'? neck_ary.web.text.join(' / '): ''
      var keisyo_c = typeof neck_ary.keisyo != 'undefined'? neck_ary.keisyo.text.join(' / '): ''
      var pay_c = typeof neck_ary.payment != 'undefined'? neck_ary.payment.text.join(' / '): ''
      var sunnari_c = typeof neck_ary.sunnari != 'undefined'? neck_ary.sunnari.text.join(' / '): ''
      var other_c = document.getElementById('textarea_sonota').value
      neck_text +=  '\r\n' + "[その他]" +
                    '\r\n' +   other_c

      var post_id_for_subjectID = $.now()
      const storeData = that.store.state.userData
      if (storeData.BN !== "" && storeData.BN !== null && storeData.BN !== "NULL" && storeData.BN !== "None") {
        var fullname = storeData.BN + "（" + storeData.firstName + "） " + storeData.lastName
      } else {
        var fullname = storeData.firstName + " " + storeData.lastName
      }

      //[拡張機能バージョン]
      // let manifestData = chrome.runtime.getManifest()
      // const Extension_version = manifestData.version
      const Extension_version = that.store.state.version

      //[整形：投稿メッセ作成]
      // subject_id = "OT" + talknote_group.id + "_" + post_id
      let inputapType = check($('[name="apType"]')).slice(0,-1)
      let input_subjects_id = document.getElementById("subjectID")
      let subject_id = ""
      let origin_subject_id = ""
      if (inputapType == "既存顧客からの追加商材アポ") {
        subject_id = "AO" + that.slackChannel.id + "_" + post_id_for_subjectID
        origin_subject_id = "既存案件ID：" + input_subjects_id.value
      } else {
        subject_id = "OT" + that.slackChannel.id + "_" + post_id_for_subjectID
      }

      const user_id = that.slackChannel.userID
      const tn_user_id = that.store.state.userData.userID.toString()
      const postID = func.getPostID(subject_id, user_id, that.slackChannel.id)

      var send_msg2gas =
      `案件ID：${subject_id}
【${input_ap_type.value}アポ】${input_today_ap}[${Extension_version}]
アポ獲得日：${input_got_date}(${WeekChars[input_DOW]})
紹介種別：${inputReferralType}
紹介コード：${inputReferralCode}

◎紹介先情報
アポ種別：${inputapType}
${origin_subject_id}
案件名：${input_company_name}
代表者名：${validateText($('#ceo_name').val())}
代表性別：${input_gender}
カテゴリ：${input_category.value}
業種：${input_sub_cate.value}
所在地：${$('#address_all1').val()}
電話番号：${input_tel}
その他電話番号：${input_other_tel}
HPのURL：${input_url_exists} ${input_hp_url}

アポ日程：${input_ap_doing_date}(${WeekChars[input_ap_doing_DOW]})${input_ap_start_time.value}～${input_ap_end_time.value}
アポ確保時間：${imput_duration.value}分
提案金額：${f_suggestion_price_5.textContent}${suggestion_price1.value}${input_suggestion_price2}万×24か月
提案内容：${input_suggestions}
フック：${input_hooks}

商談形式：${$('#bussiness_formats').val()}
商談住所：${input_mtg_add}
商談場所最寄り駅：${input_station}
バス：${input_bus} 
オンライン商談の場合⇒使用デバイス：${device.value}
その他共有事項：${remarks.value}
ネック：${neck_text}

-----------------------------
POST ID：${postID}
こちらのIDは投稿画面からの投稿IDです。
`

        //投稿ボタンの有効化
        //必須項目チェックが全てOKだったら、投稿ボタンを自動で押す
        // var sending_btn = document.getElementById("submit")
        // sending_btn.className = 'common_button post table_cell'
        // sending_btn.removeAttribute('disabled')
        // post_btn.click()
        // $('#submit').remove()

        //[background送信]
        try{
          // talknote投稿
          ;(async function() {



            //スプレッドシートに登録
            const requestFunc = new RequestBody.requestBody()
            const belongData = requestFunc.setBelongDate(storeData)
            const groupName = "apGain"
            const spreadsheetApi = that.store.state.apis[`spreadsheet${that.demo}`]
            const spreadsheetUpdateApi = that.store.state.apis[`spreadsheetUpdate${that.demo}`]
            const calCreateApi = that.store.state.apis[`calCreate${that.demo}`] + "/ap_gain"
            const DBInsertApi = that.store.state.apis[`dbInsert${that.demo}`]
            const DBUpdateApi = that.store.state.apis[`dbUpdate${that.demo}`]
            const NowTime = requestFunc.nowTime()
            const created_at = requestFunc.nowTime("created_at")
            const suggestion_price = suggestion_price1.value + input_suggestion_price2 + "万×24か月"
            const apoDate = input_ap_doing_date.replace("年", "/").replace("月", "/").replace("日", "")
            const started_at = input_ap_start_time.value.replace("時", ":").replace("分", "")
            const ended_at = input_ap_end_time.value.replace("時", ":").replace("分", "")



            const insertDataRecordCnt = [{
                                        "created_at":{"id": "", "val": NowTime},
                                        "updated_at":{"id": "", "val": NowTime},
                                        "投稿ID":{"id": "", "val": postID},
                                        "AP名":{"id": "", "val": fullname},
                                        "アポ種別":{"id": "", "val": input_ap_type.value},
                                        "使用リスト":{"id": "", "val": "使用リスト"},
                                        "アポ獲得日":{"id": "", "val": got_date_c},
                                        "案件名":{"id": "", "val": input_company_name},
                                        "カテゴリ":{"id": "", "val": input_category.value},
                                        "業種":{"id": "", "val": input_sub_cate.value},
                                        "所在地":{"id": "", "val": add_c},
                                        "連絡先電話番号":{"id": "", "val": input_tel},
                                        "HP有無":{"id": "", "val": input_url_exists},
                                        "URL":{"id": "", "val": input_hp_url},
                                        "アポ日程":{"id": "", "val": apoDate},
                                        "開始時間":{"id": "", "val": apoDate + " " + started_at},
                                        "終了時間":{"id": "", "val": apoDate + " " + ended_at},
                                        "アポ確保時間":{"id": "", "val": imput_duration.value + "分"},
                                        "提案金額":{"id": "", "val": suggestion_price},
                                        "提案内容":{"id": "", "val": input_suggestions},
                                        "フック":{"id": "", "val": input_hooks},
                                        "商談形式":{"id": "", "val": bussiness_format_c},
                                        "商談住所":{"id": "", "val": mtg_add_c},
                                        "最寄り駅":{"id": "", "val": input_station},
                                        "バス":{"id": "", "val": input_bus},
                                        "使用デバイス":{"id": "", "val": device_c},
                                        "ネック_費用":{"id": "", "val": cost_c},
                                        "ネック_時間":{"id": "", "val": time_c},
                                        "ネック_タイミング":{"id": "", "val": timing_c},
                                        "ネック_相談者":{"id": "", "val": soudansya_c},
                                        "ネック_保証":{"id": "", "val": insure_c},
                                        "ネック_お試し":{"id": "", "val": try_c},
                                        "ネック_データ":{"id": "", "val": data_c},
                                        "ネック_キャパ":{"id": "", "val": capa_c},
                                        "ネック_副業":{"id": "", "val": side_c},
                                        "ネック_商談認識":{"id": "", "val": ninsiki_c},
                                        "ネック_即決":{"id": "", "val": soketu_c},
                                        "ネック_web":{"id": "", "val": web_c},
                                        "ネック_事業継承":{"id": "", "val": keisyo_c},
                                        "ネック_支払い方法":{"id": "", "val": pay_c},
                                        "ネック_すんなり":{"id": "", "val": sunnari_c},
                                        "ネック_その他":{"id": "", "val": other_c},
                                        "group_id":{"id": "", "val": that.slackChannel.id},
                                        "group_title":{"id": "", "val": that.groupTitle},
                                        "group_name":{"id": "", "val": form_group_name},
                                        "案件ID":{"id": "", "val": subject_id},
                                        "user_id":{"id": "", "val": tn_user_id},
                                        "所属エリア":{"id": "", "val": belongData.userPrefecture},
                                        "所属（課/部）":{"id": "", "val": belongData.userSection},
                                        "所属（組）":{"id": "", "val": belongData.IS_UserTeam},
                                        "全文":{"id": "", "val": func.textEscape(send_msg2gas)},
                                        "紹介種別":{"id": "", "val": inputReferralType},
                                        "紹介コード":{"id": "", "val": inputReferralCode},
                                        "その他共有事項":{"id": "", "val": remarks.value},
                                        "その他電話番号":{"id": "", "val": input_other_tel},
                                        "所属（組）FS":{"id": "", "val": belongData.FS_UserTeam}
                                        }]

            const sheetData = requestFunc.createBody(groupName, that.isProd, insertDataRecordCnt)
            
            let calLinkSheetData = JSON.parse(JSON.stringify(sheetData))
            const today = NowTime.split(" ")[0]
            const isToday = today == apoDate
            const spread = requestFunc.spreadDesignationCalLink(isToday, that.isProd)
            calLinkSheetData.spread.id = spread.spreadsheetID
            calLinkSheetData.spread.sheet_name = spread.sheetName

            const negoDate = input_ap_doing_date.replace("年", "-").replace("月", "-").replace("日", "")
            const checkedArea = checkedVal($('[name="area"]'))
            const type_id = parseInt($('[name="bussiness_format"][value="' + bussiness_format_c + '"]').attr('data-id'))
            const hook_id = parseInt($('[name="hook"][value="' + input_hooks + '"]').attr('data-id'))
            
            let suggestionArray = input_suggestions
            if (input_suggestions.includes("/")) {
              suggestionArray = input_suggestions.replace("助成金", '').replace("SEO", '').replace("/", '')
            }

            let suggestionId = parseInt($('[name="suggestion"][value="' + suggestionArray + '"]').attr('data-id'))
            if (suggestionArray.includes('MEO')) {
              suggestionId = parseInt($('[name="suggestion"][value="' + 'MEO' + '"]').attr('data-id'))
            } else if (isNaN(suggestionId)) {
              suggestionId = 99
            }

            let area = $('[name="area"][value="' + checkedArea + '"]').attr('data-type')

            
            const is_merit = 0 //運用にはメリット項目なし
            const is_noah = 0 //現在ノア案件無し
            const callin_prospect = "" //運用には見込み代電なし
            const is_not_confirm_ap = false //運用には前不なし
            const is_outlet = "" //運用にはアウトレットなし
            const is_exist_hp = input_url_exists === "無" ? 1 : (input_url_exists === "" ? 0 : "")

            let calData = { 
                          "sg_data": {"is_prod": sheetData.support_data.is_prod, 
                                      "message": func.textEscape(send_msg2gas),
                                      "created_at": created_at, 
                                      "group_id": that.slackChannel.id,
                                      "user_id": that.store.state.userData.id
                                      },
                          "subject": {"id": subject_id,
                                      "name": input_company_name,
                                      "is_exist_hp": is_exist_hp,
                                      "is_merit": is_merit,
                                      "hook_id": hook_id,
                                      "suggestion_id": suggestionId,
                                      "callin_prospect": callin_prospect,
                                      "is_not_confirm_ap": is_not_confirm_ap,
                                      "is_outlet": is_outlet
                                      },
                          "nego": { "date": negoDate,
                                    "started_at": started_at,
                                    "ended_at": ended_at,
                                    "type_id": type_id,
                                    "area": area, 
                                    "add": mtg_add_c
                                  }
                          }

            const prefectures = add_c.match(/^(.+?[都道府県])/)
            let prefectureName = ""

            if (prefectures && prefectures.length > 0) {
              prefectureName = prefectures[1]
            }

            const phone_number = input_tel.replace(/-/g, '')
            const phone_number_array = phone_number.split(',')
            const other_phone_number = input_other_tel.replace(/-/g, '')
            const other_phone_number_array = other_phone_number.split(',')
            let phone_array

            if (other_phone_number_array == "") {
              phone_array = phone_number_array
            } else {
              phone_array = [...phone_number_array, ...other_phone_number_array]
            }

            const necks_id = getDataAttr($('[name="neck1"]:checked'), 'id').join()
            const necks_id_array = necks_id.split(',')
            const subCatesId = $('#sub_cate option:selected').data('id') ? $('#sub_cate option:selected').data('id') : 9999

            let dbData_subjects = {
              "table_name": "subjects",
              "form": {
                "non_sub_query": {
                  "id": subject_id,
                  "name": input_company_name,
                  "sub_cates_id": subCatesId,
                  "company_address": add_c,
                  "is_exist_hp": is_exist_hp,
                  "users_id": that.store.state.userData.id,
                  "is_noah": is_noah,
                  "post_id": postID,
                  "created_at": created_at,
                  "is_merit": is_merit
                },
                "sub_query": {
                  "users": true,
                }
              },
              "authC": {
                "password": "W!z|a~.x.T~Tzca8CBCm",
                "mail": "system@sungrove.co.jp"
              },
              "support_data": {
                "users_id": that.store.state.userData.id,
                "is_prod": sheetData.support_data.is_prod
              }
            }

            if (prefectureName) {
              dbData_subjects.form.sub_query.prefectures_id = 'SELECT id FROM prefectures p WHERE name LIKE "' + prefectureName + '%"'
            }

            let dbData_ap_negotiations = { 
              "table_name": "ap_negotiations",
              "form": {
                "non_sub_query": {
                  "subjects_id": subject_id,
                  "negotiation_date": ap_doing_date_c,
                  "started_at": started_at,
                  "ended_at": ended_at,
                  "duration": imput_duration.value,
                  "offer_price": suggestion_price,
                  "hooks_id": hook_id,
                  "suggestions_id": suggestionId,
                  "negotiation_types_id": type_id,
                  "address": mtg_add_c,
                  "device": device_c,
                  "created_at": created_at,
                  "post_id": postID
                },
                "sub_query": {
                  "users": false,
                }
              },
              "support_data": {
                "users_id": that.store.state.userData.id,
                "is_prod": sheetData.support_data.is_prod
              }
            }

            if (prefectureName) {
              dbData_ap_negotiations.form.sub_query.prefectures_id = 'SELECT id FROM prefectures p WHERE name LIKE "' + prefectureName + '%"'
            }

            let dbData_subject_phone_numbers = []
            for (let i = 0; i < phone_array.length ; i++) {

              dbData_subject_phone_numbers[i] = {
                "table_name": "subject_phone_numbers",
                "form": {
                  "non_sub_query": {
                    "subjects_id": subject_id,
                    "phone_number": phone_array[i],
                    "created_at": created_at,
                  },
                  "sub_query": {
                    "users": false,
                  }
                },
                "support_data": {
                  "users_id": that.store.state.userData.id,
                  "is_prod": sheetData.support_data.is_prod
                }
              }

            }

            let dbData_ap_negotiations_necks = []
            for (let i = 0; i < necks_id_array.length ; i++) {

              dbData_ap_negotiations_necks[i] = {
                "table_name": "ap_negotiations_necks",
                "form": {
                  "non_sub_query": {
                    "necks_id": necks_id_array[i],
                    "created_at": created_at,
                  },
                  "sub_query": {
                    "users": false,
                    "ap_negotiations_id": "SELECT id FROM ap_negotiations WHERE created_at = '" + created_at + "' AND subjects_id = '" + subject_id + "' LIMIT 1"
                  }
                },
                "support_data": {
                  "users_id": that.store.state.userData.id,
                  "is_prod": sheetData.support_data.is_prod
                }
              }

            }

            let teams_ids = ""
            const teamID = that.store.state.userData.teams_id
            if (teamID && teamID != "NULL" && teamID != "None") {
              teams_ids = teamID.split(",")
            } else {
              teams_ids = [""]
            }

            let dbData_array = []
            dbData_array.push(dbData_subjects)
            dbData_array.push(dbData_ap_negotiations)

            for (let i = 0; i < phone_array.length ; i++) {
              dbData_array.push(dbData_subject_phone_numbers[i])
            }

            if (necks_id_array[0] !== '') {
              for (let i = 0; i < necks_id_array.length ; i++) {
                dbData_array.push(dbData_ap_negotiations_necks[i])
              }
            }

            let phoneArrayString = phone_array.map(phone => `'${phone}'`).join(",")
            let dbData_update = [{
              "table_name": "callin_records",
              "non_sub_query": {
                "set": {
                  "subjects_id": subject_id
                },
                "where": {}
              },
              "sub_query": {
                "set": {},
                "where": {
                  "query": "phone_number IN (" + phoneArrayString + ")"
                }
              },
              "support_data": {
                "is_prod": sheetData.support_data.is_prod
              },
              "authC": {
                "password": "W!z|a~.x.T~Tzca8CBCm",
                "mail": "system@sungrove.co.jp"
              }
            }]
            
            /*********************************************************
             * 投稿開始
             * *******************************************************/
            // Loading表示
            that.vueObj.viewLoader('投稿中')

            // slack 投稿者にメンション
            const slackMention = `<@${that.slackChannel.userID}>\n`

            const data = {
              "channel": that.slackChannel.id, 
              "username": fullname,
              "text":  func.textEscape(slackMention + send_msg2gas), 
              "token": that.slackChannel.token,
              "icon_url": that.slackChannel.iconURL
            }
   
            // slack投稿
            const response = await fetch(that.slackChannel.postURL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: `token=${encodeURIComponent(data.token)}&channel=${encodeURIComponent(data.channel)}&username=${encodeURIComponent(data.username)}&text=${encodeURIComponent(data.text)}&icon_url=${data.icon_url}`
            })
            .then(function (response) {
              return response.json()
            }).then(function (response) {

              if(response.message == "not current version") {
                alert(`tnPostのバージョンが違うようです。\n更新します。`);
                location.reload()
                postResult = false
                return false
              } else {
                
                console.log('slack投稿完了', response)

                return response
              }

            })
            .catch((e) => {
              console.error(e.stack, e.message)
            })

            const messageURL = `${that.groupURL}/p${response.ts.split('.').join('')}`

            // 転送チャンネルに投稿
            const postParam = {
              slackChannel: that.slackChannel,
              messageURL: messageURL,
              postUser: fullname,
              mention: slackMention,
              text: send_msg2gas,
              channelID: isToday? that.slackChannel.forwardID02:that.slackChannel.forwardID01
            }
            new ForwardPost(postParam).post()


            $('#submit').prop('disabled', true).removeClass('pulse')

            //スプレッドシート登録へ送信
            const spreadRequest = requestFunc.apiRequestExecution(spreadsheetApi, sheetData, that.key)
            const DBRequest = requestFunc.apiRequestExecution(DBInsertApi, dbData_array, that.key)
            const promises = [spreadRequest, DBRequest]
            let responseData = ""

            async function calRequestFunction() {
              try {
                const response = await requestFunc.apiRequestExecution(calCreateApi, calData, that.key)
                responseData = await response.json()
              } catch (e) {
                let message = ""
                if (e.name === 'AbortError') {
                  message = requestFunc.createCalTimeOutMessage(messageURL)
                } else {
                  message = requestFunc.createCalFailureApplyMessage(messageURL)
                }

                const data = {
                  "channel": that.calendarFailureApplySlackChannelID, 
                  "username": fullname,
                  "text":  "【" + that.groupTitle + "】" + message, 
                  "token": that.slackChannel.token,
                  "icon_url": that.slackChannel.iconURL
                }
       
                // slack投稿
                fetch(that.slackChannel.postURL, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  body: `token=${encodeURIComponent(data.token)}&channel=${encodeURIComponent(data.channel)}&username=${encodeURIComponent(data.username)}&text=${encodeURIComponent(data.text)}&icon_url=${data.icon_url}`
                })


                console.log('カレンダー反映に失敗しました')
              }
            }

            async function spreadUpdateFunction() {
              try {
                if (responseData != "") {
                  const spreadID = sheetData.support_data.is_prod ? "1zQHTirxrdjoqHdNNcmoqG_UXStCAFdmUV_J3o-ENuTc" : "1xsCue6Nnwme_Lw70zN8qQgA0Pg62YFL_1kkFFsRNi9c"
                  const spreadUpdateRequestBody = {
                    "spread": {
                      "id": spreadID,
                      "sheet_name": "シート1"
                    },
                    "target_lines": [
                      {"column": "created_at", "value": NowTime},
                      {"column": "投稿ID", "value": postID}
                    ],
                    "update_cells": [
                      {"column": "カレンダー作成済み", "value": "済"}
                    ]
                  }

                  const calLintkSpreadRequest = requestFunc.apiRequestExecution(spreadsheetUpdateApi, spreadUpdateRequestBody, that.key)
                  promises.push(calLintkSpreadRequest)
                }
              } catch (e) {
                console.error('カレンダー作成済みマークの追加に失敗しました。', e)
              }
            }

            async function calLinkRequestFunction() {
              try {
                const location = responseData.hasOwnProperty("location") ? responseData.location : ""
                const colorId = responseData.hasOwnProperty("colorId") ? responseData.colorId : "0"
                const colorName = requestFunc.calColorNameList(colorId)

                const insertDataCalLink = [{
                  "created_at":{"id": "", "val": NowTime},
                  "案件ID":{"id": "", "val": subject_id},
                  "投稿ID":{"id": "", "val": postID},
                  "イベントID":{"id": "", "val": responseData.iCalUID},
                  "開始時間":{"id": "", "val": apoDate + " " + started_at},
                  "終了時間":{"id": "", "val": apoDate + " " + ended_at},
                  "タイトル":{"id": "", "val": responseData.summary},
                  "説明":{"id": "", "val": func.textEscape(send_msg2gas)},
                  "カレンダーID":{"id": "", "val": responseData.organizer.email},
                  "商談住所":{"id": "", "val": location},
                  "ゲスト":{"id": "", "val": responseData.attendees ? responseData.attendees[0].email : ""},
                  "色":{"id": "", "val": colorName},
                  "投稿URL":{"id": "", "val": messageURL},
                  "イベントURL":{"id": "", "val": responseData.htmlLink},
                }]

                calLinkSheetData.insert_data = insertDataCalLink
                calLinkSheetData.spread.reference_row = 2
                const calLintkSpreadRequest = requestFunc.apiRequestExecution(spreadsheetApi, calLinkSheetData, that.key)
                promises.push(calLintkSpreadRequest)
              } catch (e) {
                console.log('カレンダー連携スプレッドへの格納に失敗しました')
              }
            }

            (async () => {
              await calRequestFunction()
              await calLinkRequestFunction()

              Promise.all(promises)
              .then(async function (response) {
                // Loading非表示
                console.log('シート登録完了', response)
                await spreadUpdateFunction()
              })
              .then(async function () {
                await requestFunc.apiRequestExecution(DBUpdateApi, dbData_update, that.key)
                // 完了画面表示
                console.log('DB更新完了')
                that.vueObj.viewLoader()

                const params = {
                  groupTitle: that.groupTitle,
                  groupURL: that.groupURL,
                  searchURL: messageURL,
                  calURL: responseData.htmlLink,
                  message: '',
                  completeTitle: '投稿完了'
                }
                that.vueObj.viewComplete(params)
              })
              .catch((e) => {
                that.vueObj.viewLoader()
                console.error(e.stack, e.message)
                alert('カレンダー登録中にタイムアウトしました。\n別途再投稿指示があった場合は、再度投稿お願いします')
                window.location.href = '/'
              })  
            })()
        
          })()

          // sendMessagePromise().then(initializeVariableAPgain)
          // clearAllOption() //全項目クリア

        } catch(e) {
          alert("カレンダーに反映できませんでした。再投稿お願いします\r\n\r\n" + e.message)
          // window.location.reload()
          const param = {
            slackChannel: that.slackChannel,
            type: 'error',
            text: JSON.stringify(e),
            channelID: that.slackChannel.errorPostID,
            isProd: that.isProd
          }
          new MessagePost(param).post()
        }
      }
    }

// timer = setInterval(check_load_dom, 50)

function formatDate(date, format) {
  format = format.replace(/YYYY/, date.getFullYear())
  format = format.replace(/MM/, ((date.getMonth() +  1) < 10 ? '0':'') +  (date.getMonth() + 1))
  format = format.replace(/DD/, (date.getDate() < 10 ? '0':'') +  date.getDate())
  format = format.replace(/HH/, (date.getHours() < 10 ? '0':'') +  date.getHours())
  format = format.replace(/mm/, (date.getMinutes() < 10 ?'0':'') +  date.getMinutes())
  return format
}

function delSpace(delEle) {
  const reg1 = /^[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]|[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]$/
  const reg2 = /[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]/g

  if (reg1.test(delEle)) {
    delEle = delEle.replace(reg2, "")
  }
  return delEle
}

  }


  /*******************************
    業種カテゴリデータ取得
  ********************************/
  async getJobsCategories() {
    console.log('業種カテゴリデータ取得開始')

    const $ = this.$

    //業種カテゴリデータ取得
    let jobsCategoryRawData = []

    // Loading表示
    ///that.vueObj.viewLoader('業種カテゴリデータ取得中')
    //データ取得
    //fetch(this.getDataApiURL + '?type=getJobsCategoryData', {

    // DB接続API
    const getCategoriesApiURL = this.store.state.apis[`dbApiURL${this.demo}`] + 'db/select'
    // 接続管理
    const abortController = new AbortController()

    /*********************** 
     * 業種件数取得
    ***********************/
    const params = {
      authC: {
        password: "W!z|a~.x.T~Tzca8CBCm",
        mail: 'system@sungrove.co.jp'
      },
      main_data: {
        tables:["counterparts", "categories", "sub_cates"],
        query: `SELECT sub.id, c.id AS c_id, c.name AS c_name, cat.id AS cat_id, cat.name AS cat_name, sub.name AS sub_name FROM counterparts c INNER JOIN categories cat ON c.id = cat.counterparts_id INNER JOIN sub_cates sub ON cat.id = sub.categories_id AND sub.deleted_at is NULL` // DBの業種カテゴリを取得
      },
      support_data: {
        is_prod: 1 // 開発環境でも本番を参照
      }
    }

    await fetch(getCategoriesApiURL, {
      signal: abortController.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(params)
    })
    .then(response => response.json())
    .then(data => {
      jobsCategoryRawData = data
      return true
    });

    //data = JSON.parse(data)
    console.log('業種カテゴリデータ取得完了、処理開始')

    for(let i = 0; i < jobsCategoryRawData.length; i++) {
      for(let j = 0; j < this.jobsCategoryData.length; j++) {
        if(jobsCategoryRawData[i].c_name == this.jobsCategoryData[j].name) {
          this.jobsCategoryData[j].data.push(jobsCategoryRawData[i])
        }
      }
    }

    try {
      let i = 0
      const sit = setInterval(() => {

        //データ出力先有無をチェック
        if($('#category')[0]) {
          clearInterval(sit)
          this.vueObj.viewLoader()

          //optionタグを作成
          for(let i = 0; i < this.jobsCategoryData.length; i++) {
            let product = this.jobsCategoryData[i]
            let jobs = {}
            let other = false

            for(let j = 0; j < product.data.length; j++) {
              if(!jobs[product.data[j].cat_id]) {

                //「その他」だけ一番最後に表示するために別で処理
                if(product.data[j].cat_name == 'その他') {
                  if(!other) {
                    other = {}
                    other.category = product.data[j].cat_name
                    other.data = []
                  }
                  other.data.push({name: product.data[j].cat_name})

                  continue
                }

                jobs[product.data[j].cat_id] = {}
                jobs[product.data[j].cat_id].category = product.data[j].cat_name
                jobs[product.data[j].cat_id].data = []
              }

              jobs[product.data[j].cat_id].data.push({id: product.data[j].id, name: product.data[j].sub_name})
            }

            //「その他」を最後に追加
            if(other) {
              jobs[999] = other
            }

            let dom = []

            for(let j in jobs) {
              let option = $('<option>')
              option.attr({
                value: jobs[j].category,
                class: 'cate',
                'data-job': JSON.stringify(jobs[j].data),
                'data-id': j
              }).text(jobs[j].category)
              //$('#' + product.id).append(option)
              dom.push(option[0].outerHTML)
            }

            this.jobsCategoryData[i].options = `<optgroup label="${this.jobsCategoryData[i].name}" class="required required_color" name="cate_ops" id="${this.jobsCategoryData[i].id}">` + dom.join('') + `</optgroup>`
          }

          // 費用選択
          for(let k in this.costRange) {
            let costData = this.costRange[k]
            let dom = []

            if(costData.cost.length > 2) {
              for(let l in costData.cost) {
                let option = $('<option>')
                option.attr({
                  value: costData.cost[l],
                  name: 'added_op',
                }).text(costData.cost[l])
                dom.push(option[0].outerHTML)
              }
            } else {
              for(let l = costData.cost[0], n = costData.cost[1]; l <= n; l++ ) {
                let option = $('<option>')
                option.attr({
                  value: l,
                  name: 'added_op',
                }).text(l)
                dom.push(option[0].outerHTML)
              }
            }
            
            this.costRange[k].options = `<optgroup label="" name="sp1" id="${this.costRange[k].id}">` + dom.join('') + `</optgroup>`
          }
          
          // 取得完了フラグ
          this.is_set_hook_option = true
          console.log('業種カテゴリデータ処理完了')
        
        } else {
          i++

          if(i > 10) {
            this.vueObj.viewLoader()
            console.log('#categoryの要素のDOMが取得できませんでした。3秒後にリロードします。')
            clearInterval(sit)
            setTimeout(function() {
              location.reload()
            }, 3000)
          }
        }
      }, 50)

    } catch(e) {
      this.vueObj.viewLoader()
      console.log(e)
    }
  }
}

import RequestBody from "../../assets/js/requestBody.class"
import ForwardPost from "../../assets/js/forwardPost.class"
import MessagePost from "../../assets/js/messagePost.class"

export default {
  apGainOperation
}
