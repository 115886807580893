/*
  編集するデータを取得するクラス
  413830_tn-post-661efef8
*/
import formDataStore from "../formDataStore"
import editApGainDataClass from "./forms/editApGainData.class"
import editApoReportDataClass from "./forms/editApoReportData.class"
import editApplicationsDataClass from "./forms/editApplicationsData.class"
import editJuchuumaekaitouDataClass from "./forms/editJuchuumaekaitouData.class"

export default class {
  constructor(postID, parentObj) {
    this.postID = postID
    this.parentObj = parentObj
    this.isProd = this.parentObj.vueObj.groupTitle.indexOf('demo_') == -1
    this.demo = this.isProd ? "" : "Demo"
    this.key = formDataStore.state.apis[`loginKey${this.demo}`]

    this.params = {
      authC: {
        password: "W!z|a~.x.T~Tzca8CBCm",
        mail: formDataStore.state.userData.email
      },
      main_data: {},
      support_data: {
        is_prod: this.isProd
      }
    }

    this.abortController = new AbortController()
  }

  /*
   データ取得処理
   このメソッドを実行
  */
  async getDataProccess() {
    //this.subjectsID = await this.getSubjectsIDAtPostID()
    await this.setFormTypeInstance()
    return await this.getData()
  }


  /*
   post_idからsubjects_id取得
  */
  async getSubjectsIDAtPostID() {
    // 案件ID検索開始
    this.params.main_data = {
      tables:["subjects"],
      query: `SELECT id FROM subjects WHERE post_id = '${this.postID}'`
    }

    return await fetch(formDataStore.state.apis[`dbSelect${this.demo}`], {
      signal: this.abortController.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.params)
    })
    .then(response => response.json())
    .then(response => {
      return response.length > 0? response[0].id: false 
    })
  }

  /*
   subjects_id存在確認
  */
   async checkSubjectsID(id) {
    // 案件ID検索開始
    this.params.main_data = {
      tables:["subjects", "ap_negotiations", "sub_cates", "categories", "hooks", "suggestions"],
      query: `SELECT s.id AS id, s.name AS name, s.postcode AS postcode, s.company_address AS company_address, an.negotiation_date AS negotiation_date, an.started_at AS started_at, (SELECT name FROM hooks WHERE id = an.hooks_id) AS hook, (SELECT name FROM suggestions WHERE id = an.suggestions_id) AS suggestion, sc.name AS sub_cates_name, c.name AS categories_name, s.post_id AS post_id FROM subjects s INNER JOIN ap_negotiations an ON s.id = an.subjects_id INNER JOIN sub_cates sc ON sc.id = s.sub_cates_id INNER JOIN categories c ON sc.categories_id = c.id  WHERE s.id = '${id}'`
    }

    return await fetch(formDataStore.state.apis[`dbSelect${this.demo}`], {
      signal: this.abortController.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.params)
    })
    .then(response => response.json())
    .then(response => {
      return response.length > 0? response: [] 
    })
  }

  /*
    現在開いているフォームの編集機能インスタンス化
  */
  async setFormTypeInstance() {
    console.log(this.parentObj.formType)
    switch(this.parentObj.formType) {
      // アポ獲得報告
      case 'apgain': {
        this.formInstance = await new editApGainDataClass(this)
        break
      }
      // 商談報告
      case 'aporeport': {
        this.formInstance = await new editApoReportDataClass(this)
        break
      }
      // 申込書報告
      case 'applications': {
        this.formInstance = await new editApplicationsDataClass(this)
        break
      }
      // 受注前回答
      case 'juchuumaekaitou': {
        this.formInstance = await new editJuchuumaekaitouDataClass(this)
        break
      }
    }

    return true
  }

  /*
    編集対象のデータ取得
  */
  async getData() {
    try {
      return await this.formInstance.getData()
    } catch(e) {
      alert('データ取得に失敗')
      console.error(e)
      return false
    }
  }

  /*
   optionのdata属性取得
  **/
   getOptionsDataset(name, key) {
    const selector = name.match(/^\.|^#/)? name: `[name="${name}"]`
    const index = document.querySelector(selector).selectedIndex
    
    return document.querySelectorAll(`${selector} option`)[index].dataset[key]
  }

  /*
   checkbox,radioのdata属性取得
  **/
   getCheckBoxDataset(name, key) {
    let inputTxt = []
    for (let i = 0; i < checkboxOp.length; i++ ) {

      if( checkboxOp[i].checked ) inputTxt.push(checkboxOp[i].value)

    }
  }

  /*
  * 値取得 - チェックされた値を取得する関数
  * @params {Obj} チェックするセレクタ
  * @params {key} data属性のkey 初期値なし
  * @return {Str} aaa/bbb の形式で返す
  **/
  checkedVal(checkboxOp, key) {
    let inputTxt = []

    for (let i = 0; i < checkboxOp.length; i++ ) {

      if( checkboxOp[i].checked ) {
        const val = !key? checkboxOp[i].value: checkboxOp[i].dataset[key] 
        inputTxt.push(val)
        
      }

    }

    return inputTxt.join(!key? '/': '')
  }
  

  /*
    取得したデータをフォームに反映
  */
  async applyDataToForm() {
    await this.formInstance.applyDataToForm()
  }

  /*
    フォームデータからクエリ構築
  */
  async buildQuery() {
    await this.formInstance.buildQuery()
  }

  /*
    DBをアップデート
  */
  async updateDB() {
    await this.formInstance.updateDB()
  }

  /*
    DBに挿入
  */
  async insertDB() {
    await this.formInstance.insertDB()
  }

  /*
    シートをアップデート
  */
  async updateSheet() {
    await this.formInstance.updateSheet()
  }

  /*
    シートに挿入
  */
  async insertSheet() {
    await this.formInstance.insertSheet()
  }


  /*
    フォームデータからクエリ構築
  */
  async buildDeleteQuery() {
    await this.formInstance.buildDeleteQuery()
  }
  
  /*
   データ論理削除処理
   このメソッドを実行
  */
  async deleteDBData() {
    await this.formInstance.deleteDBData()
  }

  async deleteSheetData() {
    await this.formInstance.deleteSheetData()
  }
 
}