/*
/src/assets/js/applications.v2/formDataStore.js
このファイルは上記ファイルと内容にてるけど上記ファイルはこのファイルがアプリ全体で稼働し始めたら削除予定
*/

import { createApp } from 'vue';
import { createStore } from 'vuex';

const app = createApp();
const formDataStore = createStore({

  state: {
    siteName: '',
    version: '',
    commonFlag: 0,
    formTypeName: '',
    formMode: 'post',
    //msg: '',

    apis: {
      getFormGroupListURL: '',
      getDataApiURL: '',
      spreadsheet: '',
      spreadsheetUpdate: '',
      calCreate: '',
      calUpdate: '',
      dbApiURL: '',
      dbApiURLDemo: '',
      loginKey: '',
      loginKeyDemo: ''
    },

    userData: {
      displayName: '',
      email: '',
      userID: 0,
      firstName: '',
      lastName: '',
      BN: '',
      emoji: '',
      depts_id: '',
      prefectures_id: '',
      section_name: '',
      team_name: '',
      label_infos_ids: '',
      sections_id: '',
      teams_id: '',
      office_id: ''
    },

    testAccount: [],

    groupList: [],

    industryNamesData: [],

    KintoneOptionsNamesData: [],

    formData: {
      common: {},
      itzai: {},
      itzaiLite: {},
      ec: {},
      wel: {},
      relo: {},
      seo: {},
      meo: {},
      cms: {},
      subsidy: {},
      com_option: {},
      video_production: {},
      option: {}
    },

    msg: {
      common: '',
      itzai: '',
      itzaiLite: '',
      ec: '',
      wel: '',
      relo: '',
      seos: '',
      meos: '',
      cms: '',
      subsidy: '',
      com_option: '',
      video_production: '',
      option: ''
    },

    sheetData: {
      common: {},
      itzai: {},
      itzaiLite: {},
      ec: {},
      wel: {},
      relo: {},
      seo: {},
      meo: {},
      cms: {},
      subsidy: {},
      com_option: {},
      video_production: {},
      option: {},
    },

    dataIDs: {
      subject_id: '',
      user_sg_id: '',
      sold_price: '',
      hooks_id: '',
      suggestions_id: []
    },

    loadFlag: {
      list: 0,
      userData: 0
    },

    pageParams: {},

    editMode: false,

    editData: {}
  },

  getters: {
    groupList(state) {
      return state.groupList;
    },

    editData(state) {
      return state.editData;
    },
  },

  mutations: {
    setEditMode(state, bool) {
      state.editMode = bool
    },

    setEditData(state, data) {
      state.editData[data.type] = data.data
    },

    setSiteName(state, str) {
      state.siteName = str;
    },

    setVersion(state, str) {
      state.version = str;
    },

    setFlag(state, name) {
      state.loadFlag[name] = 1;
    },

    setFormMode(state, str) {
      state.formMode = str;
    },

    setApis(state, obj) {
      state.apis = obj;
    },

    setTestAccount(state, ary) {
      state.testAccount = ary;
    },

    setGroupListData(state, ary) {
      state.groupList = ary;
    },

    setIndustryNamesData(state, ary) {
      state.industryNamesData = ary;
    },

    setKintoneOptionsNamesData(state, ary) {
      state.KintoneOptionsNamesData = ary;
    },

    setUserData(state, obj) {
      state.userData[obj[0]] = obj[1];
    },

    setTalknoteMessage(state, msg) {
      state.msg[msg[0]] = msg[1];
    },

    setValue(state, obj) {
      if(!state.formData[obj[0]]) state.formData[obj[0]] = {} 
      state.formData[obj[0]][obj[1]] = obj[2];

      // sheetData
      if(obj[3] != '') {
        if(!state.sheetData[obj[0]]) state.sheetData[obj[0]] = {}
        state.sheetData[obj[0]][obj[3]] = obj[2];
      }      
    },

    setDataIDs(state, obj) {
      state.dataIDs[obj[0]] = obj[1];
    },

    setPageParams(state, obj) {
      state.pageParams = obj;
    },

    delValue(state, formName) {
      state.sheetData[formName] = {}
      state.formData[formName] = {}
      state.msg[formName] = ''
    },

    delAllValue(state) {
      /*state.groupList = []

      state.userData = {
        displayName: '',
        email: '',
        userID: 0,
        firstName: '',
        lastName: '',
        BN: '',
        emoji: '',
      }*/

      state.formData = {
        common: {},
        itzai: {},
        itzaiLite: {},
        ec: {},
        wel: {},
        relo: {},
        seo: {},
        meo: {},
        cms: {},
        subsidy: {},
        com_option: {},
        video_production: {},
        option: {}
      }

      state.msg = {
        common: '',
        itzai: '',
        itzaiLite: '',
        ec: '',
        wel: '',
        relo: '',
        seos: '',
        meos: '',
        cms: '',
        subsidy: '',
        com_option: '',
        video_production: '',
        option: ''
      }

      state.sheetData = {
        common: {},
        itzai: {},
        itzaiLite: {},
        ec: {},
        wel: {},
        relo: {},
        seo: {},
        meo: {},
        cms: {},
        subsidy: {},
        com_option: {},
        video_production: {},
        option: {},
      }

      state.dataIDs = {
        subject_id: '',
        user_sg_id: '',
        sold_price: '',
        hooks_id: '',
        suggestions_id: []
      }


      for(let i in state) {

        /*if(i == 'sheetData' || i == 'formData' || i == 'msg') {
          state[i] = {};
        }*/

        if(i == 'inputNoahFlag') {
          state.inputNoahFlag = '×';
        }

        if(i == 'seos') {
          state.seos = {type_num: '', url: '', word_num: '', words: []};
        }

        if(i == 'meos') {
          state.meos = {url: '', address: '', word_num: '', words: []};
        }
      }
    },

  }
});

app.use(formDataStore);

export default formDataStore;
