/**
  リスキャン用フォームコンポーネントv2
*/
<template>
  <Header></Header>
  <div class="l-wrapper">
    <div class="l-content">

      <Title
      :groupTitle="groupTitle"
      ></Title>

      <Section>
        <Description>
          お疲れさまです。<br>
          <span v-html="displayName"></span>さん
        </Description>
      </Section>

      <form action="" method="get" id="form_ap" autocomplete="off">

        <Box>

          <Column id="searchID">
            <label for="name">案件IDで案件検索</label>
            <div class="display_yoko">
              <div class="display_yoko_inner">
                <input type="text" id="SubjectId" placeholder="（例）AP111111_111111" min="22">
              </div>
            </div>
          </Column>

          <Column id="result_api">
            <label>検索コメント：</label>
            <label id="status_comment" class="c-notice">案件IDを入力すると検索開始します。</label>
            <div class="width100 text_center">
              <span class="wrapper4 timer-loader" id="loading_form"></span>
            </div>
            <div class="display_tate" id="result_titles">
            </div>
          </Column>

          <Column class="pd3_parts autoG">
            <label>アポ種別</label>
            <div class="display_yoko">
              <div class="display_yoko_inner">
                <input type="text" class="auto required form_txt" id="ApType" readonly>
              </div>
            </div>
          </Column>

          <Column class="autoG">
            <label>案件名</label>
            <div class="display_yoko">
              <div class="display_yoko_inner">
                <input type="text" class="auto required form_txt" id="ComName" readonly>
              </div>
            </div>
          </Column>

          <Column class="autoG" style="display: none;">
            <label>登録カレンダー</label>
            <div class="display_yoko">
              <div class="display_yoko_inner">
                <input type="text" class="auto form_txt" id="CalName" readonly>
              </div>
            </div>
          </Column>

          <Column class="autoG">
            <label>Gカレ上商談日時</label>
            <div class="display_yoko">
              <div class="display_yoko_inner">
                <input type="text" class="auto required form_txt" id="MtgDate" placeholder="20xx年1月1日(月)14時00分〜16時00分" readonly>
              </div>
            </div>
          </Column>

          <div class="rap1_boder" id="wrap2" style="display: none;">

            <Column class="pd3_parts filed_proxy">
              <label class="c-choice">
                <input type="checkbox" id="proxy_flag" class="form_check">
                <span id="center">代理投稿or投稿者が営業</span>
              </label>
            </Column>

            <Column class="pd3_parts" style="display:none;" id="filed_ap_name">
              <label for="name">担当AP</label>
              <div class="width60 display_yoko">
                <div class="display_yoko_inner">
                  <input type="text" placeholder="山田太郎" id="ApName">
                </div>
              </div>
            </Column>

            <Column class="pd3_parts">
              <label class="c-choice">
                <input type="checkbox" id="fixed_flag" class="form_check">
                <span id="fixed">担当固定</span>
                <Notice>
                  ※振り分けられた営業担当が固定の場合はチェックを入れてください<br>
                  ※自アポ自営、再訪はチェック不要
                </Notice>
              </label>
            </Column>

            <Column class="pd3_parts">
              <label>ステータス</label>
              <div class="c-select">
                <select class="required form_select" id="Status">
                  <option class="select_status" value="" disabled selected>--ステータス 選択--</option>
                  <option class="select_status" value="リスケ" data-id="1">リスケ</option>
                  <option class="select_status" value="キャンセル" data-id="2">キャンセル</option>
                  <option class="select_status" value="前不" data-id="3">前不</option>
                  <option class="select_status" value="復活" data-id="4">復活</option>
                  <option class="select_status" value="前確OK" data-id="5">前確 OK</option>
                </select>
              </div>
            </Column>

            <Column class="pd3_parts filed_changed slideUpEle" style="display:none;">
              <div>
                <label class="c-label attention width_check title">変更点</label>
                <div class="display_yoko">
                  <ul class="c-choices display_yoko_inner -flex">
                    <li class="c-choices__item">
                      <label class="c-choice ChangedPoints Risuke Hukkatsu">
                        <input class="required checkChangedPoints form_check" type="checkbox" name="ChangedPoints" id="checkMtgDate" value="商談日" data-id="1">
                        <span class="">商談日</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice ChangedPoints Risuke Hukkatsu">
                        <input class="required checkChangedPoints form_check" type="checkbox" name="ChangedPoints" id="checkDuration" value="商談時間" data-id="2">
                        <span class="">商談時間</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice Risuke ChangedPoints Hukkatsu">
                        <input class="required checkChangedPoints form_check" type="checkbox" name="ChangedPoints" id="checkMtgType" value="商談形式" data-id="3">
                        <span class="">商談形式</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice ChangedPoints Risuke Hukkatsu" id="ChangedPointsMtgType">
                        <input class="required checkChangedPoints form_check" type="checkbox" name="ChangedPoints" id="checkMtgAdd" value="商談住所" data-id="4">
                        <span class="">商談住所</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice ChangedPoints Risuke Yet">
                        <input class="required checkChangedPoints form_check" type="checkbox" name="ChangedPoints" id="checkYet" value="未定" data-id="5">
                        <span class="">未定</span>
                      </label>
                    </li>
                    <li class="c-choices__item">
                      <label class="c-choice ChangedPoints Nashi Hukkatsu">
                        <input class="required checkChangedPoints form_check" type="checkbox" name="ChangedPoints" id="checkNo" value="なし" data-id="6">
                        <span class="">なし</span>
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </Column>

            <Column class="pd3_parts filed_MtgDate slideUpEle" style="display:none;">
              <label>商談日時</label>
              <Group class="display_yoko pd3_parts filed_MtgDate_layout">
                <div class="">
                  <input type="date" id="ChangeDate" class="form_txt" min="" placeholder="日付指定">
                </div>
                <Flex id="ap_time" class="display_yoko">
                  <input type="text" class="time start f_time form_txt" id="ChangeStart" placeholder="開始時間" />
                  <input type="text" class="time end f_time form_txt" id="ChangeEnd" placeholder="終了時間" />
                </Flex>
              </Group>
            </Column>

            <Column class="pd3_parts filed_duration" style="display:none;">
              <label>確保時間</label>
              <Flex class="display_yoko">
                <input type="Number" id="Duration" class="form_txt" placeholder ="自動計算します" readonly>
                <span>分</span>
              </Flex>
            </Column>

            <Column class="width30 pd3_parts filed_MtgType form_txt slideUpEle" style="display:none;">
              <label>商談形式</label>
              <div class="c-select">
                <select name="mtgType" class="form_select" id="MtgType">
                  <option value="" disabled selected>--商談形式 選択--</option>
                  <option name="MtgType" value="オンライン" data-type="オンライン" data-id="1">オンライン</option>
                  <option name="MtgType" value="来社" data-type="来社" data-id="2">来社</option>
                  <option name="MtgType" value="カフェ" data-type="対面" data-id="3">カフェ</option>
                  <option name="MtgType" value="訪問" data-type="対面" data-id="3">訪問</option>
                </select>
              </div>
            </Column>

            <Column class="pd3_parts filed_MtgAdd slideUpEle" id="fieldMtgAdd" style="display:none;">
              <label>商談住所</label>
              <div>
                <div>
                  <input type="text" class="form_txt" id ="MtgAdd" name="pref_name" placeholder="都道府県・市区町村" pattern="(東京都|北海道|(?:京都|大阪)府|.{2,3}県).*">
                </div>
              </div>
            </Column>

            <Column class="pd3_parts field_mtgAddReason" style="display:none;">
              <label>商談住所の変更理由</label>
              <ul class="c-choices">
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" class="form_check" name="changedMtgAddReason" id="" value="「訪問」→「カフェ」の変更" data-type="">
                    <span class="">「訪問」→「カフェ」の変更</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" class="form_check" name="changedMtgAddReason" id="" value="「カフェ」→「訪問」の変更" data-type="">
                    <span class="">「カフェ」→「訪問」の変更</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" class="form_check" name="changedMtgAddReason" id="" value="商談形式は変更ないが、シンプルな住所変更" data-type="">
                    <span class="">商談形式は変更なしの住所変更</span>
                  </label>
                </li>
              </ul>
            </Column>

            <Column class="pd3_parts filed_VisitArea slideUpEle" style="display:none;">
              <label>来社エリア</label>
              <ul class="c-choices">
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" class="form_check" name="area" id="area_kanto" value="東京" data-type="関東来社" data-id="1">
                    <span class="required_color">東京</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" class="form_check" name="area" id="area_kansai" value="大阪" data-type="関西来社" data-id="1">
                    <span class="required_color">大阪</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" class="form_check" name="area" id="area_nagoya" value="名古屋" data-type="名古屋来社" data-id="1">
                    <span class="required_color">名古屋</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" class="form_check" name="area" id="area_hukuoka" value="福岡" data-type="福岡来社" data-id="1">
                    <span class="required_color">福岡</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" class="form_check" name="area" id="area_yokohama" value="横浜" data-type="横浜来社" data-id="1">
                    <span class="required_color">横浜</span>
                  </label>
                </li>
              </ul>
            </Column>

            <Column class="width30 pd3_parts filed_Device slideUpEle" style="display:none;">
              <label>使用デバイス</label>
              <div class="c-select">
                <select name="device" id="Device" class="form_select">
                  <option value="" disabled selected>--使用デバイス選択--</option>
                  <option data-id="1">カメラ付きPC</option>
                  <option data-id="2">カメラなしPC</option>
                  <option data-id="3">タブレット</option>
                  <option data-id="4">スマホ</option>
                </select>
              </div>
            </Column>

            <Column class="pd3_parts" id ="fieldReasons">
              <label id="nameReason">理由</label>
              <br>
              <div class="list_neck" id="feildNeckDetails">
              </div>
            </Column>

            <Column class="pd3_parts">
              <label>録音URL</label>
              <div>
                <input type="url" class="form_txt" pattern="https://.*" placeholder="https://sungrove.telforce.biz/recording/mp3/example.wav.mp3" id="RecordURL">
              </div>
            </Column>

            <Column class="pd3_parts">
              <label>備考</label>
              <div>
                <textarea style="width:99%; height:80px; " id="Remarks" placeholder="メンションは投稿後のコメントからお願いします。"></textarea>
              </div>
            </Column>

          </div>

        </Box>

        <div class="form_wrap">
          <div class="pd2 text_center">
            <Button :tag="'a'" class="waves-effect waves-light btn disabled" id="btn">slackへ送信</Button>
          </div>

          <div class="pd2">
            <textarea style="width:99%; height:300px; display:none" id="txt_box"></textarea>
          </div>
        </div>

      </form>

    </div>
  </div>
  <Loading :class="{ '-active': isLoading }" :loaderText="loaderText"></Loading>
  <Complete v-if="isComplete" :groupTitle="groupTitle" :groupURL="groupURL" :searchURL="searchURL" :calURL="calURL" :message="message" :completeTitle="completeTitle"></Complete>
</template>

<script>
  import { defineComponent } from 'vue'
  import moment from "moment"
  import $ from 'jquery'
  import sha256 from 'crypto-js/sha256'
  import formDataStore from "../../assets/js/formDataStore"
  import formFuncClass from "../../assets/js/formFunctions.class.js"
  import reScheduleCancelClass from "../../assets/js/reScheduleCancel.class.js"

  
  // コンポーネントを読み込む
  import Header from '../organisms/header.vue';
  import Title from '../organisms/title.vue';
  import Loading from '../organisms/loading.vue';
  import Complete from '../complete.vue';

  import Variables from '../molecules/variables.vue';
  import Choices from '../molecules/choices.vue';
  import Box from '../molecules/box.vue';
  import Section from '../molecules/section.vue';
  import Column from '../molecules/column.vue';
  import Group from '../molecules/group.vue';
  import Flex from '../molecules/flex.vue';
  import Block from '../molecules/block.vue';

  import Description from '../atoms/description.vue';
  import Notice from '../atoms/notice.vue';
  import Label from '../atoms/label.vue';
  import Input from '../atoms/input.vue';
  import Select from '../atoms/select.vue';
  import Textarea from '../atoms/textarea.vue';
  import Button from '../atoms/button.vue';
  import IconAdd from '../atoms/icon-add.vue';
  import IconRemove from '../atoms/icon-remove.vue';

  export default defineComponent({
    name: 'formReScheduleCancel',

    components: {
      Header,
      Title,
      Loading,
      Complete,

      Variables,
      Choices,
      Box,
      Section,
      Column,
      Group,
      Flex,
      Block,

      Description,
      Notice,
      Label,
      Input,
      Select,
      Textarea,
      Button,
      IconAdd,
      IconRemove,
    },

    data() {
      return {
        isLoading: false,
        isComplete: false,
        isBody: true,
        loaderText: '',
        groupTitle: '',
        searchURL: '',
        calURL: '',
        message: '',
        completeTitle: '',
        displayName: '',
      }
    },

    methods: {
      init() {
        console.log('フォーム初期化開始')
        this.thisFormParams = this.store.state.groupList.filter((obj) => obj[0].trim() == this.$route.params.type.trim())[0]
        this.groupTitle = this.thisFormParams[0]
        this.displayName = this.store.state.userData.displayName
        this.isProd = this.groupTitle .indexOf('demo_') == -1
        
        // 投稿先のtalknoteノートURL
        this.groupURL = this.thisFormParams[1]

        // talknote投稿用アドレス
        this.talknoteGroupAddress = this.thisFormParams[2]

        document.documentElement.scrollTop = 0

        // 投稿先のslackチャンネル情報
        this.slackChannel = {
          id: this.thisFormParams[6],
          userID: this.store.state.userData.slackUserID,
          name: this.thisFormParams[7],
          postURL: this.store.state.apis.slackPOSTURL,
          workSpaceURL: this.store.state.apis.slackWorkSpaceURL,
          token: this.store.state.apis.slackToken,
          iconURL: this.store.state.userData.emoji,
          forwardID01: this.thisFormParams[8],
          forwardID02: this.thisFormParams[9],
          errorPostID: this.store.state.apis.errorPostSlackChannelID
        }

        const func = new formFuncClass.formFunctions(moment, $, sha256)
        new reScheduleCancelClass.reScheduleCancel(this, $, func, moment)
        console.log('フォーム初期化完了')
      },

      viewLoader(loaderText = '') {
        this.isLoading = !this.isLoading
        this.loaderText = loaderText
        console.log('ローディング表示')
      },

      viewComplete(param) {
        this.groupTitle = param.groupTitle;
        this.groupURL = param.groupURL;
        this.searchURL = param.searchURL;
        this.calURL = param.calURL;
        this.completeTitle = '投稿完了';
        this.isBody = false;
        this.isComplete = true;
        console.log('完了画面表示')
      },
    },

    mounted() {

      this.store = formDataStore

      if(this.store.state.groupList.length == 0) {
        this.store.watch(
          (state, getters) => getters.groupList,
          (newValue, oldValue) => {
            console.log('ページ固有のデータ取得');
            this.init()
          }
        )
      } else {
        this.init()
      }
    },

    setup(_, context) {
      return {}
    },
  });
</script>

