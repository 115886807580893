import { createApp } from 'vue'
import App from './App.vue'
import router from './router'; // Add
const app = createApp(App);
app.use(router); // Add
app.mount('#app');


//history.pushState('', '', '');

window.addEventListener("popstate", function () {
  //history.pushState('', '', '');
});

// ログインしていなかったらリダイレクト
window.onpageshow = function(event:any) {
  if (event.persisted) {
    window.location.reload();
  }
};