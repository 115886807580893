/*
* ぽちボタンクリック後の処理
*/ 

class afrPostSubsidy {
  constructor(that) {
    this.that = that
    this.formName = "助成金Lab"
    this.that.$('.search_form_wrap').show()
  }

  returnFormName() {
    return this.formName
  }

  getInputData() {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const checkedSubjects = document.querySelectorAll('.c-choice.subjects input[type="checkbox"]')
    const checkedDocuments = document.querySelectorAll('.c-choice.documents input[type="checkbox"]')
    const subjectsArray = this.getCheckedBoxArray(checkedSubjects)
    const documentsArray = this.getCheckedBoxArray(checkedDocuments)

    store.commit('setValue', ['subsidy', 'input_subjects_val', subjectsArray, 'subjectsArray'])
    store.commit('setValue', ['subsidy', 'input_documents_val', documentsArray, 'documentsArray'])
    store.commit('setValue', ['subsidy', 'input_recruitment_val', $('#recruitment_status').val(), 'recruitment_status'])
    store.commit('setValue', ['subsidy', 'input_concern_val', $('#concern').val(), 'concern'])
  }
  
  getCheckedBoxArray(checkboxes) {
    const selectedValues = []
    checkboxes.forEach(checkbox => {
      if (checkbox.checked) {
        selectedValues.push(checkbox.parentNode.textContent.trim())
      }
    })
    return selectedValues
  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    const sendVal = 
`
◎助成金
対象助成金：${store.state.formData.subsidy.input_subjects_val}
未回収必須書類：${store.state.formData.subsidy.input_documents_val}
直近の採用状況：${store.state.formData.subsidy.input_recruitment_val}
申請時の懸念点：${store.state.formData.subsidy.input_concern_val}
`
    store.commit('setTalknoteMessage', ['subsidy', sendVal])
  }
}

export default {
  afrPostSubsidy
}