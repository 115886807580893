/**
  受注前回答用フォームコンポーネントv2
*/
<template>
  <Header></Header>
  <div class="l-wrapper">
    <div class="l-content">

      <Title
      :isAdmin="isAdmin"
      :groupTitle="groupTitle"
      :modeChangeBtnText="modeChangeBtnText"
      :modeChangeBtnLabel="modeChangeBtnLabel"
      :modeStatus="modeStatus"
      :modeChange="modeChange"
      :edit="edit"
      :post="post"
      ></Title>

      <Section>
        <Description>
          お疲れさまです。<br>
          <span v-html="displayName"></span>さん
        </Description>
      </Section>

      <form action="" method="get" id="form_ap" autocomplete="off">

        <Box class="form_ap_area">
          <div class="form-example">
            <div class="form-example-inner">

              <Column id="searchID" class="js-notEditable">
                <label>案件IDで案件検索</label>
                <div class="form_parts">
                  <input id="subject_id_tag" class="required" type="text" name="subject_id" data-id="" placeholder="（例）AP111111_111111" min="22" value>
                </div>
              </Column>
              <Column id="result_api" class="js-notEditable">
                <label>検索コメント：</label>
                <label id="status_comment" class="c-notice">案件IDを入力すると検索開始します。</label>
                <div class="width100 text_center">
                  <span class="wrapper4 timer-loader" id="loading_form"></span>
                </div>
                <div class="display_tate" id="result_titles">
                </div>
              </Column>
              <Column class="form_wrap js-notEditable">
                <label>案件名</label>
                <div class="form_parts">
                  <input id="subject_name_tag" class="required" type="text" name="subject_name" value>
                </div>
              </Column>
              <Column class="form_wrap js-editable">
                <label>受注形態</label>
                <ul class="c-choices">
                  <li class="c-choices__item">
                    <label class="c-choice" for="radio_button_1">
                      <input id="radio_button_1" class="required" type="radio" name="orders_type" value="受注" v-model="editableItems.orders_type" />
                      <span>受注</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice" for="radio_button_2">
                      <input id="radio_button_2" class="required" type="radio" name="orders_type" value="追客延長A" v-model="editableItems.orders_type" />
                      <span>追客延長A</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice" for="radio_button_3">
                      <input id="radio_button_3" class="required" type="radio" name="orders_type" value="追客延長B" v-model="editableItems.orders_type" />
                      <span>追客延長B</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice" for="radio_button_4">
                      <input id="radio_button_4" class="required" type="radio" name="orders_type" value="追客延長C" v-model="editableItems.orders_type" />
                      <span>追客延長C</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice" for="radio_button_5">
                      <input id="radio_button_5" class="required" type="radio" name="orders_type" value="追客延長D" v-model="editableItems.orders_type" />
                      <span>追客延長D</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice" for="radio_button_6">
                      <input id="radio_button_6" class="required" type="radio" name="orders_type" value="追客延長E" v-model="editableItems.orders_type" />
                      <span>追客延長E</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice" for="radio_button_7">
                      <input id="radio_button_7" class="required" type="radio" name="orders_type" value="失注" v-model="editableItems.orders_type" />
                      <span>失注</span>
                    </label>
                  </li>
                </ul>
              </Column>
              <Column class="form_wrap js-editable">
                <label>次回アクション日</label>
                <label id="next_action_date_tag_check"></label>
                <Flex id="next_action_date" class="display_yoko">
                  <div class="form_parts">
                    <input type="date" id="next_action_date_tag" class="required form_txt" min="" name="next_action_date" placeholder="日付指定" v-model="editableItems.next_action_date">
                  </div>
                  <div class="form_parts">
                    <input id="next_action_time_tag" type="text" name="next_action_time" placeholder="開始時間" value v-model="editableItems.next_action_time">
                  </div>
                </Flex>
              </Column>
              <Column class="form_wrap js-notEditable">
                <label>理由</label>
                <div class="form_parts">
                  <textarea id="reasons_tag" class="required" name="reasons"></textarea>
                </div>
              </Column>
              <Column class="form_wrap js-notEditable">
                <label>備考</label>
                <div class="form_parts">
                  <textarea id="other_things_tag" name="other_things"></textarea>
                </div>
              </Column>
            </div>
          </div>
        </Box>

        <div class="pd2 text_center form_wrap" id="filedBtn">
          <Button :tag="'a'" class="waves-effect waves-light btn disabled" id="btn">slackへ送信</Button>
        </div>

      </form>

    </div>
  </div>
  <Loading :class="{ '-active': isLoading }" :loaderText="loaderText"></Loading>
  <Complete v-if="isComplete" :groupTitle="groupTitle" :groupURL="groupURL" :searchURL="searchURL" :message="message" :completeTitle="completeTitle"></Complete>
</template>


<script>
  import { defineComponent } from 'vue'
  import moment from "moment"
  import $ from 'jquery'
  import "jquery-ui"
  import sha256 from 'crypto-js/sha256'
  import formDataStore from "../../assets/js/formDataStore"
  import juchuumaeKaitouClass from "../../assets/js/juchuumaeKaitou.class.js"
  import formFuncClass from "../../assets/js/formFunctions.class.js"

  // コンポーネントを読み込む
  import Header from '../organisms/header.vue';
  import Title from '../organisms/title.vue';
  import Loading from '../organisms/loading.vue';
  import Complete from '../complete.vue';

  import Variables from '../molecules/variables.vue';
  import Choices from '../molecules/choices.vue';
  import Box from '../molecules/box.vue';
  import Section from '../molecules/section.vue';
  import Column from '../molecules/column.vue';
  import Group from '../molecules/group.vue';
  import Flex from '../molecules/flex.vue';
  import Block from '../molecules/block.vue';

  import Description from '../atoms/description.vue';
  import Label from '../atoms/label.vue';
  import Input from '../atoms/input.vue';
  import Select from '../atoms/select.vue';
  import Textarea from '../atoms/textarea.vue';
  import Button from '../atoms/button.vue';
  import IconAdd from '../atoms/icon-add.vue';
  import IconRemove from '../atoms/icon-remove.vue';

  export default defineComponent({
    name: 'formJuchuumaekaitou',

    components: {
      Header,
      Title,
      Loading,
      Complete,

      Variables,
      Choices,
      Box,
      Section,
      Column,
      Group,
      Flex,
      Block,

      Description,
      Label,
      Input,
      Select,
      Textarea,
      Button,
      IconAdd,
      IconRemove,
    },

    data() {
      return {
        isLoading: false,
        isComplete: false,
        isBody: true,
        loaderText: '',
        displayName: '',
        groupTitle: '',
        searchURL: '',
        message: '',
        completeTitle: '',
        modeChangeBtnText: '',
        modeChangeBtnLabel: '',
        modeStatus: '',
        
        isAdmin: false,
        modeChangeBtnText: '',
        modeChangeBtnLabel: '',
        modeStatus: '',
        modeChange: () => {},
        edit: true,
        post: false,

        editableItems: {}
      }
    },

    methods: {
      init() {
        console.log('フォーム初期化開始')
        // このフォームの名前とかtalknoteの投稿用アドレスなどの情報格納
        this.thisFormParams = this.store.state.groupList.filter((obj) => obj[0].trim() == this.$route.params.type.trim())[0]

        // タイトル表示
        this.groupTitle = this.thisFormParams[0]

        // フォームが本番かdemoか
        this.isProd = this.thisFormParams[0].indexOf('demo_') == -1

        // ユーザー名表示
        // メアドとかもthis.store.state.userDataに入ってる
        this.displayName = this.store.state.userData.displayName

        // 投稿先のslackチャンネル情報
        this.slackChannel = {
          id: this.thisFormParams[6],
          userID: this.store.state.userData.slackUserID,
          name: this.thisFormParams[7],
          postURL: this.store.state.apis.slackPOSTURL,
          workSpaceURL: this.store.state.apis.slackWorkSpaceURL,
          token: this.store.state.apis.slackToken,
          iconURL: this.store.state.userData.emoji,
          forwardID01: this.thisFormParams[8],
          forwardID02: this.thisFormParams[9],
          errorPostID: this.store.state.apis.errorPostSlackChannelID
        }

        document.documentElement.scrollTop = 0

        // 投稿先のtalknoteノートURL
        // console.log('投稿先のtalknoteノートURL', this.thisFormParams[1])

        // talknote投稿用アドレス
        // console.log('talknote投稿用アドレス', this.thisFormParams[2])

        // 表示するフォームのタイプ
        // console.log('表示するフォームのタイプ', this.thisFormParams[3])

        // 外部にあるフォームで使う汎用関数インスタンス化
        const func = new formFuncClass.formFunctions(moment, $, sha256, this)
        new juchuumaeKaitouClass.juchuumaeKaitou(this, $, func, moment)

        console.log('フォーム初期化完了')
      },

      viewLoader(loaderText = '') {
        if(
            (!this.loaderText && !loaderText) ||
            (this.isLoading && loaderText == '')
          ) {
          this.isLoading = false
          this.loaderText = ''
          return 
        }
        this.isLoading = true
        this.loaderText = loaderText
        console.log('ローディング表示')
      },

      viewComplete(param) {
        this.groupTitle = param.groupTitle;
        this.groupURL = param.groupURL;
        this.searchURL = param.searchURL;
        this.message = param.message;
        this.completeTitle = param.completeTitle? param.completeTitle: '投稿完了';
        this.isBody = false;
        this.isComplete = true;
        console.log('完了画面表示')
      },
    },

    mounted() {

      this.store = formDataStore

      if(this.store.state.groupList.length == 0) {

        // このフォームに直接飛んできたときにフォーム情報取得が完了してないことがあるので同期待ち
        this.store.watch(
          (state, getters) => getters.groupList,
          (newValue, oldValue) => {
            console.log('ページ固有のデータ取得');
            //初期化
            this.init()
          }
        )
      } else {
        //初期化
        this.init()
      }
    },

    setup(_, context) {
      return {}
    }
  });
</script>
