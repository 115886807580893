
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'loader',

  props: {
    loaderText: String
  }
});
