/*
* ぽちボタンクリック後の処理
*/ 

class afrPostComOption {
  constructor(that) {
    this.that = that
    this.formName = "補償オプション"
    this.that.$('.search_form_wrap').show()
  }

  returnFormName() {
    return this.formName
  }

  getInputData() {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store

    store.commit('setValue', ['com_option', 'input_com_option_val', $('input[name="com_option"]:checked').val(), 'com_option'])
  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    const sendVal = 
`
◎補償オプション
補償オプション：${store.state.formData.com_option.input_com_option_val}
`
    store.commit('setTalknoteMessage', ['com_option', sendVal])
  } 
}

export default {
  afrPostComOption
}