/**
  説明文コンポーネント
*/
<template>
  <p class="c-description">
    <slot></slot>
  </p>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({

  });
</script>

<style lang="scss" scoped>
.c-description {
  line-height: 1.6;
}
</style>
