/*
* ぽちボタンクリック後の処理
*/ 

class afrPostVideoProduction {
  constructor(that) {
    this.that = that
    this.formName = "動画制作"
    this.that.$('.search_form_wrap').show()
  }

  returnFormName() {
    return this.formName
  }

  getInputData() {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store

    store.commit('setValue', ['video_production', 'input_video_production_val', $('input[name="video_production"]:checked').val(), 'video_production'])
  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    const sendVal = 
`
◎動画制作
プラン：${store.state.formData.video_production.input_video_production_val}
`
    store.commit('setTalknoteMessage', ['video_production', sendVal])
  } 
}

export default {
  afrPostVideoProduction
}