/**
  申し込み用フォーム[助成金Lab]コンポーネント
*/
<template>
  <div id="formSubsidy" class="form_group">
    <div class="rap1_boder" id="">
      <Section><h1 class="wrap_title pd_7">助成金Lab</h1></Section>
      <Column class="pd3_parts form_parts">
        <label>対象助成金</label>
      </Column>
      <Column>
        <label>未回収必須書類</label>
        <label class="c-choice documents"><input type="checkbox" name="require_documents" value="就業規則"><span>就業規則</span></label>
        <label class="c-choice documents"><input type="checkbox"  name="require_documents" value="雇用契約書"><span>雇用契約書</span></label>
        <label class="c-choice documents"><input type="checkbox"  name="require_documents" value="出勤簿"><span>出勤簿</span></label>
        <label class="c-choice documents"><input type="checkbox"  name="require_documents" value="従業員名簿"><span>従業員名簿</span></label>
        <label class="c-choice documents"><input type="checkbox"  name="require_documents" value="給料明細"><span>給料明細</span></label>
      </Column>
      <Column class="pd3_parts form_parts">
        <label>直近の採用状況</label>
        <textarea class="form_txt" id="recruitment_status"></textarea>
      </Column>
      <Column class="pd3_parts form_parts">
        <label>申請時の懸念点</label>
        <textarea class="form_txt" id="concern"></textarea>
      </Column>
    </div>
  </div>
</template>


<script>
import { defineComponent } from 'vue'

// コンポーネントを読み込む
import Variables from '../../../molecules/variables.vue';
import Choices from '../../../molecules/choices.vue';
import Box from '../../../molecules/box.vue';
import Section from '../../../molecules/section.vue';
import Column from '../../../molecules/column.vue';
import Group from '../../../molecules/group.vue';
import Flex from '../../../molecules/flex.vue';
import Block from '../../../molecules/block.vue';

import Description from '../../../atoms/description.vue';
import Label from '../../../atoms/label.vue';
import Input from '../../../atoms/input.vue';
import Select from '../../../atoms/select.vue';
import Textarea from '../../../atoms/textarea.vue';
import Button from '../../../atoms/button.vue';
import IconAdd from '../../../atoms/icon-add.vue';
import IconRemove from '../../../atoms/icon-remove.vue';
import formDataStore from "@/assets/js/formDataStore"

export default defineComponent({
  name: 'formApplicationSubsidy',

  components: {
    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,
  },

  methods: {
  },

  mounted() {

    //ここの配列を後でkintoneから取得してきたデータに置き換える
    formDataStore.state.KintoneOptionsNamesData.option_names = [
      "1.65歳超雇用推進助成金",
      "2.両立支援等助成金（出生時両立支援コース）",
      "3.両立支援等助成金（育児休業等支援コース）",
      "4.人材開発支援助成金",
      "5.キャリアアップ助成金（賞与・退職金）",
      "6.キャリアアップ助成金（正社員化）",
      "7.キャリアアップ助成金（賃金規定等共通化）",
      "8.キャリアアップ助成金（短時間労働者労働時間延長）"
    ]

    const subsidyOptions = formDataStore.state.KintoneOptionsNamesData.option_names
    const columnElement = document.querySelector('#formSubsidy .form_parts')

    subsidyOptions.forEach(choice => {
      const checkboxLabel = document.createElement('label')
      checkboxLabel.className = 'c-choice subjects'

      const checkboxInput = document.createElement('input')
      checkboxInput.type = 'checkbox'
      checkboxInput.className = 'required'
      checkboxInput.name = 'subsidy'
      checkboxInput.value = choice
      const dotPattern = /[．，]/g
      checkboxInput.id = choice.replace(dotPattern, '.').split('.')[0]
      checkboxInput.dataset.id = checkboxInput.id

      const checkboxSpan = document.createElement('span')
      checkboxSpan.textContent = choice

      checkboxLabel.appendChild(checkboxInput)
      checkboxLabel.appendChild(checkboxSpan)

      columnElement.appendChild(checkboxLabel)
    })
  },

  setup(_, context) {
    return {}
  }

})
</script>
