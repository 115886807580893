/**
  セレクトボックスコンポーネント
*/
<template>
  <div class="c-select">
    <select v-bind:class="selectClass" v-bind:id="selectId">
      <option v-for="(values, key) in objects" :value="values.value" :data-id="values.id" :disabled="values.disabled" :selected="values.selected">{{values.text}}</option>
    </select>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      selectId: String,
      selectClass: String,
      objects: Object
    }
  });
</script>

<style lang="scss">
.c-select {
  width: 100%;
  position: relative;

  &::after {
    content: "";
    $base64: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="10" height="10"><path fill="#{$color-primary}" d="M201.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 338.7 54.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>';
    background-image: url(str-replace("#", "%23", $base64));
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: calc(50% - 5px);
    right: 10px;
    pointer-events: none;
  }

  select {
    width: 100%;
    line-height: 1;
    padding: 8px 26px 8px 10px;
    border-radius: $radius-small;
    border: 1px solid $color-gray-2;
    min-height: 39px;
  }
}
</style>
