/**
  ボタンコンポーネント
*/
<template>
  <a class="c-hint">
    <slot></slot>
  </a>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({

  });
</script>

<style lang="scss">
.c-hint {
  color: $color-blue-3;
  text-decoration: underline;
  margin-left: 20px;
}
</style>
