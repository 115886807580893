/**
  申し込み用フォーム[補償オプション]コンポーネント
*/
<template>
<div id="formComOption" class="form_group">
  <div class="rap1_boder" id="">
    <Section><h1 class="wrap_title pd_7">補償オプション</h1></Section>
    <Column class="pd3_parts width15">
      <ul class="c-choices">
        <li class="c-choices__item">
          <label class="c-choice">
            <input type="radio" class="required" name="com_option" value="無し">
            <span class="">無し</span>
          </label>
        </li>
        <li class="c-choices__item">
          <label class="c-choice">
            <input type="radio" class="required" name="com_option" value="クラスA">
            <span class="">クラスA</span>
          </label>
        </li>
        <li class="c-choices__item">
          <label class="c-choice">
            <input type="radio" class="required" name="com_option" value="クラスB">
            <span class="">クラスB</span>
          </label>
        </li>
      </ul>
    </Column>
  </div>
</div>
</template>


<script>
import { defineComponent } from 'vue'

// コンポーネントを読み込む
import Variables from '../../../molecules/variables.vue';
import Choices from '../../../molecules/choices.vue';
import Box from '../../../molecules/box.vue';
import Section from '../../../molecules/section.vue';
import Column from '../../../molecules/column.vue';
import Group from '../../../molecules/group.vue';
import Flex from '../../../molecules/flex.vue';
import Block from '../../../molecules/block.vue';

import Description from '../../../atoms/description.vue';
import Label from '../../../atoms/label.vue';
import Input from '../../../atoms/input.vue';
import Select from '../../../atoms/select.vue';
import Textarea from '../../../atoms/textarea.vue';
import Button from '../../../atoms/button.vue';
import IconAdd from '../../../atoms/icon-add.vue';
import IconRemove from '../../../atoms/icon-remove.vue';

export default defineComponent({
  name: 'formApplicationComOption',

  components: {
    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,
  },

  methods: {
  },

  mounted() {
  },

  setup(_, context) {
    return {}
  }

})
</script>
