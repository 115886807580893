/*
* ぽちボタンクリック後の処理
*/ 

class afrPostSEO {
  constructor(that) {
    this.that = that
    this.formName = "SEO"
    this.that.$('.search_form_wrap').show()
  }

  returnFormName() {
    return this.formName
  }

  getInputData() {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func

    let $seoWords = $('.seoWords')
    this.input_seo_words = ''
    this.seo_words_ary = []

    for(let i = 0; i < $seoWords.length; i++ ) { 
      if($seoWords[i].value !== "") {
        this.seo_words_ary.push($seoWords[i].value)
        this.input_seo_words += '\n' + '（' + (i+1) + '）' + $seoWords[i].value
      }
    }
    
    // 値取得：SEO
    store.commit('setValue', ['seos', 'words', func.textEscape(this.seo_words_ary), 'words'])
    store.commit('setValue', ['seos', 'type_num', '', 'type_num'])
    store.commit('setValue', ['seos', 'url', func.textEscape($('#seoURL').val()), 'url'])
    store.commit('setValue', ['seos', 'word_num', func.textEscape($('#seoWordNum').val()), 'word_num'])
    store.commit('setValue', ['seos', 'input_seo_train', $('input[name="seo_train"]:checked').val(), 'input_seo_train'])
   
  }

  setVal (balanceInfoMsg) {
    const store = this.that.store
    
    const sendVal = 
`
◎SEO
ワード数：${store.state.formData.seos.word_num}
ワード：${this.input_seo_words}
SEO対象URL：${store.state.formData.seos.url}
対策期間：${store.state.formData.seos.input_seo_train}
`
    store.commit('setTalknoteMessage', ['seos', sendVal])
  }
}

export default {
  afrPostSEO 
}