/*
* application.jsの共通
*/ 


class commonFuncs {
  constructor(that) {
    this.that = that
    this.formName = ""
    that.formClearFlag = 0
    this.actionInit(that)
  }

  returnFormName() {
    return this.formName
  }

  getInputData() {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const userID = store.state.userData.userID
    const subjectID = $('#subjectID').val()
    const func = this.that.func;

    const postID = func.getPostID(subjectID, userID, this.that.slackChannel.id)
    const useCreditYesNo = $('input[name="use_credit_yesNo"]:checked').val() ? $('input[name="use_credit_yesNo"]:checked').val() : ""

    const talknoteType = func.getTalknoteType(this.that.groupTitle)
    
    store.commit('setValue', ['common', 'group_type', talknoteType, 'group_type'])
    store.commit('setValue', ['common', 'postID', postID, 'post_id'])

    // 値取得：案件情報
    store.commit('setValue', ['common', 'inputsubjectID', subjectID, 'subject_id'])
    store.commit('setValue', ['common', 'inputComName', func.textEscape($('#comName').val()), 'com_name'])
    store.commit('setValue', ['common', 'inputApName', func.textEscape($('#apName').val()), ''])
    
    // 値取得：入金情報
    store.commit('setValue', ['common', 'inputSalesPrice', $('#salesPrice').val() + '円', 'sales_price'])
    store.commit('setValue', ['common', 'inputPaymentStatus', $('#paymentStatus').val(), 'payment_status'])
    store.commit('setValue', ['common', 'inputPaid', $('#paid').val() + '円', 'paid'])
    store.commit('setValue', ['common', 'inputPaymentDetail', func.textEscape($('#paymentDetail').val()), 'payment_detail'])
    
    const inputPaymentMethod = $('#paymentMethod').val() === null ? '' : $('#paymentMethod').val()
    store.commit('setValue', ['common', 'inputPaymentMethod', inputPaymentMethod, 'payment_method'])
    store.commit('setValue', ['common', 'inputBalance', $('#balance').val(), ''])
    store.commit('setValue', ['common', 'inputBalanceDeadlineReason', func.textEscape($('#balanceDeadlineReason').val()), 'balance_deadline_reason'])
    store.commit('setValue', ['common', 'inputUseCreditYesNo', useCreditYesNo, 'use_credit_yesNo'])
    //store.commit('setValue', ['common', 'totalInfo_balance', 0, 'total_balance'])

    // 値取得：必要書類
    store.commit('setValue', ['common', 'inputDoc', $('[name="necessaryDoc"]:checked').val(), 'doc'])

    const inputAgreementDate = moment($('#agreementDate').val().split('/').join('-')).format('YYYY/MM/DD（ddd）')
    store.commit('setValue', ['common', 'inputAgreementDate', inputAgreementDate, 'Agreement_date'])

    const inputSendDate = $('#unnecessary_sendDate').is(':checked') ? '不要' : moment($('#sendDate').val().split('/').join('-')).format('YYYY/MM/DD（ddd）')
    store.commit('setValue', ['common', 'inputSendDate', inputSendDate, 'send_date'])

    const inputCallDate = $('#unnecessary_callDate').is(':checked') ? '不要' : moment($('#callDate').val().split('/').join('-')).format('YYYY/MM/DD（ddd）')
    store.commit('setValue', ['common', 'inputCallDate', inputCallDate, 'call_date'])

    store.commit('setValue', ['common', 'inputCallDateSppl', $('#callDateSppl').val(), ''])
    store.commit('setValue', ['common', 'inputReceipt', $('[name="receipt_yesNo"]:checked').val(), 'receipt'])
    store.commit('setValue', ['common', 'inputOtherShared', $('#otherShared').val(), ''])

    //DB用に取得
    store.commit('setDataIDs', ['subject_id', subjectID])
    store.commit('setDataIDs', ['user_sg_id', store.state.userData.id])
    store.commit('setDataIDs', ['sold_price', $('#salesPrice').val().replace(/円|,/g, '')])

    let hooks_ids = []
    let suggestions_ids = []
    $('.select_checkbox:checked').each(function(i, ele) {
      //const checked_id = $(ele).val()
      const elm = $(ele)
      if(elm.data('suggestion-id')) suggestions_ids.push(elm.data('suggestion-id'))
      if(elm.data('hook-id')) hooks_ids.push(elm.data('hook-id'))
      /*
      switch (checked_id) {

        case 'itzai': //イツザイ
          hooks_ids.push(3)
          suggestions_ids.push(3)
          break;

        case 'itzaiLite': //イツザイ
          hooks_ids.push(3)
          suggestions_ids.push(6)
          break;
        
        case 'ec': //ec
          suggestions_ids.push(2)
          break;

        case 'wel': //wel
          hooks_ids.push(2)
          break;

        case 'relo': //リロ
          hooks_ids.push(1)
          break;

        case 'seo': //seo
          suggestions_ids.push(6)
          break;

        case 'meo': //meo
          suggestions_ids.push(7)
          break;

        case 'cms': //cms
          suggestions_ids.push(1)
          break;

        case 'subsidy': //subsidy
          suggestions_ids.push(5) //番号変える場合はcommon.class.jsのリクエストボディ条件のsuggestions_id[i] == 5　の番号も変えること
          break;
          
        case 'com_option': //com_option
          suggestions_ids.push(99)
          break;
        
        case 'video_production': //video_production
          suggestions_ids.push(99)
          break;

      }*/
    })
    store.commit('setDataIDs', ['hooks_id', hooks_ids])
    store.commit('setDataIDs', ['suggestions_id', suggestions_ids])

  }

  setVal(balanceInfoMsg) {
    const store = this.that.store
    const Extension_version = store.state.version

    const sendVal = 
`◎案件情報[${Extension_version}]
案件ID：${store.state.formData.common.inputsubjectID}
案件名： ${store.state.formData.common.inputComName}
アポ担当：${store.state.formData.common.inputApName}

◎入金内容
受注内容：${store.state.formData.common.formTypeName}
受注金額：${store.state.formData.common.inputSalesPrice}
決済進捗：${store.state.formData.common.inputPaymentStatus}
決済済み金額：${store.state.formData.common.inputPaid}
${balanceInfoMsg}
残額期日理由：${store.state.formData.common.inputBalanceDeadlineReason}
ビジクレ使用可否：${store.state.formData.common.inputUseCreditYesNo}
補足：${store.state.formData.common.inputPaymentDetail}

◎引き継ぎ情報
契約日：${store.state.formData.common.inputAgreementDate}
必要書類：${store.state.formData.common.inputDoc}
送付希望日：${store.state.formData.common.inputSendDate}
お礼電話日時：${store.state.formData.common.inputCallDate} ${store.state.formData.common.inputCallDateSppl}
領収証：${store.state.formData.common.inputReceipt}
` 

    store.commit('setTalknoteMessage', ['common', sendVal])
  }

  clearAllOption() {
    const $ = this.that.$
    const func = this.that.func

    func.clearInput($('#form_ap').find('textarea, input[type="text"], input[type="tel"], input[type="url"], input[type="date"], input[type="Number"]'))
    func.clearSelect($('#form_ap').find('select'))
    func.checkOut($('#form_ap').find('input[type="radio"], input[type="checkbox"]'))
    //$('.formSharedComm, .formAddress, .formPayment_default, .formPayment_option, #opSaledPlan').css('display', 'none')

    $('#status_comment').text('案件IDを入力すると検索開始します').css('color', '#757575')
    $('#productsNum').val('3')
    $('#result_titles').empty()
    $('.cloneEle').remove()
    this.that.vueObj.hideSelectAllForm()

    // 必須項目初期化
    $('#paymentMethod, #balanceDeadline, #balanceDeadlineReason').removeClass('required')

    this.that.store.commit('delAllValue')
    console.log('data clear')

  }

  // Talknote用のデータ構築
  getDataToTalknote() {
    let msgAry = []
    let sorter = ['common', 'itzai', 'itzaiLite', 'ec', 'wel', 'relo', 'seos', 'meos', 'cms', 'cmsLodging', 'subsidy', 'com_option', 'video_production', 'option', 'contactInfo']
    let remark_text = document.getElementById("remark").value

    for(let i in sorter) {
      let msg  = this.that.store.state.msg[sorter[i]]
      if(msg) msgAry.push(msg)
    }
    const msg = msgAry.join('') + `
備考：${remark_text}
-----------------------------
POST ID：${this.that.store.state.formData.common.postID}
こちらのIDは投稿画面からの投稿IDです。`

    return msg
  }

  // スプレッドシート用のデータ構築
  getDataToSheet() {
    let dataObj = {}

    for(let i in this.that.store.state.sheetData) {
      if(i != 'seos' && i != 'meos') { 
        for(let j in this.that.store.state.sheetData[i]) {         
          dataObj[j] = this.that.store.state.sheetData[i][j]
        }
      } else {
        dataObj[i] = this.that.store.state.sheetData[i]
      }
    }

    return dataObj
  }

  async sendFormData (msg, sheetdata) {

    const $ = this.that.$
    const vueObj = this.that.vueObj
    const store = this.that.store
    const func = this.that.func
    const getDataApiURL = this.that.getDataApiURL
    const userData = store.state.userData
    const tn_user_id = store.state.userData.userID

    // Loading表示
    vueObj.viewLoader('投稿中')
    
    sheetdata.msg = func.textEscape(msg)
    sheetdata.type = "ApplicationPostV2"
    sheetdata.user_id = tn_user_id
    sheetdata.group_id = this.that.slackChannel.id
    sheetdata.group_name = this.that.groupTitle
    sheetdata.group_title = this.that.groupTitle

    sheetdata.appDataObj = store.state.dataIDs

    ;(async function(_this) {

      //let url = func.searchPOSTURL(_this.that.groupURL, sheetdata.post_id)
      const requestFunc = new RequestBody.requestBody()
      const groupName = "applications"
      const demo = _this.that.vueObj.isProd ? "" : "Demo"
      const key = store.state.apis[`loginKey${demo}`]
      const spreadsheetApi = store.state.apis[`spreadsheet${demo}`]
      const DBInsertApi = store.state.apis[`dbInsert${demo}`]
      const NowTime = requestFunc.nowTime()
      const created_at = requestFunc.nowTime("created_at")

      let total_balance = ""
      try {
        total_balance = parseInt($('#salesPrice').val().replace(/,/g, "")) - parseInt($('#paid').val().replace(/,/g, ""))
        total_balance = total_balance.toLocaleString() + '円'
      } catch {
        total_balance = "0円"
      }

      let result_meo_words = ""
      let meos, seos, balances;

      if(sheetdata.meos == undefined) {
        meos = {url: '', address: '', word_num: '', words: []}
      } else {
        meos = sheetdata.meos
        result_meo_words = requestFunc.meo_words(meos.words)
      }
      
      if(sheetdata.seos == undefined) {
        seos = {type_num: '', url: '', word_num: '', words: []}
      } else {
        seos = sheetdata.seos
      }

      balances = sheetdata.balances
      let balance_deadline = ''

      if(balances[0].balanceDeadline !== undefined) {
        for(let i in balances) {
          balance_deadline += balances[i].balanceDeadline.split('（')[0] + ','
        }
        balance_deadline = balance_deadline.slice(0, -1)
      }

      if(sheetdata.contact_comName !== undefined) {
        sheetdata.contact_com_name = sheetdata.contact_comName
      }

      if(meos.words[0] !== undefined) {
        meos.address = meos.words[0].address
      }


      const storeData = store.state.formData
      const belongData = requestFunc.setBelongDate(store.state.userData)
      const storeID = store.state.dataIDs
      const inputComOption = storeData.com_option.input_com_option_val
      const inputVideoProduction = storeData.video_production.input_video_production_val
      const inputSubjects = storeData.subsidy.input_subjects_val
      const inputDocuments = storeData.subsidy.input_documents_val
      let subjectsArray = ""
      let documentsArray = ""

      const selectpaymentStatus = document.querySelector('#paymentStatus')
      const selectedStatusOption = selectpaymentStatus.options[selectpaymentStatus.selectedIndex]
      const paymentProgressId = selectedStatusOption.getAttribute('data-id')

      let soldPrice = sheetdata.sales_price.slice(0, -1)
      soldPrice = parseInt(soldPrice.replace(/,/g, ''))

      let paidAmount = sheetdata.paid.slice(0, -1)
      paidAmount = parseInt(paidAmount.replace(/,/g, ''))
      
      const paymentMethodsId = []
      const selectPaymentMethod = document.querySelectorAll('select.paymentMethod')
      selectPaymentMethod.forEach((select) => {
        const selectedOption = select.options[select.selectedIndex]
        const selectedIds = parseInt(selectedOption.getAttribute('data-id'))
        paymentMethodsId.push(selectedIds)
      })

      const balanceArray = []
      const balanceInputs = document.querySelectorAll('input[name="balance"]')
      balanceInputs.forEach((input) => {
        const balance = parseInt(input.value.replace(/,/g, ''))
        balanceArray.push(balance)
      })

      const industryDataID = storeData.itzai.inputIndustryDataId || ""
      const industryDataIDLite = storeData.itzaiLite.inputIndustryDataId || ""
      const industry = industryDataID && industryDataIDLite ? 2 : 1

      if (inputSubjects != undefined) {
        for (let i = 0; i < inputSubjects.length; i++) {
          subjectsArray += inputSubjects[i] + ","
        }
        subjectsArray = subjectsArray.slice(0, -1)
      }

      if (inputDocuments != undefined) {
        for (let i = 0; i < inputDocuments.length; i++) {
          documentsArray += inputDocuments[i] + ","
        }
        documentsArray = documentsArray.slice(0, -1)
      }

      const checkboxes = document.querySelectorAll('.c-choice.subjects')
      const selectedSubsidyIDs = []

      for (let i = 0; i < checkboxes.length; i++) {
        const checkbox = checkboxes[i]
        const input = checkbox.querySelector('input')

        if (input.checked) {
          const id = input.id
          selectedSubsidyIDs.push(id)
        }
      }

      let meos_word_num = Number(meos.word_num)
      let seos_word_num = Number(seos.word_num)

      const checkedItzai = document.getElementById("formItzaiSelect").checked
      const checkedItzaiLite = document.getElementById("formItzaiLiteSelect").checked
      let contact_com_name, contact_name, contact_mail, contact_tel_num, contact_ceo_name, industry_data_id

      contact_com_name = sheetdata.contact_com_name
      contact_name = sheetdata.contact_name
      contact_mail = sheetdata.contact_mail
      contact_tel_num = sheetdata.contact_tel_num
      contact_ceo_name = sheetdata.contact_ceo_name
      industry_data_id = ""

      if (checkedItzaiLite) {
        industry_data_id = Number(sheetdata.industry_data_id_lite)
      }

      if (checkedItzai) {
        industry_data_id = Number(sheetdata.industry_data_id)
      }

      if (userData.BN !== "" && userData.BN !== null && userData.BN !== "NULL" && userData.BN !== "None") {
        var fullname = userData.BN + "（" + userData.firstName + "） " + userData.lastName
      } else {
        var fullname = userData.firstName + " " + userData.lastName
      }

      // slack 投稿者にメンション
      const slackMention = `<@${vueObj.slackChannel.userID}>\n`
      const data = {
        "channel": vueObj.slackChannel.id, 
        "username": fullname,
        "text":  slackMention + msg, 
        "token": vueObj.slackChannel.token,
        "icon_url": vueObj.slackChannel.iconURL
      }

      // slack投稿
      const response = await fetch(vueObj.slackChannel.postURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `token=${encodeURIComponent(data.token)}&channel=${encodeURIComponent(data.channel)}&username=${encodeURIComponent(data.username)}&text=${encodeURIComponent(data.text)}&icon_url=${data.icon_url}`
      })
      .then(function (response) {
        return response.json()
      }).then(function (response) {

        if(response.message == "not current version") {
          alert(`tnPostのバージョンが違うようです。\n更新します。`);
          location.reload()
          postResult = false
          return false
        } else {
          
          console.log('slack投稿完了', response)

          return response
        }

      })
      .catch((e) => {
        console.error(e.stack, e.message)
      })
      
      const messageURL = `${_this.that.groupURL}/p${response.ts.split('.').join('')}`
      

      // 転送チャンネルに投稿
      if(storeData.common.formTypeName.indexOf('イツザイ') > -1) {
         const postParam = {
          slackChannel: vueObj.slackChannel,
          messageURL: messageURL,
          postUser: fullname,
          mention: slackMention,
          text: msg,
          channelID: vueObj.slackChannel.forwardID01
        }
        new ForwardPost(postParam).post()
      }

      if(storeData.common.formTypeName.indexOf('EC') > -1) {
        const postParam = {
          slackChannel: vueObj.slackChannel,
          messageURL: messageURL,
          postUser: fullname,
          mention: slackMention,
          text: msg,
          channelID: vueObj.slackChannel.forwardID02
        }
        new ForwardPost(postParam).post()
      }

      let contactProgress = ""
      if (storeData.contactInfo && storeData.contactInfo.inputContactProgressYesNo) {
        contactProgress = storeData.contactInfo.inputContactProgressYesNo
      }

      let insertDataRecordCnt = [{
                                "投稿日時":{"id": "", "val": NowTime},
                                "全文":{"id": "", "val": func.textEscape(msg)},
                                "投稿URL":{"id": "", "val": messageURL},
                                "ユーザーID":{"id": "", "val": tn_user_id},
                                "投稿者名":{"id": "", "val": fullname},
                                "所属エリア":{"id": "", "val": belongData.userPrefecture},
                                "所属（課/部）":{"id": "", "val": belongData.userSection},
                                "所属（組）":{"id": "", "val": belongData.FS_UserTeam},
                                "案件ID":{"id": "", "val": sheetdata.subject_id},
                                "投稿ID":{"id": "", "val": sheetdata.post_id},
                                "案件名":{"id": "", "val": sheetdata.com_name},
                                "受注内容":{"id": "", "val": sheetdata.saled_plan ? sheetdata.saled_plan : ""},
                                "受注金額":{"id": "", "val": sheetdata.sales_price},
                                "決済進捗":{"id": "", "val": sheetdata.payment_status},
                                "決済済み金額":{"id": "", "val": sheetdata.paid},
                                "残額":{"id": "", "val": total_balance},
                                "残高期日":{"id": "", "val": balance_deadline ? balance_deadline : ""},
                                "支払い方法":{"id": "", "val": sheetdata.balances[0].paymentMethod ? sheetdata.balances[0].paymentMethod : ""},
                                "残高期日理由":{"id": "", "val": sheetdata.balance_deadline_reason ? sheetdata.balance_deadline_reason : ""},
                                "補足":{"id": "", "val": sheetdata.payment_detail ? sheetdata.payment_detail : ""},
                                "契約日":{"id": "", "val": sheetdata.Agreement_date},
                                "必要書類":{"id": "", "val": sheetdata.doc},
                                "送付希望日付":{"id": "", "val": sheetdata.send_date},
                                "お礼電話日時":{"id": "", "val": sheetdata.call_date},
                                "領収証":{"id": "", "val": sheetdata.receipt},
                                "連絡先情報 - 社名":{"id": "", "val": contact_com_name ? contact_com_name : ""},
                                "連絡先情報 - 担当者名":{"id": "", "val": contact_name ? contact_name : ""},
                                "連絡先情報 - メールアドレス":{"id": "", "val": contact_mail ? contact_mail : ""},
                                "連絡先情報 - 電話番号":{"id": "", "val": contact_tel_num ? contact_tel_num : ""},
                                "特典":{"id": "", "val": ""},
                                "SEO - 型番":{"id": "", "val": seos.type_num ? seos.type_num : ""},
                                "SEO - 対象URL":{"id": "", "val": seos.url ? seos.url : ""},
                                "SEO - ワード数":{"id": "", "val": seos_word_num ? seos.word_num : ""},
                                "SEO - ワード":{"id": "", "val": seos.words.join() ? seos.words.join() : ""},
                                "MEO - 店舗URL":{"id": "", "val": meos.url ? meos.url : ""},
                                "MEO - 店舗住所":{"id": "", "val": meos.address ? meos.address : ""},
                                "MEO - ワード数":{"id": "", "val": meos_word_num ? meos_word_num : ""},
                                "MEO - ワード&範囲":{"id": "", "val": result_meo_words ? result_meo_words : ""},
                                "カテゴリ":{"id": "", "val": sheetdata.cate ? sheetdata.cate : ""},
                                "提案内容":{"id": "", "val": storeData.common.formTypeName ? storeData.common.formTypeName : ""},
                                "転送":{"id": "", "val": ""},
                                "WEL-特典":{"id": "", "val": storeData.wel.input_wel_benefits ? storeData.wel.input_wel_benefits : ""},
                                "WEL-カテゴリ":{"id": "", "val": storeData.wel.input_wel_cate ? storeData.wel.input_wel_cate : ""},
                                "リロ-特典":{"id": "", "val": storeData.relo.input_relo_benefits ? storeData.relo.input_relo_benefits : ""},
                                "リロ-カテゴリ":{"id": "", "val": storeData.relo.input_relo_cate ? storeData.relo.input_relo_cate : ""},
                                "連絡先情報 - 代表者名":{"id": "", "val": contact_ceo_name ? contact_ceo_name : ""},
                                "業界ID":{"id": "", "val": industry_data_id ? industry_data_id : ""},
                                "対象助成金":{"id": "", "val": subjectsArray ? subjectsArray : ""},
                                "未回収必要書類":{"id": "", "val": documentsArray ? documentsArray : ""},
                                "補償オプション":{"id": "", "val": inputComOption ? inputComOption : ""},
                                "動画制作":{"id": "", "val": inputVideoProduction ? inputVideoProduction : ""},
                                "所属（組）IS":{"id": "", "val": belongData.IS_UserTeam},
                                "進行可否":{"id": "", "val": contactProgress},
                                "ビジクレ使用可否":{"id": "", "val": storeData.common.inputUseCreditYesNo},
                                }]

      let insertSheetData = requestFunc.createBody(groupName, _this.that.vueObj.isProd, insertDataRecordCnt)

      const agreement_date = sheetdata.Agreement_date.split("（")[0]

      let dbData_applications = []
      for (let i = 0; i < industry ; i++) {
        dbData_applications[i] = { 
          "table_name": "applications",
          "form": {
            "non_sub_query": {
              "post_id": sheetdata.post_id,
              "sold_price": soldPrice,
              "created_at": created_at,
              "paid_amount": paidAmount,
              "users_id" : store.state.userData.id,
              "subjects_id": sheetdata.subject_id,
              "agreement_date": agreement_date,
              "payment_progress_id": paymentProgressId
            },
            "sub_query": {
              "users": true,
            }
          },
          "authC": {
            "password": "W!z|a~.x.T~Tzca8CBCm",
            "mail": "system@sungrove.co.jp"
          },
          "support_data": {
            "users_id" : store.state.userData.id,
            "is_prod": _this.that.vueObj.isProd
          }
        }

        if (industry == 1 && (industryDataID || industryDataIDLite)) {
          dbData_applications[i].form.non_sub_query.categories_id = industryDataID || industryDataIDLite

        } else if (industry == 2) {

          if (i == 0) {
            dbData_applications[i].form.non_sub_query.categories_id = industryDataID
          } else if (i == 1) {
            dbData_applications[i].form.non_sub_query.categories_id = industryDataIDLite
          }

        }

      }

      let dbData_application_suggestions = []
      let count = 0
      for (let i = 0; i < storeID.suggestions_id.length ; i++) {

        let create_body = { 
          "table_name": "application_suggestions",
          "form": {
            "non_sub_query": {
              "suggestions_id": storeID.suggestions_id[i],
              "created_at": created_at,
            },
            "sub_query": {
              "users": false,
              "applications_id": "SELECT id FROM applications WHERE created_at = '" + created_at + "' AND subjects_id = '" + sheetdata.subject_id + "' LIMIT 1"
            }
          },
          "support_data": {
            "users_id" : store.state.userData.id,
            "is_prod": _this.that.vueObj.isProd
          }
        }

        if (storeID.suggestions_id[i] == 5) {

          while (count < selectedSubsidyIDs.length) {
            let new_create_body = JSON.parse(JSON.stringify(create_body))
            new_create_body.form.sub_query.subsidys_id = 'SELECT id FROM subsidys WHERE id = "' + selectedSubsidyIDs[count] + '"'
            dbData_application_suggestions[i+count] = new_create_body
            count += 1
          }
          count -= 1

        } else {
          dbData_application_suggestions[i+count] = create_body
        }

      }

      let dbData_application_hooks = []
      for (let i = 0; i < storeID.hooks_id.length ; i++) {
      
        dbData_application_hooks[i] = { 
          "table_name": "application_hooks",
          "form": {
            "non_sub_query": {
              "hooks_id": storeID.hooks_id[i],
              "created_at": created_at
            },
            "sub_query": {
              "users": false,
              "applications_id": "SELECT id FROM applications WHERE created_at = '" + created_at + "' AND subjects_id = '" + sheetdata.subject_id + "' LIMIT 1"
            }
          },
          "support_data": {
            "users_id" : store.state.userData.id,
            "is_prod": _this.that.vueObj.isProd
          }
        }
      }

      let payment_methods = []
      for (let i = 0; i < balanceArray.length ; i++) {

        payment_methods[i] = {
          "table_name": "balance_payment_methods",
          "form": {
            "non_sub_query": {
              "created_at": created_at,
              "balance": balanceArray[i]
            },
            "sub_query": {
              "users": false,
              "applications_id": "SELECT id FROM applications WHERE created_at = '" + created_at + "' AND subjects_id = '" + sheetdata.subject_id + "' LIMIT 1"
            }
          },
          "support_data": {
            "users_id" : store.state.userData.id,
            "is_prod": _this.that.vueObj.isProd
          }
        }

        if (!isNaN(paymentMethodsId[i])) {
          payment_methods[i].form.non_sub_query.payment_methods_id = paymentMethodsId[i]
        }
      }

      let teams_ids = ""
      const teamID = store.state.userData.teams_id
      if (teamID && teamID != "NULL" && teamID != "None") {
        teams_ids = teamID.split(",")
      } else {
        teams_ids = [""]
      }

      let dbData_applications_group_teams = []
      for (let i = 0; i < teams_ids.length ; i++) {
        dbData_applications_group_teams[i] = {
          "table_name": "applications_group_teams",
          "form": {
            "non_sub_query": {
              "post_id": sheetdata.post_id,
              "created_at": created_at
            },
            "sub_query": {
              "users": false,
            }
          },
          "support_data": {
            "users_id": store.state.userData.id,
            "is_prod": _this.that.vueObj.isProd
          }
        }
      }

      let insertDbData = []

      for (let i = 0; i < dbData_applications.length ; i++) {
        insertDbData.push(dbData_applications[i])
      }

      if (dbData_application_suggestions[0] !== '') {
        for (let i = 0; i < dbData_application_suggestions.length ; i++) {
          insertDbData.push(dbData_application_suggestions[i])
        }
      }

      if (dbData_application_hooks[0] !== '') {
        for (let i = 0; i < dbData_application_hooks.length ; i++) {
          insertDbData.push(dbData_application_hooks[i])
        }
      }

      if (payment_methods[0] !== '') {
        for (let i = 0; i < payment_methods.length ; i++) {
          insertDbData.push(payment_methods[i])
        }
      }

      for (let i = 0; i < teams_ids.length ; i++) {
        if (teamID && teamID != "NULL" && teamID != "None") {
          dbData_applications_group_teams[i].form.non_sub_query.teams_id = parseInt(teams_ids[i])
        }
        insertDbData.push(dbData_applications_group_teams[i])
      }

      $('#btn').addClass('disabled').removeClass('pulse')

      sheetdata.post_url = messageURL
      
      console.log(insertSheetData, insertDbData)

      //スプレッドシート登録へ送信
      const spreadRequest = requestFunc.apiRequestExecution(spreadsheetApi, insertSheetData, key)
      const DBRequest = requestFunc.apiRequestExecution(DBInsertApi, insertDbData, key)
      Promise.all([spreadRequest, DBRequest])
      .then(function (response) {
        console.log(response)
        // Loading非表示
        vueObj.viewLoader()

        // 完了画面表示
        const params = {
          groupTitle: _this.that.groupTitle,
          groupURL: _this.that.groupURL,
          searchURL: sheetdata.post_url,
          calURL: '',
          message: '',
          completeTitle: '投稿完了'
        }
        vueObj.viewComplete(params)
      })
      .catch((e) => {
        // Loading非表示
        vueObj.viewLoader()
        alert(e.stack, e.message)
        console.error(e.stack, e.message)
      })
    
      /*vueObj.reset()
      
      for(let i in _this.values) {
        _this.values[i] = ""
      }*/

    })(this);

  } 

  //商材フォーム(.select_checkbox)が一つも表示されていない時は1を戻し、少なくとも一つは表示されている時は0を戻す
  check_form_select() {
    const $ = this.that.$
    let result = 1
    $(".select_checkbox").each(function(i, elem){
      if ($(elem).prop("checked") == true) {
        result *= 0
      } else {
        result *= 1
      }
    })
    
    return result
  }

  actionInit(that) {
    const $ = that.$
    const moment = that.moment
    const func = that.func
    let store = that.store
    let appClassObj = that.appClassObj
    const $form_ap = $("#form_ap")
    const officeData = new OfficeDatas()
 
    $form_ap.off("click.form_add").on("click.form_add", ".form_add", function(e) {

      let targetParent = $(this).parent().parent().parent()
      const paymentStatus = $('#paymentStatus').val()
      
      const clone = $(this).parent().parent().clone(true)
      
      clone.addClass('cloneEle').insertAfter($(this).parent().parent()).find('.addDelText').val('')
      $('.cloneEle').find('.required').removeClass('required').css({'background-color': '#ffffff'})

      if(clone.data('id')) clone.removeAttr('data-id')

      if( targetParent.children().length >= 1 && paymentStatus == '未決済' ) { //決裁進捗：未決済 × 残額フィールド(残額・残額期日・支払い方法)数が1以上
        $('.balanceInfo_balance').attr('readonly', false)
      }
  
      targetParent.children().eq(0).find($('.addDelText')).addClass('required')// 一番上のdivの子要素の一番最初がrequiredを追加 
    })

    // カレンダー
    $('#sendDate, #callDate, #balanceDeadline').off('click.cal').on('click.cal', function() {
      let _this = $(this)
      let date = _this.val()
      let current = date && date.match(/[0-9]{4}\/[0-9]{2}\/[0-9]{2}/)? date: moment().toDate()

      /*_this.datepicker("dialog", current, function(date) {
        _this.val(date)
        _this.parent().parent().find('input[type="checkbox"]').attr('checked', false)
      }, {
        dateFormat : 'yy/mm/dd',
        monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
      })*/
    })

    $form_ap.off("click.unnecessary").on("click.unnecessary", '.unnecessary', function() { 
      const $this = $(this)
      const id = $(this).attr('id').split('_')[1]

      if ($this.is(':checked')) { //「不要」をチェックした場合：その下にある日付の必須項目を外す
        $('#' + id).removeClass('required').val('').removeAttr('style')
      } else {
        $('#' + id).addClass('required')
      }
    })

    $form_ap.off("click.agreeToday").on("click.agreeToday", '.agreeToday', function() { 
      const $this = $(this)
      const id = $(this).attr('id').split('_')[1]

      if ($this.is(':checked')) { //「契約日当日」をチェックした場合：その下にある日付の必須項目を外し、当日のデータを入れる
        let format = 'YYYY-MM-DD'
        let today = moment().format(format)
        $('#' + id).removeClass('required').val(today).removeAttr('style')
      } else {
        $('#' + id).addClass('required')
      }
    })

    $form_ap.off("change.dateUnnecessary").on("change.dateUnnecessary", '.dateUnnecessary', function() { //日付を入力した場合：「不要」チェック外す
      const thisId = $(this).attr('id')

      if( $('#unnecessary_' + thisId).is(':checked') ) $('#unnecessary_' + thisId).prop('checked', false)
    })


    $form_ap.off("click.form_del").on("click.form_del", ".form_del", function() {
      let target = $(this).parent().parent()
      let targetParent = target.parent()
      let paymentStatus = $('#paymentStatus').val()

      if (target.parent().children().length > 1) {
        target.remove()
        targetParent.children().eq(0).find($('[name="balance"], [name="balanceDeadline"], [name="paymentMethod"]')).addClass('required')

        // 削除したあとが項目が一つの場合
        if(targetParent.children().length == 1 && paymentStatus !== '決済完了' && targetParent.children().hasClass('addDelPayment')) { //残額フィールド(残額・残額期日・支払い方法)が一つの場合：残額 = 受注金額

          $('#balance').val($('#salesPrice').val())
          targetParent.find('#balance, #balanceDeadline, #paymentMethod').addClass('required')

        } else if (targetParent.children().length == 1) {

          targetParent.find('.seoWord, .meoWord, .meoWordArea').addClass('required')
          
        } 

        if( targetParent.children().length == 1 && paymentStatus == '未決済' ) {
          $('.balanceInfo_balance').attr('readonly', true)
        }
      }

    })

    // 案件ID - 複数候補
    $form_ap.off('click.ListEvents').on('click.ListEvents', '[name="ListEvents"]', function() {
      const dataAbout = $(this).data('about')
      $('#comName').val(dataAbout.company_name)
      $('#apName').val(dataAbout.ap_name != "undefined" ? dataAbout.ap_name : "");
    })

    // [案件IDクリア]
    $('#subjectID').off('input.clear').on('input.clear', function() {
      const val = $(this).val()
      const bfrId = $(this).attr('data-id')
      const bfrFromType = $('#formList').val()
      const regSubject = /[A-Z]*[0-9]*_[0-9]{13}/g

      if(val == "" || (regSubject.test(val) && bfrId !== "" )) { //案件IDが再度入力された場合
        appClassObj.common.clearAllOption()
        $(this).val(val)
        $('#formList').val(bfrFromType)
        if(bfrFromType) $('.search_form_wrap').show()
      }
    })

    // 入金情報 - 決済進捗
    $form_ap.off('change.paymentStatus').on('change.paymentStatus', '#paymentStatus', function() {
      const paymentStatus = $('#paymentStatus option:selected').data('status')
      $('#salesPrice, #paid, #balance').attr('readonly', true).removeClass('samePrice')
      $('#salesPrice, #paid, #balance, .formPayment_option, #balanceDeadlineReason').val('')
      $('.formPayment_option').css('display', 'none')
      $('.paymentOp, .seoWord, .meoWord, .meoWordArea').removeClass('required')
      $('.addDelPayment.cloneEle').remove()

      switch(paymentStatus) {

        case 'done':
          $('#salesPrice').attr('readonly', false)
          $('#balanceDeadline').val('')
          $('#balance').val('0')
          $('#salesPrice, #paid').addClass('samePrice')
          $('.use_credit_display').hide()
          $('.use_credit_display input[type="radio"]').removeClass('required').prop('checked', false)
          $('.progress_display').show()
          $('.progress_display input[type="radio"]').addClass('required')
          break
          
        case 'doing':
          $('#salesPrice, #paid, #balance').attr('readonly', false)
          $('.formPayment_option').css('display', 'block')
          $('.formPayment_option.-flex').css('display', 'flex')
          $('.paymentOp').addClass('required')
          $('.use_credit_display').show()
          $('.use_credit_display input[type="radio"]').addClass('required')
          $('.progress_display').show()
          $('.progress_display input[type="radio"]').addClass('required')
          break

        case 'notYet':
          $('#salesPrice').attr('readonly', false)
          $('#paid').val('0')
          $('#salesPrice, #balance').addClass('samePrice')
          $('.formPayment_option').css('display', 'block')
          $('.formPayment_option.-flex').css('display', 'flex')
          $('.paymentOp').addClass('required')
          $('.use_credit_display').show()
          $('.use_credit_display input[type="radio"]').addClass('required')
          $('.progress_display').hide()
          $('.progress_display input[type="radio"]').removeClass('required').prop('checked', false)
          break
      }
    }).trigger('change.paymentStatus')

    // 入金情報 - 受注金額・決済済み金額・残額
    $form_ap.off('input.price').on('input.price', '#salesPrice, #paid, #balance', function() {
      func.updateTextView($(this))
      
      if($(this).hasClass('samePrice') && $('.balanceInfo').length == 1) { //残額フィールドが一つの時
        $('.samePrice').val($(this).val())
      }
      
      if($(this).hasClass('balanceInfo_balance') && $('.balanceInfo_balance').index(this) == 1 && $('#paymentStatus').val() == '未決済') { // 残額フィールドの二つ目の残額が入力された場合、一つの残額をクリアにする
        $('.balanceInfo_balance')[0].value = ''
      }

    })

    // 必須項目
    $form_ap.off('change.require input.require click.require mousemove.require').on('change.require input.require click.require mousemove.require', () => {
      let resultRequiredArray = func.checkRequired()
      let required_NG = 0
      let check_required = 0

      let flag_common = this.check_form_select()

      for(let key in resultRequiredArray) {
        resultRequiredArray[key] == 0 ? required_NG = 1 : check_required = 1
      }

      if(flag_common == 0 && check_required == 1 && required_NG == 0) { 
        $('#btn').removeClass('disabled').addClass('pulse')
      }
    })

    //[日付制限(遡り防止)]
    $('.dateLimitations').change(function() {
      let formated_today = moment().format('YYYY-MM-DD')
      let check_list = ["balanceDeadline", "sendDate", "callDate"]

      for (let i = 0; i < check_list.length; i++) {
        $('#' + check_list[i] + 'Check').text("")

        if ($('#' + check_list[i]).val() < formated_today && $('#' + check_list[i]).val() != "") {
          $('#' + check_list[i] + 'Check').text('※ 本日以降の日付を入力してください').css('color', '#e91e63')
          $('#' + check_list[i]).val("")
        }

      }
      
    })

    // [日付制限(本日以降防止)]
    $('.dateLimitationsBefore').change(function() {
      let formated_today = moment().format('YYYY-MM-DD')
      let check_list = ["agreementDate"]
    
      for (let i = 0; i < check_list.length; i++) {
        $('#' + check_list[i] + 'Check').text("")
    
        if ($('#' + check_list[i]).val() > formated_today && $('#' + check_list[i]).val() != "") {
          $('#' + check_list[i] + 'Check').text('※ 本日以前の日付を入力してください').css('color', '#e91e63')
          $('#' + check_list[i]).val("")
        }
      }
    })

    // 案件ID検索
    $('#subjectID').off('input.search').on('input.search', function(e) {
      let _this = $(this)

      const subject_id = _this.val()

      let textCount = 22

      if(subject_id.indexOf('_') == 13) {
        textCount = 26
      }

      let before = _this.data('subject_id')

      if(subject_id.indexOf('sungrove') == 0) {
        textCount = 11
      } else {
        $('.autoG, .gotAP, .form_wrap').slideUp()
      }

      if(subject_id.length < textCount || (before && before != subject_id) || e.originalEvent.inputType.indexOf("delete") == 0) {
        //$('#status_comment').text('案件IDを入力すると検索開始します')
        _this.data('subject_id', '')
        before = ""

        if($('.form_wrap').eq(0).css('display') == 'block') {
          $('.auto').prop('readonly', true);
          that.formClearFlag = 1
          _this.trigger('input.clear')
          _this.val(subject_id)
        }

        return false
      }

      const result = subject_id.match(/(^[A-Z]{2}[0-9A-Z]{11}?_[0-9]{13}$|^[A-Z]{2}[0-9]{6}[A-Z]?_[0-9]{13}$|^sungrove[123])/)
      
      if(!result) {
        if(subject_id.indexOf('sungrove') > -1) {
          $('#subjectID').val(subject_id)
        } 

        //alert('案件IDを正しく入力してください。')

        //$('#subjectID').val('')

        return false
      }

      // 入力されたら
      if($('#status_comment').text().indexOf('検索中') == -1) {
        $('.events').remove()

        if(subject_id.startsWith('sungrove') == false) { 
          const reg1 = /^[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]|[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]$/
          const reg2 = /[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]/g
          $('#subjectID').attr('readonly', true)

          if(reg1.test(subject_id)) subject_id = subject_id.replace(reg2, "")

          $('#btn').addClass('disabled').removeClass('pulse')
          $('#status_comment').text('検索中...🔎 少々お待ちください😣').css('color', '#757575')

          // Loading表示
          $('#loading_form').css('display', 'block')


          // 案件ID検索APIに投げるGAS API URL
          //let searchApiURL = that.getDataApiURL + '?type=searchSubjectId&SubjectId=' + subject_id

          let searchApiURL = that.store.state.apis.searchSubjectsIDApiURL + subject_id


          that.formClearFlag = 1
          _this.trigger('input.clear')
          _this.val(subject_id)

          let del_flag = 0
          let result_del = 0 
          let del_cal_id = 0
          let del_evnet_id = 0
          let del_desc = ''

          // Loading表示
          that.vueObj.viewLoader('検索中')
          that.vueObj.hideSelectAllForm()
          $('.select_checkbox').prop('checked', false)

          // 案件ID検索開始
          fetch(searchApiURL)
          .then(response => response.json())
          .then(response => {
            // Loading非表示
            that.vueObj.viewLoader('')

            $('#subjectID').attr('readonly', false)
            $('#loading_form').css('display', 'none')
            $('#status_comment').text('')
            $('select').css('display', 'block')
            $('.autoG, .item, .form_wrap').slideDown()

            let result_CalID = func.checkCalID(response)

            if(result_CalID !== 99) {
              del_flag = 1
              result_del = response.splice(result_CalID, 1) 
              del_cal_id = result_del[0].cal_id
              del_evnet_id = result_del[0].event_id
              del_desc = result_del[0].description
            }

            if(response.length == 1) {
              $('#status_comment').text('対象の案件を見つけました👀')
              let resTitle = response[0].title
              let resCalId = response[0].cal_id
              let exchangeEle = ''
              const regSaihou = /\[.*再.*\]/
              const errorText = func.titlePattern(resTitle, regSaihou)
              if (errorText) {
                $('#status_comment').html(errorText).css({'color': 'red', 'font-size': '16px'})
              }

              const isIncludeSaihouCal = officeData.checkIncludeSaihouCal(that.resCalId)
              const isRescanCal = officeData.checkRescanCal(that.resCalId)

              if(resTitle.indexOf('///*') !== -1 || resTitle.indexOf('*///') !== -1) {
                exchangeEle = func.v2Title(response[0])
              } else if(isIncludeSaihouCal || (isRescanCal && regSaihou.test(resTitle))) {
                exchangeEle = func.saihou(response[0])
                store.state.inputSaihou = '(再訪)'
              } else if(resTitle.indexOf('///') !== -1) {
                exchangeEle = func.v1Title(response[0])
              }

              $('#comName').val(exchangeEle.com_name).attr('readonly', true)
              $('#apName').val(exchangeEle.ap_name).attr('readonly', true)

            } else if(response.length >= 1) {
              $('#status_comment').text('複数のカレンダーが見つかりました。該当カレンダーのタイトルを選択してください。').css('color', '#29b6f6')
              const errorText = func.createSubjectList(response)
              if (errorText != []) {
                $('#status_comment').html(errorText).css({'color': 'red', 'font-size': '16px'})
              }
            } else {
              $('#status_comment').text('もう一度IDを確認して検索してください。').css('color', '#e91e63')
            }
          })

          return true

        } else if(subject_id == 'sungrove123') {
          $('select').css('display', 'block')
          $('.autoG, .item, .form_wrap').slideDown()
          $('#status_comment').text('案件IDがない案件の為、手動入力モードに切り替えました📝').css('color', '#e91e63')
          $('#comName').attr('readonly', false)
        
        }
      }  
    })
  }
}

import RequestBody from "../requestBody.class"
import ForwardPost from "../forwardPost.class"
import OfficeDatas  from "../officeData"

export default {
  commonFuncs
}