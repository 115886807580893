/**
  ブロックコンポーネント
*/
<template>
  <div class="c-block">
    <slot></slot>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({

  });
</script>

<style lang="scss">
.c-block {
  padding: 18px 20px 20px;
  background: $color-blue-4;
  border-radius: $radius;

  @include is-mobile {
    padding: 15px;
  }

  &:not(:last-child) {
    margin-bottom: 25px;
  }

  .c-choices {
    margin: 0 -10px;
  }

  .c-choices.-vertical {
    margin: 10px -4px 0;

    .c-choices__item:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &.addBox {
    border-radius: 0;

    &:not(:last-child) {
      margin-bottom: 0;
    }

    &:first-child {
      border-radius: 10px 10px 0 0;
    }

    &:last-child {
      border-radius: 0 0 10px 10px;
    }

    &:only-child {
      border-radius: $radius;
    }
  }
}
</style>
