/**
  テキストエリアコンポーネント
*/
<template>
  <textarea></textarea>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({

  });
</script>

<style lang="scss">
.c-textarea,
textarea {
  width: 100%;
  padding: 8px 10px;
  border-radius: $radius-small;
  border: 1px solid $color-gray-2;
  min-height: 100px;
  resize: vertical;
}
</style>
