/*
* ぽちボタンクリック後の処理
*/ 

class afrPostOption {
  constructor(that) {
    this.that = that
    this.formName = "オプション"
    this.that.$('.search_form_wrap').show()
  }

  returnFormName() {
    return this.formName
  }

  getInputData() {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func

    store.commit('setValue', ['option', 'input_option_val', func.textEscape($("#optionVal").val()), 'optionVal'])
  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    const sendVal = 
`
◎オプション
オプション：${store.state.formData.option.input_option_val}
`
    store.commit('setTalknoteMessage', ['option', sendVal])
  } 
}

export default {
  afrPostOption
}