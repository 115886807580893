/**
  チェックボックスコンポーネント
*/
<template>
  <label class="c-choice">
    <input v-bind:type="values.type" :class="{ 'form_check': values.formCheck}" v-bind:name="values.name" v-bind:value="values.value" v-bind:id="values.id" v-bind:data-id="values.dataId">
    <span>{{values.text}}</span>
  </label>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      values: Object
    }
  });
</script>

<style lang="scss">
.c-choice {
  input {
    &[type="radio"],
    &[type="checkbox"] {
      display: none;

      &:checked + span {
        &::before {
          border-color: $color-blue-3;
        }

        &::after {
          opacity: 1;
        }
      }

      &:disabled + span {
        opacity: .7;
      }

      + span {
        font-weight: $bold;
        position: relative;
        padding: 4px 4px 2px 32px;
        display: block;
        border-radius: $radius-small;
        cursor: pointer;

        &::before {
          content: "";
          display: block;
          background: $color-white;
          border: 2px solid $color-gray-2;
          width: 16px;
          height: 16px;
          position: absolute;
          top: 4px;
          left: 4px;
          transition: background-color $transition, border-color $transition;
        }

        &::after {
          content: "";
          display: block;
          width: 10px;
          height: 10px;
          position: absolute;
          top: 9px;
          left: 9px;
          transition: opacity $transition;
          opacity: 0;
        }
      }
    }

    &[type="radio"] {
      + span {
        &::before {
          border-radius: 50%;
        }

        &::after {
          border-radius: 50%;
          background: $color-blue-3;
        }
      }
    }

    &[type="checkbox"] {
      &:checked + span {
        &::before {
          background-color: $color-blue-3;
          border-color: $color-blue-3;
        }
      }

      + span {
        &::before {
          border-radius: $radius-small;
        }

        &::after {
          width: 4px;
          height: 10px;
          top: 7px;
          left: 11px;
          border: solid $color-white;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}
</style>
