/**
  セクションコンポーネント
*/
<template>
  <div class="c-section">
    <slot></slot>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({

  });
</script>

<style lang="scss" scoped>
.c-section {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
</style>
