/*
対象カラム
11 電話番号
12 URL
55 全文
*/

export default {
  
  "469871_tn-post-865f26de": [
    {
        "ユーザーID": {
            "id": "",
            "val": 1000189557
        },
        "備考": {
            "id": "",
            "val": ""
        },
        "全文": {
            "id": "",
            "val": "[tnPost 3.0.0β]\n案件ID：AP413830_1688120564206\n案件名：test\n回答種別：追客延長A\n次回アクション日：2023/07/02 （日） \n理由：test\n備考：\n-----------------------------\ntn-postID： 469871_tn-post-865f26de\nこちらのIDはSP投稿画面からの投稿IDです。"
        },
        "削除日時": {
            "id": "",
            "val": ""
        },
        "回答種別": {
            "id": "",
            "val": "追客延長A"
        },
        "所属エリア": {
            "id": "",
            "val": "名古屋"
        },
        "所属（組）": {
            "id": "",
            "val": "北條組"
        },
        "所属（課/部）": {
            "id": "",
            "val": "システム課"
        },
        "投稿ID": {
            "id": "",
            "val": "469871_tn-post-865f26de"
        },
        "投稿URL": {
            "id": "",
            "val": "https://s02.company.talknote.com/j6T71X45Nc3fEL7xsCpBxQ/search?keyword=469871_tn-post-865f26de"
        },
        "投稿日時": {
            "id": "",
            "val": "2023/06/30 19:54:30"
        },
        "投稿者名": {
            "id": "",
            "val": "テスト（青鹿） 英之"
        },
        "案件ID": {
            "id": "",
            "val": "AP413830_1688120564206"
        },
        "案件名": {
            "id": "",
            "val": "test"
        },
        "次回アクション日": {
            "id": "",
            "val": "2023/07/02 （日）"
        },
        "理由": {
            "id": "",
            "val": "test"
        },
        "開始時間": {
            "id": "",
            "val": ""
        }
    }
],
"negotiationReports": [
  {
      "id": 33877,
      "subjects_id": "AP413830_1691050742716",
      "users_id": 1700,
      "negotiation_results_id": 2,
      "contact_deadline_date": null,
      "gender_id": 3,
      "payment_statuses_id": 2,
      "created_at": "2023-08-21 15:53:04",
      "ap_evaluations_id": 2,
      "deleted_at": null,
      "sections_id": 7,
      "teams_id": 19,
      "post_id": "455639_tn-post-ee1fa76f"
  }
],
"negotiationSuggestions": [
  {
      "id": 33654,
      "negotiation_reports_id": 33877,
      "suggestions_id": 1,
      "created_at": "2023-08-21 15:53:04",
      "deleted_at": null
  },
  {
      "id": 33655,
      "negotiation_reports_id": 33877,
      "suggestions_id": 2,
      "created_at": "2023-08-21 15:53:04",
      "deleted_at": null
  },
  /*{
      "id": 33656,
      "negotiation_reports_id": 33877,
      "suggestions_id": 3,
      "created_at": "2023-08-21 15:53:04",
      "deleted_at": null
  },*/
  {
      "id": 33657,
      "negotiation_reports_id": 33877,
      "suggestions_id": 5,
      "created_at": "2023-08-21 15:53:04",
      "deleted_at": null
  },
  {
      "id": 33658,
      "negotiation_reports_id": 33877,
      "suggestions_id": 6,
      "created_at": "2023-08-21 15:53:04",
      "deleted_at": null
  },
  {
      "id": 33659,
      "negotiation_reports_id": 33877,
      "suggestions_id": 7,
      "created_at": "2023-08-21 15:53:04",
      "deleted_at": null
  },
  {
      "id": 33660,
      "negotiation_reports_id": 33877,
      "suggestions_id": 99,
      "created_at": "2023-08-21 15:53:04",
      "deleted_at": null
  }
],
"negotiationPositions": [
  {
      "id": 38199,
      "client_positions_id": 1,
      "negotiation_reports_id": 33877,
      "created_at": "2023-08-21 15:53:04",
      "deleted_at": null
  },
  /*{
      "id": 38200,
      "client_positions_id": 2,
      "negotiation_reports_id": 33877,
      "created_at": "2023-08-21 15:53:04",
      "deleted_at": null
  },
  {
      "id": 38201,
      "client_positions_id": 3,
      "negotiation_reports_id": 33877,
      "created_at": "2023-08-21 15:53:04",
      "deleted_at": null
  }*/
]

}