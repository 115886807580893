/**
  申し込み用フォーム共通コンポーネント
*/
<template>
 <div>
  <Header></Header>
  <div class="l-wrapper">
    <div class="l-content">

      <Title
      :isAdmin="isAdmin"
      :groupTitle="groupTitle"
      :modeChangeBtnText="modeChangeBtnText"
      :modeChangeBtnLabel="modeChangeBtnLabel"
      :modeStatus="modeStatus"
      :modeChange="modeChange"
      :edit="edit"
      :post="post"
      ></Title>

      <Section>
        <Description>
          お疲れさまです。<br>
          <span v-html="displayName"></span>さん
        </Description>
      </Section>
      <form action="" method="get" id="form_ap" autocomplete="off">
        <Box class="pd3_parts js-notEditable" id="wrapFormList">
          <div id="normalHtml">

            <Column class="search_form_wrap">

              <Column id="searchID">
                <label for="name">案件IDで案件検索</label>
                <div class="display_yoko">
                  <input class="required form_txt" type="text" id="subjectID" placeholder="（例）AP111111_111111" data-id="" min="22" v-model="editableItems.subjectID">
                </div>
              </Column>

              <Column id="result_api">
                <label>検索コメント：</label>
                <label id="status_comment" class="c-notice">案件IDを入力すると検索開始します</label>
                <div class="width100 text_center">
                  <span class="wrapper4 timer-loader" id="loading_form"></span>
                </div>
                <div class="display_tate" id="result_titles">
                </div>
              </Column>

            </Column>

            <Column class="common_form_wrap form_wrap form_list">
              <Column class="autoG pd3_parts">
                <label>案件名</label>
                <div class="display_yoko">
                  <input type="text" class="required form_txt" id="comName" >
                </div>
              </Column>
              <Column class="autoG pd3_parts">
                <label>アポ担当</label>
                <div class="display_yoko">
                  <input type="text" class="required form_txt" id="apName" >
                </div>
              </Column>
            </Column>

          </div>
        </Box>

        <Box id="formCheckList" class="form_group form_wrap form_list js-editable">

          <div class="rap1_boder" id="wrap2">
            <Section>
              <h1 class="wrap_title pd_7">入金情報</h1>
            </Section>

            <Column class="min_width200px pd3_parts formPayment_defalt js-editable">
              <label >決済進捗</label>
              <div class="c-select">
                <select name="" id="paymentStatus" class="required"  v-model="editableItems.paymentStatus">
                  <option value="" disabled selected>--決済進捗 選択--</option>
                  <option data-status="done" data-id = "1">決済完了</option>
                  <option data-status="doing" data-id = "2">一部決済</option>
                  <option data-status="notYet" data-id = "3">未決済</option>
                </select>
              </div>
            </Column>
            <Column class="pd3_parts formPayment_defalt js-editable">
              <label>受注金額</label>
              <Flex class="display_yoko">
                <input type="text" class="required form_txt" inputmode="numeric" id="salesPrice" placeholder="半角数字で入力" v-model="editableItems.salesPrice">
                <span class="chara_bottom">円</span>
              </Flex>
            </Column>
            <Column class="pd3_parts formPayment_defalt">
              <label >決済済み金額</label>
              <Flex class="display_yoko">
                <input id="paid" type="text" inputmode="numeric" min="1" class="required form_txt" placeholder="半角数字で入力" v-model="editableItems.paid">
                <span class="chara_bottom">円</span>
              </Flex>
            </Column>
            <Column class="js-editable">
              <Block class="c-group display_yoko pd3_parts addDelPayment addBox">
                <div class="mg1 formPayment_defalt balanceInfo" >
                  <label >残額</label>
                  <Flex class="display_yoko">
                    <div>
                      <input type="text" id="balance" name="balance" min="1" class="balance required addDelText balanceOp balanceInfo_balance form_txt samePrice" inputmode="numeric" placeholder="半角数字で入力" v-model="editableItems.balance">
                    </div>
                    <span class="chara_bottom">円</span>
                  </Flex>
                </div>
                <div class="formPayment_option display_none">
                  <label >残額期日</label>
                  <label id="balanceDeadlineCheck"></label>
                  <div>
                    <div class="formPayment_option display_none">
                      <input type="date" id="balanceDeadline" name="balanceDeadline" class="balanceDeadline addDelText paymentOp balanceInfo_date form_txt dateLimitations" min="" v-model="editableItems.balanceDeadline">
                    </div>
                  </div>
                </div>
                <div class="formPayment_option display_none">
                  <label >支払い方法</label>
                  <div class="c-select">
                    <select name="paymentMethod" class="paymentMethod form_select addDelText formPayment_option paymentOp balanceInfo_paymentMethod" v-model="editableItems.paymentMethod">
                      <option value="" disabled selected>--支払い方法 選択--</option>
                      <option data-id = "1">ビジクレ</option>
                      <option data-id = "2">カード払い</option>
                      <option data-id = "3">カード振替</option>
                      <option data-id = "4">口座振替</option>
                      <option data-id = "5">振り込み</option>
                    </select>
                  </div>
                </div>
                <Flex>
                  <IconAdd name="add" value="＋" class="form_add btn-floating btn-small waves-effect waves-light red lighten-3 mg1 formPayment_option -flex display_none"></IconAdd>
                  <IconRemove name="del" value="－" class="form_del btn-floating btn-small waves-effect waves-light blue lighten-3 formPayment_option -flex display_none"></IconRemove>
                </Flex>
              </Block>
            </Column>
            <Column class="pd3_parts formPayment_option js-notEditable">
              <label>残額期日理由</label>
              <div class="form_parts"><textarea id="balanceDeadlineReason" class="paymentOp"></textarea></div>
            </Column>
            <Column class="pd3_parts formAddress use_credit_display">
              <label>ビジクレ使用可否</label>
              <ul class="c-choices">
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" name="use_credit_yesNo" id="use_credit_yes" value="可能" class="required">
                    <span class="">可能</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" name="use_credit_yesNo" id="use_credit_no" value="不可" class="required">
                    <span class="">不可</span>
                  </label>
                </li>
              </ul>
            </Column>
            <Column class="pd3_parts formPayment_defalt display_none form_parts js-notEditable">
              <label>補足</label>
              <div class="form_parts"><textarea id="paymentDetail" class=""></textarea></div>
            </Column>
          </div>
        </Box>

        <Box class="form_group  form_wrap form_list">
          <Column class="rap1_boder js-notEditable" id="">
            <Section>
              <h1 class="wrap_title pd_7">引き継ぎ情報</h1>
            </Section>

            <Column class="pd3_parts formHandover">
              <label>契約日</label>
              <Group>
                <div class="pd3_parts">
                  <p>
                    <label class="c-choice">
                      <input class="agreeToday" type="checkbox" id="unnecessary_agreementDate" />
                      <span>投稿日当日</span>
                    </label>
                  </p>
                </div>
                <div class="pd3_parts">
                  <label id="agreementDateCheck"></label>
                  <input type="date" id="agreementDate" class="required dateUnnecessary form_txt dateLimitationsBefore">
                </div>
              </Group>
            </Column>

            <Column class="pd3_parts formHandover">
              <label>必要書類</label>
              <ul class="c-choices">
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" class="required" name="necessaryDoc" value="請求書" id="">
                    <span class="">請求書</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" class="required" name="necessaryDoc" value="受領書" id="">
                    <span class="">受領書</span>
                  </label>
                </li>
              </ul>
            </Column>
            <Column class="pd3_parts formHandover">
              <label>送付希望日</label>
              <Group>
                <div class="pd3_parts">
                  <p>
                    <label class="c-choice">
                      <input class="unnecessary" type="checkbox" id="unnecessary_sendDate" />
                      <span>不要</span>
                    </label>
                  </p>
                </div>
                <div class="pd3_parts">
                  <label id="sendDateCheck"></label>
                  <input type="date" id="sendDate" class="required dateUnnecessary form_txt dateLimitations" placeholder="送付希望日を選択してください">
                </div>
              </Group>
            </Column>
            <Column class="pd3_parts formHandover">
              <label>お礼電話日時</label>
              <Group>
                <div class="pd3_parts">
                  <p>
                    <label class="c-choice">
                      <input class="unnecessary" type="checkbox" id="unnecessary_callDate" />
                      <span>不要</span>
                    </label>
                  </p>
                </div>
                <div class="pd3_parts">
                  <label id="callDateCheck"></label>
                  <input type="date" id="callDate" class="required dateUnnecessary form_txt dateLimitations" placeholder="架電日時を選択してください">
                </div>
                <input type="text" placeholder="備考（※時間指定は受けられません）" id="callDateSppl">
                <label class="attention">17時以降の投稿の場合は翌日以降の指定をお願いします</label>
              </Group>
            </Column>
            <Column class="pd3_parts formHandover">
              <label>領収書</label>
              <Group>
                <ul class="c-choices">
                  <li class="c-choices__item">
                    <label class="c-choice">
                      <input type="radio" class="required" name="receipt_yesNo" id="receipt_yes" value="有り">
                      <span class="">有り</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice">
                      <input type="radio" class="required" name="receipt_yesNo" id="receipt_no" value="無し">
                      <span class="">無し</span>
                    </label>
                  </li>
                </ul>
                <label class="attention">発行が必要な場合は、上長の許可を得て別途ご相談ください</label>
              </Group>
            </Column>
          </Column>

          <Column class="select_form rap1_boder js-editable">
            <label>受注商材を選択してください。</label>
            <div class="select_form_inner">
              <div class="select_form_list">
                <p>
                  <label class="c-choice">
                    <input name="select_form" id="formItzaiSelect" class="select_checkbox" type="checkbox" value="itzai" data-suggestion-id="3" data-hook-id="3" data-name="イツザイ">
                    <span>イツザイ</span>
                  </label>
                </p>
                <p>
                  <label class="c-choice">
                    <input id="formItzaiLiteSelect" name="select_form" class="select_checkbox" type="checkbox" value="itzaiLite" data-suggestion-id="6" data-hook-id="3" data-name="イツザイ ライト">
                    <span>イツザイ ライト</span>
                  </label>
                </p>
                <p>
                  <label class="c-choice">
                    <input name="select_form" id="formECSelect" class="select_checkbox" type="checkbox" value="ec" data-suggestion-id="2" data-name="EC">
                    <span>EC</span>
                  </label>
                </p>
                <p>
                  <label class="c-choice">
                    <input name="select_form" id="formWELSelect" class="select_checkbox" type="checkbox" value="wel" data-hook-id="2" data-name="WEL">
                    <span>WEL</span>
                  </label>
                </p>
                <p>
                  <label class="c-choice">
                    <input name="select_form" id="formReloSelect" class="select_checkbox" type="checkbox" value="relo" data-hook-id="1" data-name="リロ">
                    <span>リロ</span>
                  </label>
                </p>
                <p>
                  <label class="c-choice">
                    <input name="select_form" id="formSEOSelect" class="select_checkbox" type="checkbox" value="seo" data-suggestion-id="6" data-name="SEO">
                    <span>SEO</span>
                  </label>
                </p>
                <p>
                  <label class="c-choice">
                    <input name="select_form" id="formMEOSelect" class="select_checkbox" type="checkbox" value="meo" data-suggestion-id="7" data-name="MEO">
                    <span>MEO</span>
                  </label>
                </p>
                <p>
                  <label class="c-choice">
                    <input name="select_form" id="formCMSSelect" class="select_checkbox" type="checkbox" value="cms" data-suggestion-id="1" data-name="CMS">
                    <span>CMS</span>
                  </label>
                </p>
                <p>
                  <label class="c-choice">
                    <input name="select_form" id="formCMSLodgingSelect" class="select_checkbox" type="checkbox" value="cms_lodging" data-suggestion-id="9" data-name="CMS（宿泊型番）">
                    <span>CMS（宿泊型番）</span>
                  </label>
                </p>
                <p>
                  <label class="c-choice">
                    <input name="select_form" id="formSubsidySelect" class="select_checkbox" type="checkbox" value="subsidy" data-suggestion-id="5" data-name="助成金Lab">
                    <span>助成金Lab</span>
                  </label>
                </p>
                <p>
                  <label class="c-choice">
                    <input name="select_form" id="formComOptionSelect" class="select_checkbox" type="checkbox" value="com_option" data-suggestion-id="99" data-name="補償オプション">
                    <span>補償オプション</span>
                  </label>
                </p>
                <p>
                  <label class="c-choice">
                    <input name="select_form" id="formVideoProductionSelect" class="select_checkbox" type="checkbox" value="video_production" data-suggestion-id="99"  data-name="動画制作">
                    <span>動画制作</span>
                  </label>
                </p>
                <p>
                  <label class="c-choice">
                    <input name="select_form" id="formOptionSelect" class="select_checkbox" type="checkbox" value="option"  data-suggestion-id="99" data-name="オプション">
                    <span>オプション</span>
                  </label>
                </p>
              </div>
            </div>
          </Column>
        </Box>

        <div id="formGroups" v-if="isFormGroups">
          <formApplicationItzai class="c-box" v-if="isFormApplicationItzai" ref="formApplicationItzaiMethod"></formApplicationItzai>

          <formApplicationItzaiLite class="c-box" v-if="isFormApplicationItzaiLite" ref="formApplicationItzaiLiteMethod"></formApplicationItzaiLite>

          <formApplicationEC class="c-box" v-if="isFormApplicationEC" ref="formApplicationECMethod"></formApplicationEC>

          <formApplicationWEL class="c-box" v-if="isFormApplicationWEL" ref="formApplicationWELMethod"></formApplicationWEL>

          <formApplicationRelo class="c-box" v-if="isFormApplicationRelo" ref="formApplicationReloMethod"></formApplicationRelo>

          <formApplicationSEO class="c-box" v-if="isFormApplicationSEO" ref="formApplicationSEOMethod"></formApplicationSEO>

          <formApplicationMEO class="c-box" v-if="isFormApplicationMEO" ref="formApplicationMEOMethod"></formApplicationMEO>

          <formApplicationCMS class="c-box" v-if="isFormApplicationCMS" ref="formApplicationCMSMethod"></formApplicationCMS>

          <formApplicationCMSLodging class="c-box" v-if="isFormApplicationCMSLodging" ref="formApplicationCMSLodgingMethod"></formApplicationCMSLodging>

          <formApplicationSubsidy class="c-box" v-if="isFormApplicationSubsidy" ref="formApplicationSubsidyMethod"></formApplicationSubsidy>

          <formApplicationComOption class="c-box" v-if="isFormApplicationComOption" ref="formApplicationComOptionMethod"></formApplicationComOption>

          <formApplicationVideoProduction class="c-box" v-if="isFormApplicationVideoProduction" ref="formApplicationVideoProductionMethod"></formApplicationVideoProduction>

          <formApplicationOption class="c-box" v-if="isFormApplicationOption" ref="formApplicationOptionMethod"></formApplicationOption>

          <formApplicationContactInfo class="c-box" v-if="isFormApplicationContactInfo" ref="formApplicationContactInfoMethod"></formApplicationContactInfo>
        </div>

        <div class="common_form_wrap form_wrap form_list">
          <Column class="rap1_boder" id="wrap3">
            <div class="pd3_parts">
              <label>備考</label>
              <div><textarea id="remark" class="form_txt"></textarea></div>
            </div>
          </Column>

          <div class="pd2 text_center" id="filedBtn">
            <Button :tag="'a'" class="waves-effect waves-light btn disabled" id="btn">slackへ送信</Button>
          </div>
        </div>
      </form>
      
      <Loading :class="{ '-active': isLoading }" :loaderText="loaderText"></Loading>
      <Complete v-if="isComplete" :groupTitle="groupTitle" :groupURL="groupURL" :searchURL="searchURL" :message="message" :completeTitle="completeTitle"></Complete>
    </div>
  </div>
</div>
</template>


<script>
import { defineComponent } from 'vue'
import moment from "moment"
import $ from 'jquery'
import "jquery-ui"
import sha256 from 'crypto-js/sha256'
import axios from 'axios';

import formDataStore from "../../../assets/js/formDataStore"
import formFuncClass from "../../../assets/js/formFunctions.class"
import applicationMainClass from "../../../assets/js/applications/main.class"

// コンポーネントを読み込む
import Header from '../../organisms/header.vue';
import Title from '../../organisms/title.vue';
import Loading from '../../organisms/loading.vue';
import Complete from '../../complete.vue';

import Variables from '../../molecules/variables.vue';
import Choices from '../../molecules/choices.vue';
import Box from '../../molecules/box.vue';
import Section from '../../molecules/section.vue';
import Column from '../../molecules/column.vue';
import Group from '../../molecules/group.vue';
import Flex from '../../molecules/flex.vue';
import Block from '../../molecules/block.vue';

import Description from '../../atoms/description.vue';
import Label from '../../atoms/label.vue';
import Input from '../../atoms/input.vue';
import Select from '../../atoms/select.vue';
import Textarea from '../../atoms/textarea.vue';
import Button from '../../atoms/button.vue';
import IconAdd from '../../atoms/icon-add.vue';
import IconRemove from '../../atoms/icon-remove.vue';

import formApplicationItzai from "./forms/formApplicationItzai.vue"
import formApplicationItzaiLite from "./forms/formApplicationItzaiLite.vue"
import formApplicationEC from "./forms/formApplicationEC.vue"
import formApplicationWEL from "./forms/formApplicationWEL.vue"
import formApplicationRelo from "./forms/formApplicationRelo.vue"
import formApplicationSEO from "./forms/formApplicationSEO.vue"
import formApplicationMEO from "./forms/formApplicationMEO.vue"
import formApplicationCMS from "./forms/formApplicationCMS.vue"
import formApplicationCMSLodging from "./forms/formApplicationCMSLodging.vue"
import formApplicationSubsidy from "./forms/formApplicationSubsidy.vue"
import formApplicationComOption from "./forms/formApplicationComOption.vue"
import formApplicationVideoProduction from "./forms/formApplicationVideoProduction.vue"
import formApplicationOption from "./forms/formApplicationOption.vue"
import formApplicationContactInfo from "./forms/formApplicationContactInfo.vue"


const dev = 0

export default defineComponent({
  name: 'formApplicationMain',

  components: {
    Header,
    Title,
    Loading,
    Complete,

    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,

    formApplicationItzai,
    formApplicationItzaiLite,
    formApplicationEC,
    formApplicationWEL,
    formApplicationRelo,
    formApplicationSEO,
    formApplicationMEO,
    formApplicationCMS,
    formApplicationCMSLodging,
    formApplicationSubsidy,
    formApplicationComOption,
    formApplicationVideoProduction,
    formApplicationOption,
    formApplicationContactInfo,
  },

  data() {
    return {
      isLoading: false,
      isComplete: false,
      isBody: true,
      loaderText: '',
      groupTitle: '',
      searchURL: '',
      message: '',
      displayName: '',
      completeTitle: '',

      isMain:true,
      isList: true,
      isForm: false,
      isMessage: true,
      isGroupTitle: false,
      isLoading: false,
      isComplete: false,
      talknoteGroupAddress: "",
      groupURL: '',
      groupList: [],
      message: '',
      resetFunction: {},
      formType: '',
      loaderText: '',

      isFormGroups: dev? true: false,
      isFormApplicationItzai: dev? true: false,
      isFormApplicationItzaiLite: dev? true: false,
      isFormApplicationEC: dev? true: false,
      isFormApplicationWEL: dev? true: false,
      isFormApplicationRelo: dev? true: false,
      isFormApplicationSEO: dev? true: false,
      isFormApplicationMEO: dev? true: false,
      isFormApplicationCMS: dev? true: false,
      isFormApplicationCMSLodging: dev? true: false,
      isFormApplicationSubsidy: dev? true: false,
      isFormApplicationComOption: dev? true: false,
      isFormApplicationVideoProduction: dev? true: false,
      isFormApplicationOption: dev? true: false,
      isFormApplicationContactInfo: dev? true: false,

      isAdmin: false,
      modeChangeBtnText: '',
      modeChangeBtnLabel: '',
      modeStatus: '',
      modeChange: () => {},
      edit: true,
      post: false,

      editableItems: {
        selectForm: []
      }
    }
  },

  methods: {
    init() {

      console.log('フォーム初期化開始')
      this.thisFormParams = this.store.state.groupList.filter((obj) => obj[0].trim() == this.$route.params.type.trim())[0]
      this.groupTitle = this.thisFormParams[0]
      this.displayName = this.store.state.userData.displayName
      this.axios = axios

      // 投稿先のtalknoteノートURL
      //this.groupURL = this.thisFormParams[1]

      // talknote投稿用アドレス
      //this.talknoteGroupAddress = this.thisFormParams[2]

      // フォームが本番かdemoか
      this.isProd = this.groupTitle.indexOf('demo_') == -1

      // 投稿先のslackチャンネル情報
      this.slackChannel = {
        id: this.thisFormParams[6],
        userID: this.store.state.userData.slackUserID,
        name: this.thisFormParams[7],
        postURL: this.store.state.apis.slackPOSTURL,
        workSpaceURL: this.store.state.apis.slackWorkSpaceURL,
        token: this.store.state.apis.slackToken,
        iconURL: this.store.state.userData.emoji,
        forwardID01: this.thisFormParams[8],
        forwardID02: this.thisFormParams[9],
        errorPostID: this.store.state.apis.errorPostSlackChannelID
      }

      const func = new formFuncClass.formFunctions(moment, $, sha256, this);
      new applicationMainClass.main(this, $, func, moment, formDataStore);

      if(dev) {
        $('select').css('display', 'block')
        $('.autoG, .item, .form_wrap').slideDown()
      }

      console.log('フォーム初期化完了')
    },

    getProps() {
      return {
        user: formDataStore.state.userData,
        talknoteGroupAddress: this.talknoteGroupAddress,
        groupURL: this.groupURL,
        groupTitle: this.groupTitle,
        version: formDataStore.state.version,
        getDataApiURL: formDataStore.state.apis.getDataApiURL,
      }
    },

    toggleSelectForm(formType, toggle) {
      this.isFormGroups = true;

      switch(formType) {
        case 'itzai': {
          this.isFormApplicationItzai = toggle;
          this.isFormApplicationContactInfo = toggle;
          break;
        }
        case 'itzaiLite': {
          this.isFormApplicationItzaiLite = toggle;
          this.isFormApplicationContactInfo = toggle;
          break;
        }
        case 'ec': {
          this.isFormApplicationEC = toggle;
          this.isFormApplicationContactInfo = toggle;
          break;
        }
        case 'wel': {
          this.isFormApplicationWEL = toggle;
          break;
        }
        case 'relo': {
          this.isFormApplicationRelo = toggle;
          break;
        }
        case 'seo': {
          this.isFormApplicationSEO = toggle;
          break;
        }
        case 'meo': {
          this.isFormApplicationMEO = toggle;
          break;
        }
        case 'cms': {
          this.isFormApplicationCMS = toggle;
          this.isFormApplicationContactInfo = toggle;
          break;
        }
        case 'cms_lodging': {
          this.isFormApplicationCMSLodging = toggle;
          this.isFormApplicationContactInfo = toggle;
          break;
        }
        case 'subsidy': {
          this.isFormApplicationSubsidy = toggle;
          break;
        }
        case 'com_option': {
          this.isFormApplicationComOption = toggle;
          break;
        }
        case 'video_production': {
          this.isFormApplicationVideoProduction = toggle;
          break;
        }
        case 'option': {
          this.isFormApplicationOption = toggle;
          break;
        }
      }
    },

    hideSelectAllForm() {
      this.isFormGroups = false;
      this.isFormApplicationItzai = false;
      this.isFormApplicationItzaiLite = false;
      this.isFormApplicationEC = false;
      this.isFormApplicationWEL = false;
      this.isFormApplicationRelo = false;
      this.isFormApplicationSEO = false;
      this.isFormApplicationMEO = false;
      this.isFormApplicationCMS = false;
      this.isFormApplicationCMSLodging = false;
      this.isFormApplicationSubsidy = false;
      this.isFormApplicationComOption = false;
      this.isFormApplicationVideoProduction = false;
      this.isFormApplicationOption = false;
      this.isFormApplicationContactInfo = false;
    },

    //画面遷移前に実行させること
    reset() {
      $('#subjectId').val('').trigger('input.clear');
    },

    viewLoader(loaderText = '') {
      if(
          (!this.loaderText && !loaderText) ||
          (this.isLoading && loaderText == '')
        ) {
        this.isLoading = false
        this.loaderText = ''
        return 
      }
      this.isLoading = true
      this.loaderText = loaderText
      console.log('ローディング表示')
    },

    viewComplete(param) {
      console.log(param)
      this.groupTitle = this.groupTitle;
      this.groupURL = this.groupURL;
      this.searchURL = param.searchURL;
      this.message = param.message;
      this.completeTitle = param.completeTitle? param.completeTitle: '投稿完了';
      this.isBody = false;
      this.isComplete = true;
      console.log('完了画面表示')
    },
  },

  mounted() {
    
    this.store = formDataStore

    if(this.store.state.groupList.length == 0) {
      this.store.watch(
        (state, getters) => getters.groupList,
        (newValue, oldValue) => {
          console.log('ページ固有のデータ取得');
          this.init()
        }
      )
    } else {
      this.init()
    }
  },

  setup(_, context) {
    return {}
  }

});
</script>
