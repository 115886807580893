/**
  追加ボタンコンポーネント
*/
<template>
  <a class="c-icon-add" v-if="tag === 'a'"></a>
  <button type="button" class="c-icon-add" aria-label="項目を追加する" v-else></button>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      tag: String
    }
  });
</script>

<style lang="scss" scoped>
.c-icon-add {
  width: 24px;
  min-width: 24px;
  height: 24px;
  background: $color-blue-3;
  transition: background $transition;
  border-radius: 24px;
  position: relative;

  @include is-hover {
    background: $color-blue-1;
  }

  &:disabled {
    background: $color-gray-2;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    width: 12px;
    height: 2px;
    background: $color-white;
    position: absolute;
    top: calc(50% - 1px);
    left: calc(50% - 6px);
  }

  &::after {
    transform: rotate(90deg);
  }
}
</style>
