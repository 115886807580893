/**
  インプットコンポーネント
*/
<template>
  <input class="c-input">
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({

  });
</script>

<style lang="scss">
.c-input,
input:not([type="radio"]):not([type="checkbox"]) {
  width: 100%;
  line-height: 1;
  padding: 8px 10px;
  border-radius: $radius-small;
  border: 1px solid $color-gray-2;
  min-height: 39px;

  &:read-only {
    background: $color-gray-1;
  }

  &::-webkit-calendar-picker-indicator {
    $base64: 'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 24"><path fill="#{$color-primary}" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>';
    width: 20px;
    height: 16px;
    background-image: url(str-replace("#", "%23", $base64));
  }
}

::placeholder {
  color: $color-gray-2;
}
</style>
