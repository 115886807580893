/**
  タイトルコンポーネント
*/
<template>
  <Section>
    <h1>
      <span v-html="groupTitle"></span>
      <button class="c-button mode-change-btn" v-if="isAdmin" v-bind:class="{edit:edit, post:post}" v-bind:title="modeChangeBtnText" v-html="modeChangeBtnLabel" @click="modeChange"></button>
    </h1>
    <p class="mode-status" v-if="isAdmin" v-bind:class="{post:edit, edit:post}" v-text="modeStatus"></p>
  </Section>
</template>

<script>
  import { defineComponent } from 'vue';
  import Section from '../molecules/section.vue';

  export default defineComponent({

    data() {
      return {}
    },
    components: {
      Section
    },
    props: {
      isAdmin: Boolean,
      groupTitle: String,
      modeChangeBtnText: String,
      modeChangeBtnLabel: String,
      modeStatus: String,
      modeChange: Function,
      edit: Boolean,
      post: Boolean,
    },

    mounted: async function() {

    },

    methods: {

    }
  });
</script>

<style lang="scss" scoped>

</style>