/**
  レイアウトコンポーネント
*/
<template>
  <div class="c-box" :class="{ '-no-padding': noPadding }">
    <slot></slot>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      noPadding: Boolean
    }
  });
</script>

<style lang="scss">
.c-box {
  text-align: left;
  padding: 30px 20px;
  background: $color-white;
  border-top: 2px solid $color-blue-3;
  box-shadow: 0 6px 16px rgba($color-black, .05);
  margin-bottom: 30px;

  @include is-mobile {
    padding: 15px 10px;
  }

  &.-no-padding {
    padding: 0;
  }

  .c-nav {
    .-bg-demo {
      background: $color-blue-4;
    }
  }
}
</style>
