/**
  申し込み用フォーム[連絡先情報]コンポーネント
*/
<template>
<div id="formContactInfo" class="form_group">
  <Section id="is_contact_info" class="form_wrap form_list">
    <div class="rap1_boder" id="wrap3">
      <Section><h1 class="wrap_title pd_7">連絡先情報</h1></Section>
      <div class="formAddress">
        <Column class="pd3_parts">
          <label>社名</label>
          <input type="text" id="contactComName" class="required form_txt">
        </Column>
        <Column class="pd3_parts formAddress">
          <label>代表取締役者名</label>
          <input type="text" id="contactCEOName" class="required form_txt">
        </Column>
        <Column class="pd3_parts formAddress">
          <label>担当者名</label>
          <input type="text" id="contactName" class="required form_txt">
        </Column>
        <Column class="pd3_parts formAddress">
          <label>メールアドレス</label>
          <input type="text" id="contactMail" class="required form_txt">
        </Column>
        <Column class="pd3_parts formAddress">
          <label>電話番号</label>
          <input type="text" id="contactTelNum" class="required form_txt">
        </Column>
        <Column class="pd3_parts formAddress">
          <label>WEB打ち合わせ可否</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" name="canOnlineMtg_yesNo" id="onlineMtg_yes" value="可能" class="required">
                <span class="">可能</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" name="canOnlineMtg_yesNo" id="onlineMtg_no" value="不可" class="required">
                <span class="">不可</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" name="canOnlineMtg_yesNo" id="no" value="不明" class="required">
                <span class="">不明</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formAddress progress_display">
          <label>進行可否</label>
          <p class="-attention">※進行開始日を調整したい場合は、不可を選択ください</p>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" name="progress_yesNo" id="progress_yes" value="可能" class="required">
                <span class="">可能</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" name="progress_yesNo" id="progress_no" value="不可" class="required">
                <span class="">不可</span>
              </label>
            </li>
          </ul>
        </Column>
      </div>
    </div>
  </Section>
</div>
</template>


<script>
import { defineComponent } from 'vue'

// コンポーネントを読み込む
import Variables from '../../../molecules/variables.vue';
import Choices from '../../../molecules/choices.vue';
import Box from '../../../molecules/box.vue';
import Section from '../../../molecules/section.vue';
import Column from '../../../molecules/column.vue';
import Group from '../../../molecules/group.vue';
import Flex from '../../../molecules/flex.vue';
import Block from '../../../molecules/block.vue';

import Description from '../../../atoms/description.vue';
import Label from '../../../atoms/label.vue';
import Input from '../../../atoms/input.vue';
import Select from '../../../atoms/select.vue';
import Textarea from '../../../atoms/textarea.vue';
import Button from '../../../atoms/button.vue';
import IconAdd from '../../../atoms/icon-add.vue';
import IconRemove from '../../../atoms/icon-remove.vue';

export default defineComponent({
  name: 'formApplicationContactInfo',

  components: {
    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,
  },

  methods: {
  },

  mounted() {
  },

  setup(_, context) {
    return {}
  }

})
</script>
