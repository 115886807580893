/**
  ラベルコンポーネント
*/
<template>
  <label class="c-label">
    <slot></slot>
  </label>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({

  });
</script>

<style lang="scss">
.c-label,
label:not([class]) {
  @include fz(14);
  color: $color-blue-1;
  font-weight: $bold;
  display: block;
  margin-bottom: 6px;
}
</style>
