/**
  申し込み用フォーム[WEL]コンポーネント
*/
<template>
<div id="formWel" class="form_group">
  <Column class="form_wrap form_list">
    <div class="rap1_boder" id="">
      <Section><h1 class="wrap_title pd_7">WEL</h1></Section>
      <Column class="pd3_parts">
        <label>カテゴリ数</label>
        <input type="text" class="required form_txt" inputmode="numeric" id="welCateNum">
      </Column>
      <Column class="pd3_parts">
        <label>カテゴリ</label>
        <input type="text" class="required form_txt" id="welCate">
      </Column>
      <Column class="pd3_parts formSharedComm">
        <label>特典</label>
        <input type="text" class="required form_txt" id="welBenefits">
      </Column>
    </div>
  </Column>
  <Column class="form_wrap form_list">
    <div class="rap1_boder" id="">
      <div class="pd3_parts form_parts">
        <label>備考欄・特記事項</label>
        <div>
          <textarea class="remarks_textarea" id="welOtherShared"></textarea>
        </div>
      </div>
    </div>
  </Column>
</div>
</template>


<script>
import { defineComponent } from 'vue'

// コンポーネントを読み込む
import Variables from '../../../molecules/variables.vue';
import Choices from '../../../molecules/choices.vue';
import Box from '../../../molecules/box.vue';
import Section from '../../../molecules/section.vue';
import Column from '../../../molecules/column.vue';
import Group from '../../../molecules/group.vue';
import Flex from '../../../molecules/flex.vue';
import Block from '../../../molecules/block.vue';

import Description from '../../../atoms/description.vue';
import Label from '../../../atoms/label.vue';
import Input from '../../../atoms/input.vue';
import Select from '../../../atoms/select.vue';
import Textarea from '../../../atoms/textarea.vue';
import Button from '../../../atoms/button.vue';
import IconAdd from '../../../atoms/icon-add.vue';
import IconRemove from '../../../atoms/icon-remove.vue';

export default defineComponent({
  name: 'formApplicationWEL',

  components: {
    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,
  },

  methods: {
  },

  mounted() {
  },

  setup(_, context) {
    return {}
  }

})
</script>
