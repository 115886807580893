/*****
メッセージを投稿するクラス
******/

export default class {
  constructor(param) {
    this.param = param
    this.channelID = param.channelID
    this.textHeader = ''

    switch(this.param.type) {
      case 'error': {
        this.textHeader = this.errorHeader()
      }
      default: {
        this.textHeader = this.defaultHeader() 
      }
    }
  }

  defaultHeader() {
    return `(1) プログラム名: sg-post
    (2) 環境：${this.param.isProd? 'production': 'dev'}
    (3) 担当：<@U05KVEFT3T5>
    (4) プラグラムURL: ${location.href}
    (5) メッセージ：`
  }

  errorHeader () {
    return  `(1) プログラム名: sg-post
    (2) 環境：${this.param.isProd? 'production': 'dev'}
    (3) 担当：<@U05KVEFT3T5>
    (4) プラグラムURL: ${location.href}
    (5) エラーメッセージ：`
  }
  
  post() {
    if(!this.channelID) {
      console.error('投稿先のchannel IDがありません')
      return false
    }  

    try {
      fetch(this.param.slackChannel.postURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `token=${encodeURIComponent(this.param.slackChannel.token)}&channel=${encodeURIComponent(this.channelID)}&text=${encodeURIComponent(this.textHeader + this.param.text)}`
      })
      .then(function (response) {
        return response.json()
      }).then(function (response) {

        if(response.message == "not current version") {
          alert(`バージョンが違うようです。\n更新します。`);
          location.reload()
          postResult = false
          return false
        } else {
          
          console.log('投稿完了', response)

          return response
        }

      })
      .catch((e) => {
        console.error(e.stack, e.message)
      })
    } catch(e) {
      console.error(e)
    }
  }
}