/**
  申し込み用フォーム[MEO]コンポーネント
*/
<template>
  <div id="formMEO" class="form_group">
    <div class="rap1_boder" id="">
      <Section><h1 class="wrap_title pd_7">MEO</h1></Section>
      <Column class="pd3_parts formMEO">
        <label>店舗URL</label>
        <input type="text" class="required form_txt" id="meoURL">
      </Column>
      <Column class="pd3_parts formMEO">
        <label>ワード数</label>
        <input type="number" class="required form_txt" inputmode="numeric" id="meoWordNum">
      </Column>
      <Column class="formMEO">
        <Block class="c-group display_yoko addBox">
          <div class="pd3_parts formMEO width45 pd4_r">
            <label>ワード</label>
            <input type="text" class="addDelText meoWords form_txt" id="meoWord" placeholder="(例)ラーメン おすすめ 通販">
          </div>
          <div class="pd3_parts formMEO width15">
            <label>対象範囲</label>
            <Flex class="display_yoko">
              <input type="text" class="addDelText meoWordAreas form_txt" inputmode="numeric" id="meoWordArea">
              <span>km</span>
            </Flex>
          </div>
          <div class="pd3_parts formMEO width45">
            <label>中心地点住所</label>
            <div class="display_yoko">
              <input type="text" class="addDelText meoWordAdds form_txt" id="meoWordAdd">
            </div>
          </div>
          <Flex>
            <IconAdd name="add" value="＋" class="form_add btn-floating btn-small waves-effect waves-light red lighten-3 mg1 "></IconAdd>
            <IconRemove name="del" value="－" class="form_del btn-floating btn-small waves-effect waves-light blue lighten-3 " id="tel1" ></IconRemove>
          </Flex>
        </Block>
      </Column>
      <Column class="pd3_parts">
        <label>対策期間</label>
        <ul class="c-choices">
          <li class="c-choices__item">
            <label class="c-choice">
              <input type="radio" class="required" name="meo_train" id="meoTrain1" value="1年間">
              <span class="">1年間</span>
            </label>
          </li>
          <li class="c-choices__item">
            <label class="c-choice">
              <input type="radio" class="required" name="meo_train" id="meoTrain2" value="2年間">
              <span class="">2年間</span>
            </label>
          </li>
        </ul>
      </Column>
    </div>
  </div>
</template>


<script>
import { defineComponent } from 'vue'

// コンポーネントを読み込む
import Variables from '../../../molecules/variables.vue';
import Choices from '../../../molecules/choices.vue';
import Box from '../../../molecules/box.vue';
import Section from '../../../molecules/section.vue';
import Column from '../../../molecules/column.vue';
import Group from '../../../molecules/group.vue';
import Flex from '../../../molecules/flex.vue';
import Block from '../../../molecules/block.vue';

import Description from '../../../atoms/description.vue';
import Label from '../../../atoms/label.vue';
import Input from '../../../atoms/input.vue';
import Select from '../../../atoms/select.vue';
import Textarea from '../../../atoms/textarea.vue';
import Button from '../../../atoms/button.vue';
import IconAdd from '../../../atoms/icon-add.vue';
import IconRemove from '../../../atoms/icon-remove.vue';

export default defineComponent({
  name: 'formApplicationMEO',

  components: {
    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,
  },

  methods: {
  },

  mounted() {
  },

  setup(_, context) {
    return {}
  }

})
</script>
