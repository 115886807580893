/**
  グループコンポーネント
*/
<template>
  <div class="c-flex">
    <slot></slot>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({

  });
</script>

<style lang="scss">
.c-flex {
  display: flex;
  align-items: center;
  white-space: nowrap;

  > * {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}
</style>
