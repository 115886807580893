/****************************************
 * 関数定義
*****************************************/
class formFunctions {
  constructor(moment, $, sha256, vueObj) {
    this.moment = moment
    this.$ = $
    this.sha256 = sha256
    this.vueObj = vueObj || {}
    this.officeData = new OfficeDatas()

    this.actionInit($)
    
    new dataEditFunc(this)
  }

  actionInit($) {
    const _this = this;
    
    let trimTimer = {};
    $('input[type="text"]:not(#subjectId), textarea').off('input.trimSpace').on('input.trimSpace', function() {
      if(trimTimer) clearTimeout(trimTimer); 
      trimTimer = setTimeout(() => { $(this).val(_this.trimSpace($(this).val())) }, 500);
    });
  }

  getTalknoteType(group_title) {
    if(group_title.indexOf('demo') !== -1) {
      return 'テスト'
    } else if (group_title.indexOf('アポ獲得報告') !== -1) {
      return 'アポ獲得'
    } else if (group_title.indexOf('商談報告') !== -1) {
      return '商談報告'
    } else if (group_title.indexOf('リスケ') !== -1) {
      return 'リスキャン'
    } else if (group_title.indexOf('申込') !== -1) {
      return '申込'
    }
  }

  getPostID(subject_id, user_id, group_id) {

    let postID = this.sha256(this.moment().format('YYYYMMDDHHmmss') + subject_id + user_id).toString()
    let start = Math.floor(Math.random() * (postID.length - 8))
    postID = postID.substr(start, 8)
    postID = group_id + '_sg-post' + postID

    return postID
  }

  // GoogleカレンダーIDチェック
  checkCalID(obj) {
    let result = {}
    let only_display = ""
    let index_nomal = ""
    const regSaihou = /\[.*再.*\]/
    for(let i = 0; i < obj.length; i++) {
      const isIncludeSaihouCal = this.officeData.checkIncludeSaihouCal(obj[i].cal_id)
      const isRescanCal = this.officeData.checkRescanCal(obj[i].cal_id)

      if(isRescanCal && ! regSaihou.test(obj[i].title)) { //リスキャンカレンダー(再訪以外)
        result.exits_rc = 1
      } else if(!isIncludeSaihouCal && !isRescanCal) {
        result.exits_nomarl = 1
        index_nomal = i
      }

      if(obj[i].title.indexOf('使用不可') !== -1) {
        result.do_not_use = 1
        index_nomal = i
      }
    }

    if((result.exits_rc == 1 && result.exits_nomarl == 1) || result.do_not_use == 1 ) {
      return index_nomal
    } else {
      return 99
    }
  }

  // タイトルフォーマットv1
  v1Title(obj) {
    const $ = this.$, moment = this.moment

    let resultv1 = {}
    let regApName = /\/.*】/

    resultv1.ap_type_name = obj.description.split(/アポ】.*\[/)[0].split('【投稿】')[1].split('【')[1] + 'アポ'
    // ap_type
    let splitedTitle = obj.title.split('///')[0]
    const regApType = /【.*ア\/.*】/

    if(splitedTitle.indexOf('♻') !== -1 || splitedTitle.match(regApType) !== null) {
      resultv1.ap_type = 'アウトレット'
      resultv1.ap_type_name = resultv1.ap_type_name.replace('アポ',resultv1.ap_type+'アポ')
    } else if(splitedTitle.indexOf('自アポ') !== -1) {
      resultv1.ap_type = '自'
      resultv1.confirm_ap = '-'
      resultv1.ap_type_name = resultv1.ap_type_name
    }else {
      resultv1.ap_type = '通常'
    }

    resultv1.visit_area = ''
    resultv1.subject_id = $('#SubjectId').val()
    let spltEle = ""
    if(splitedTitle.indexOf('自アポ') == -1) {
      resultv1.confirm_ap = this.checkConfirm_ap(obj.title)
      spltEle = resultv1.confirm_ap
    } else {
      spltEle = '自アポ'
    }
    resultv1.ap_name = obj.title.split(spltEle)[1].split('】')[0].split('/').slice(-1)[0]
    resultv1.com_name = obj.title.split(resultv1.ap_name+'】')[1].split('///')[0]
    resultv1.mtg_date = this.resDate(obj.starttime, obj.endtime, moment)

    // mtg_type
    let ap_type_name_mtg_type = ""

    const isOsakaApCal = this.officeData.getApCalID("大阪")
    const isTokyoApCal = this.officeData.getApCalID("東京")

    if(splitedTitle.indexOf('対面') !== -1) {
      ap_type_name_mtg_type = resultv1.mtg_type = '対面'
    } else if(splitedTitle.indexOf('来社') !== -1 && obj.cal_id == isOsakaApCal) {
      resultv1.mtg_type = '対面'
      resultv1.visit_area = '関西'
      ap_type_name_mtg_type = resultv1.visit_area + resultv1.mtg_type
    } else if(splitedTitle.indexOf('来社') !== -1 && obj.cal_id == isTokyoApCal) {
      resultv1.mtg_type = '対面'
      resultv1.visit_area = '関東'
      ap_type_name_mtg_type = resultv1.visit_area + resultv1.mtg_type
    } else if(splitedTitle.indexOf('🎦') !== -1) {
      resultv1.mtg_type = '🎦'
      ap_type_name_mtg_type = 'オンライン'
    }

    if(resultv1.ap_type_name.indexOf('関西来社') !== -1 || resultv1.ap_type_name.indexOf('関東来社') !== -1) {
      resultv1.ap_type_name = resultv1.ap_type_name.replace(/関西来社|関東来社/,ap_type_name_mtg_type)
    } else if(resultv1.ap_type_name.indexOf('対面') !== -1) {
      resultv1.ap_type_name = resultv1.ap_type_name.replace('対面',ap_type_name_mtg_type)
    } else if(resultv1.ap_type_name.indexOf('オンライン') !== -1) {
      resultv1.ap_type_name = resultv1.ap_type_name.replace('オンライン',ap_type_name_mtg_type)
    }

    // hook
    if(resultv1.ap_type_name.indexOf('リロ') !== -1) {
      resultv1.hook = 'リロ'
    } else if(resultv1.ap_type_name.indexOf('ウェル') !== -1) {
      resultv1.hook = 'ウェル'
    } else if(resultv1.ap_type_name.indexOf('イツザイ') !== -1) {
      resultv1.hook = 'イツザイ'
    }else {
      resultv1.hook = 'WEB直販'
    }

    // suggestion
    if(resultv1.ap_type_name.indexOf('イツザイ') !== -1) {
      resultv1.suggestion = 'イツザイ'
    } else if(resultv1.ap_type_name.indexOf('EC') !== -1) {
      resultv1.suggestion = 'EC'
    } else if(resultv1.ap_type_name.indexOf('CMS') !== -1) {
      resultv1.suggestion = 'CMS'
    }
    return resultv1
  }

  // タイトルフォーマットv2
  v2Title(obj) {
    const $ = this.$, moment = this.moment

    let resultv2 = {}
    let reg_title = /\[.*\/.*\/.*\]/
    let titleEnd = ""
    if (obj.title.indexOf("///*") !== -1) {
      titleEnd = "///*"
    } else if (obj.title.indexOf("*///") !== -1) {
      titleEnd = "*///"
    }
    let main_ele = obj.title.split(titleEnd)[0].split('】')[0].split('【')[0].match(reg_title)[0] //[-/-][☆/通常/W/CMS/対面] の形式
    let ApName_ComName = obj.title.split(main_ele)[1]; // 【田中】サングローブ株式会社///*
    resultv2.visit_area = resultv2.jiapo=resultv2.outlet=''
    resultv2.mtg_date = this.resDate(obj.starttime, obj.endtime, moment)
    resultv2.ap_name = ApName_ComName.split('】')[0].split('【')[1]
    resultv2.com_name = ApName_ComName.split(resultv2.ap_name+'】')[1].split(titleEnd)[0]
    resultv2.subject_id = $('#SubjectId').val()
    let ele1 = obj.title.split('///')[0].split(/\[.{1}\/.{1}\]/)[1].split('【')[0]
    let reg1 = (/\[|\/|\]/g)
    let splited_ele1 = ele1.split(reg1)
    resultv2.confirm_ap = splited_ele1[1]
    resultv2.ap_type = splited_ele1[2]
    resultv2.suggestion = splited_ele1[4]
    resultv2.hook = splited_ele1[3]
    resultv2.mtg_type = splited_ele1[5].replace('🎦', 'オンライン')

    if(resultv2.mtg_type == '来社東') {
      resultv2.mtg_type = '来社'
      resultv2.visit_area = '関東'
    } else if(resultv2.mtg_type == '来社西') {
      resultv2.mtg_type = '来社'
      resultv2.visit_area = '関西'
    }

    if(resultv2.ap_type == 'ア♻') {
      resultv2.ap_type = resultv2.outlet = 'アウトレット'
    } else if(resultv2.ap_type == '自') {
      resultv2.jiapo ='自'
    }

    if(resultv2.hook == 'R') {
      resultv2.hook = 'リロ'
    } else if(resultv2.hook == 'W') {
      resultv2.hook = 'ウェル'
    }

    resultv2.ap_type_name = resultv2.hook + resultv2.suggestion + resultv2.visit_area + resultv2.mtg_type + resultv2.outlet +resultv2.jiapo+"アポ"

    if(resultv2.hook == "") {
      resultv2.hook  = resultv2.suggestion ; 
    }

    return resultv2
  }

  // 再訪のタイトルフォーマット
  saihou(obj) {
    const $ = this.$

    let resultS = {
      title:"",
      ap_type:"",
      ap_type_name:"",
      com_name: "",
      mtg_date: "",
      mtg_type: "",
      visit_area:"",
      hook: "",
      suggestion: "",
      confirm_ap:"-",
      outlet:""
    }

    resultS.mtg_date = this.resDate(obj.starttime, obj.endtime)
    const reg1 = (/\[.*\]\[.*\]/g)
    const reg2 = /【([^】]+)】/g
    const reg3 = (/\.*\/(打|商|決|打・決)\]/g)
    resultS.ap_type = '再訪'
    resultS.title = obj.title
    resultS.subject_id = $('#SubjectId').val()
    resultS.com_name = obj.title.split(reg3).slice(-1)[0].split('///')[0]
    resultS.mtg_type = obj.title.match(reg1)[0].split('][')[1].split('/')[0].replace('🎦', 'オンライン')
    resultS.ap_type_name = obj.description.match(reg2)[0].replace(/【|】/g,'')

    if(resultS.ap_type_name.indexOf(resultS.mtg_type) == -1 && resultS.mtg_type == 'オンライン') {
      resultS.ap_type_name = resultS.ap_type_name.replace(/対面|来社/,resultS.mtg_type)
    } else if(resultS.ap_type_name.indexOf(resultS.mtg_type) == -1 && resultS.mtg_type == '対面') {
      resultS.ap_type_name = resultS.ap_type_name.replace(/オンライン|来社/,resultS.mtg_type)
    } else if(resultS.ap_type_name.indexOf(resultS.mtg_type) == -1 && resultS.mtg_type == '来社') {
      resultS.ap_type_name = resultS.ap_type_name.replace(/オンライン|対面/,resultS.mtg_type)
    }

    const saihouTokyoCalID = this.officeData.getSaihouCalID("東京")
    const saihouOsakaCalID = this.officeData.getSaihouCalID("大阪")

    if(resultS.mtg_type.indexOf('来社') !== -1  && obj.cal_id == saihouTokyoCalID) {
      resultS.visit_area = '関東'
    } else if(resultS.mtg_type.indexOf('来社') !== -1 && obj.cal_id == saihouOsakaCalID) {
      resultS.visit_area = '関西'
    }

    return resultS
  }

  // 絵文字消す
  delEmoji(Ele) {
    let emoji_reg = new RegExp(/[\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF]/, 'g')
    Ele = Ele.replace(emoji_reg, "")
    return Ele
  }

  // カレンダータイトルリスト生成
  createSubjectList(res) {
    const $ = this.$
    const errorTexts = []

    for(let i = 0; i < res.length; i++) {
      let ele = ""
      let title = res[i].title
      const regSaihou = /\[.*再.*\]/
      const errorText = this.titlePattern(title, regSaihou)
      if (errorText) { errorTexts.push(errorText) }

      const isIncludeSaihouCal = this.officeData.checkIncludeSaihouCal(res[i].cal_id)
      const isRescanCal = this.officeData.checkRescanCal(res[i].cal_id)

      if(title.indexOf('///*') !== -1 || title.indexOf('*///') !== -1) {
        ele = this.v2Title(res[i])
      } else if(isIncludeSaihouCal || (isRescanCal && regSaihou.test(title))) {
        ele = this.saihou(res[i])
      } else if(title.indexOf('///') !== -1) {
        ele = this.v1Title(res[i])
      }

      const ele_input = '<input class="required" type="radio" name="ListEvents" value="' + i + '" data-about=\'{\"company_name\":\"' + ele.com_name + '\",\"cal_name\":\"' + res[i].cal_name + '\",\"mtg_date\":\"' + ele.mtg_date + '\",\"ap_type_name\":\"' + ele.ap_type_name + '\", \"subject_id\":\"' + ele.subject_id +'\", \"ap_name\":\"' + ele.ap_name + '\"}\' data-res=\'{\"cal_id\":\"' + res[i].cal_id + '\",\"event_id\":\"' + res[i].event_id + '\",\"mtg_type\":\"' + ele.mtg_type + '\",\"mtg_add\":\"' + res[i].location + '\",\"hook\":\"' + ele.hook + '\",\"suggestion\":\"' + ele.suggestion + '\",\"ap_type\":\"' + ele.ap_type + '\",\"visit_area\":\"' + ele.visit_area + '\",\"confirm_ap\":\"' + ele.confirm_ap + '\"}\'>'
      const ele_span = '<span>' + title + '</span>'

      $('<label class="pd5_rl events c-choice">' + ele_input + ele_span + '</label>').appendTo("#result_titles")
    }

    const errorMessage = errorTexts.join('<br>=======================================<br>')
    return errorMessage

  }

  titlePattern(title, regSaihou) {
    const pattern = /^([^[\]/【】]*?)\[(.*?\/.*?)\]\[([^[]*?\/){4}[^[]*?\]\[([^[]*?\/){3}[^[]*?]【([^【】]*?)】([^【】]*?)\*\/\/\/.*$/
    const match = title.match(pattern)
    if (!match && !regSaihou.test(title)) {
      const errorText = this.titleMissCheck(title)
      return errorText
    }
    return ""
  }

  titleMissCheck(title) {
    let errorText = "カレンダータイトルが崩れています！ 上長の方にご確認ください"

    if (title.indexOf('*///') == -1) {
      if (title.indexOf('*//') !== -1) {
        errorText = errorText + '<br>タイトル末尾の / が足りません！ 正しくは*///です'
      } else {
        errorText = errorText + '<br>タイトル末尾の */// がありません！'
      }
    }

    const correctCount = { //タイトルルール変更時には見直し
      "[": 3,
      "]": 3,
      "【": 1,
      "】": 1
    }

    for (const bracket in correctCount) {
      const regex = new RegExp(`\\${bracket}`, 'g')
      const matches = title.match(regex)
      const count = matches ? matches.length : 0
  
      if (count !== correctCount[bracket]) {
        errorText = errorText + `<br>'${bracket}' の数が正しくありません。本来の数: ${correctCount[bracket]}, 実際の数: ${count}`
      }
    }

    return errorText
  }

  // 日付生成
  resDate(st, et) {
    const moment = this.moment

    let date,day,start,end,mtg_date
    let WeekChars = [ "日", "月", "火", "水", "木", "金", "土" ]
    day = WeekChars[new Date(st.replace(/-/g,"/")).getDay()]
    let format = 'YYYY-MM-DD HH:mm'
    let startTime = moment(st).format(format)
    let endTime = moment(et).format(format)
    date = startTime.split(' ')[0]
    start = startTime.split(' ')[1]
    end = endTime.split(' ')[1]
    mtg_date = date + "（" + day + "）" + start + "〜" + end
    return mtg_date
  }

  // 時間 - 開始・終了時間
  startEndTime(startIdName, endIdName) {
    const $ = this.$
    const moment = this.moment

    $('#' + endIdName).timepicker({
      'timeFormat': 'H:i',
      'step':'15',
      'minTime':'9:00',
      'maxTime':'21:30'
    })

    $('#' + startIdName).timepicker({
      'timeFormat': 'H:i',
      'step':'15',
      'minTime':'8:00',
      'maxTime':'20:00'
    }).on('changeTime', function() {
      let timepicker_start = $('#' + startIdName).val()
      const addedTime = moment('2022-04-01 ' + timepicker_start).add(1, 'h').format('HH:mm')

      $('#' + endIdName).timepicker({
        'timeFormat': 'H:i',
        'step': '15',
        'minTime': addedTime,
        'maxTime': '21:30'
      })  
    })
  }

  // 変換 - 全角数字 => 半角数字
  hankakusuji(str) {
    return str.replace(/[０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 65248)
    })
  }

  // 変換 - 全角英字 => 半角英字
  hankaku2Zenkaku(str) {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
    })
  }

  /*
  * コンマを削除する関数
  * @params {Str} コンマあり文字列数字(1,000)
  * @return {Num} 半角英字(1000)
  **/
  _removeComma(StrNumber) {
    const removed = StrNumber.replace(/,/g, '')
    return parseInt(removed, 10)
  }

  // コンマ削除
  removeComma(number) {
    const removed = number.replace(/,/g, '')
    return parseInt(removed, 10)
  }

  /*
  * 変換 - コンマあり数字string型 => コンマありNumber型
  * @params {Str} コンマありStr型(1,000)
  * @return {Num} コンマありNumber型(1,000)
  **/
  commaStr2Num(str) {
    str = this._removeComma(str)
    str = Number(str).toLocaleString()
    return str
  }

    /*
  * 変換 - コンマ数字にする関数
  * @params {Obj} コンマあり数字にするセレクタ
  **/
  updateTextView(_obj){
    let num = this.getNumber(_obj.val());
    if(num == 0) {
      _obj.val('');
    } else {
      _obj.val(num.toLocaleString());
    }
  }

  /*
  * 変換 - 文字列両端の空白文字を削除　2つ以上続くスペースを1つだけにして返す
  * @params {elm} 該当するエレメント 
  **/
  trimSpace(val) {
    if(val) {
      return (val + '').trim().replace(/[ 　]{2,}/gm, ' ');
    } 
  }

  /*
  * data-*属性を取得する関数
  * @param {obj} 取得したいdata-*属性のDOM
  * @param {str} * 部分の名前
  * @return {ary} data-*の値取得した配列
  **/
  getDataAttrAry(selecter, AttrName) {
    const $ = this.$;
    let ary = []
    selecter.each(function(i, ele) {
      ary.push($(ele).data(AttrName))
    })
    return ary
  }


  getNumber(_str) {
    let arr = _str.split('');
    let out = new Array();
    for(let cnt = 0; cnt < arr.length; cnt++){
      if(isNaN(arr[cnt]) == false){
        out.push(arr[cnt]);
      }
    }
    return Number(out.join(''));
  }

  /*
  * 変換 - 「"（半角ダブルクオート）」→「”（全角ダブルクオート）」
  * 変換 - 「,（半角コンマ）」→「，（全角コンマ）」
  * @params {Str} 変換前Str型
  * @return {Str} 「”（全角ダブルクオート）」変換後Str型
  **/
  textEscape(strObj) {
    let type = typeof strObj == 'object' ? this.classOf(strObj) : 'string'

    switch (type) {
      case 'hash':
        
        for(let key in strObj) {
          if(typeof strObj[key] == 'number') continue
          strObj[key] = strObj[key].replace(/'/g, "’").replace(/"/g, "”")
        }
        return strObj
    
      case 'array':
  
        for(let i = 0; i < strObj.length; i++) {
          if(typeof strObj[i] == 'number') continue
          strObj[i] = (strObj[i] + '').replace(/'/g, "’").replace(/"/g, "”")
        }
        return strObj
  
      case 'string':
        return strObj.replace(/'/g, "’").replace(/"/g, "”")
    }
  }

  /*
  * 判定 - Arrayかhashかどうかを判定する関数
  * @params {Obj} 判定するオブジェクト
  * @return {Str} hash or array が返ってくる
  **/
  classOf(obj) {

    if( (typeof obj) == "object" ) {

        if(obj.length !== undefined) return "array"

        else{ for(t in obj){
            if(obj[t] !== undefined)return "hash"
            else return "object"
        }}

    }else {
      return (typeof obj) 
    }

  }

  // アポ記号チェック
  checkConfirm_ap(title) {
    let splited_title = title.split('///')[0]

    if(splited_title.indexOf('前不') !== -1) {
      return '＊'
    } else if(splited_title.indexOf('★') !== -1) {
      return '★'
    } else if(splited_title.indexOf('☆') !== -1) {
      return '☆'
    } else if(splited_title.indexOf('彡') !== -1) {
      return '彡'
    }
  }

  // 必須項目設定
  checkRequired() {
    const $ = this.$

    let key = ""
    let requiredArray = {}
    const required_dom = $('.required')
    const bg_color = '#ffcdd2'
    const color = '#212121'

    $('#btn').addClass('disabled').removeClass('pulse')  
    $('.required').css({
      'background-color': bg_color,
      'color': color
    })
    // [形式チェック：商談住所]
    let format_flags = ""

    if($('#MtgAdd').prop('required')) {
      const trigger = $('[name="pref_name"]')
      const label_class = '.error_msg_pref'
      const label_tag = '<label class="error_msg_pref">※ 都道府県名から入力してください</label>'
      const insertbfr = $('[name="pref_name"]')
      format_flags = this.format_check_pref(trigger, label_class, label_tag, insertbfr)
    }

    for(let num = 0; num < required_dom.length; num++) {

      if(required_dom[num].type == 'radio' || required_dom[num].type == 'checkbox') {
        key = required_dom[num].name
        requiredArray[key] = 0
        const name_dom = $('[name="' + key + '"]')

        name_dom.next().css({
          'background-color': bg_color,
          'color': color
        })

        if(this.checkedVal(name_dom) != '') {
          requiredArray[key] = 1
          name_dom.next().css({'background-color': 'transparent'})
        }

      } else if(required_dom[num].classList.contains('chip')) {
        key = "ResuCanReasonCate"
        requiredArray[key] = 0
        const name_dom = $('[name=' + key + ']')
        name_dom.css({
          'background-color': bg_color,
          'color': color
        })

        if(this.checked_chip($('div.teal.lighten-2')) != '') {
          requiredArray[key] = 1
          name_dom.css({'background-color': '#e0e0e0'})
        }

      } else {
        key = required_dom[num].id
        requiredArray[key] = 0

        if(required_dom[num].value != '') {
          requiredArray[key] = 1
          required_dom[num].style.backgroundColor = '#ffffff'
        }
      }
    }

    return requiredArray
  }

  //必須項目を設定を外す関数(param.id)
  removeRequiredOp(idName) {
    const $ = this.$
    $(`#${idName}`).removeClass("required")
  }

  // 期間チェック
  duration(fromT, toT) {
    const $ = this.$
    const moment = this.moment
    const dateFrom = moment('2020-04-01 ' + fromT + ':00') //開始時間
    const dateTo = moment('2020-04-01 ' + toT + ':00') //終了時間
    const diff = dateTo.diff(dateFrom, 'minutes')
    
    if(Math.sign(diff) == -1) {
      alert('[商談時間] 開始日時は終了日時より前にしてください。')
      $('#ChangeStart').timepicker({
        'timeFormat': 'H:i',
        'step':'15',
        'minTime':'8:00',
        'maxTime':'20:00'
      })
      return 0
    } else {
      return diff
    }
  }

  check(checkbox_op) {
    let input_txt = []
    for (let i = 0; i < checkbox_op.length; i++) {

      if( checkbox_op[i].checked ) {
        input_txt.push(checkbox_op[i].value)
      }

    }
    return input_txt.join('/')
  }

  checkRemove(checkbox_op) {
    for(let i = 0; i < checkbox_op.length; i++) {
      checkbox_op[i].checked = false
    }
  }

  checkOut(check_names) {
    for(let i = 0; i < check_names.length; i++) {
      check_names[i].checked = false
    }
  }

  // 値取得 - チェックボックス
  checkedVal(checkbox_op) {
    let input_txt = ""
    
    for (let i = 0; i < checkbox_op.length; i++) {
    
      if( checkbox_op[i].checked ) input_txt += checkbox_op[i].value+"/"
    
    }
    return input_txt.slice(0,-1)
  }

  // 値取得 - inputタグ
  getInputData(obj) {
    let input_txt = []
    for (let i = 0; i < obj.length; i++) {
    
      input_txt.push(obj[i].value)

    }
    return input_txt.join('/')
  }

  format_check(trigger, label_class, label_tag, insertbfr) {
    const $ = this.$

    if($(label_class).length > 0) {
      $(label_class).remove()
    }
  
    let format_flags = "" //初期化
  
    for(let i = 0; i < trigger.length; i++) {
      if(trigger.get(i).validationMessage == "") {
        format_flags += "1"
      } else {
        format_flags += "0"
        $(label_tag).insertBefore(insertbfr[i])
      }
    }
  
    return format_flags
  }
  

  format_check_pref(trigger,label_class,label_tag,insertbfr) {
    const $ = this.$

    let format_flag = "", format_flags = ""

    if(trigger.prev('label').length > 0) {
      trigger.prev('label').remove()
    }

    for(let i = 0; i < trigger.length; i++) { 
      if(trigger.get(i).validationMessage == "" && (trigger[i].value.startsWith("北海道") != -1 || trigger[i].value.startsWith("東京都") != -1 || trigger[i].value.startsWith("大阪府") != -1 || trigger[i].value.startsWith("京都府") != -1)) {
        format_flag += "1"
      } else if(trigger.get(i).validationMessage == "") {
        let pref_ken = trigger[i].value.split('県')
        let pref_list = ["青森", "岩手", "宮城", "秋田", "山形", "福島", "茨城", "栃木", "群馬", "埼玉", "千葉", "神奈川", "新潟", "富山", "石川", "福井", "山梨", "長野", "岐阜", "静岡", "愛知", "三重", "滋賀", "兵庫", "奈良", "和歌山", "鳥取", "島根", "岡山", "広島", "山口", "徳島", "香川", "愛媛", "高知", "福岡", "佐賀", "長崎", "熊本", "大分", "宮崎", "鹿児島", "沖縄"]

        if(pref_list.find((v) => v === pref_ken[0]) == undefined) {
          format_flag += "0"
          $(label_tag).insertBefore(insertbfr[i])
        } else {
          format_flag += "1"
        }

      } else {
        format_flag += "0"
        $(label_tag).insertBefore(insertbfr[i])
      }
    }

    if(format_flag.indexOf('0') == -1) {
      format_flags += "1"
    } else {
      format_flags += "0"
    }

    return format_flags
  }

  format_check_subjectId(trigger, label_tag, insertbfr, format_flags) {
    const $ = this.$

    if(trigger.val() == "") return false

    let format_flag = ""

    if(trigger.prev('label').length > 0) {
      trigger.prev('label').remove()
    }
    
    for(let i = 0; i < trigger.length; i++) {
      let error_msg = $('[name="SubjectId"]')[i].validationMessage 

      if(error_msg == '') {
        format_flag += '1'
      }else{
        format_flag += '0'
        trigger[i].value = ''
        $(label_tag).insertBefore(insertbfr)
      }
    }

// 1:OK
// 0:NG
    if(format_flag.indexOf('0') !== -1) {
      format_flags += "0" 
    }else{
      format_flags += "1" 
    }
    return format_flags
  }

  addOption(obj, allOps) {
    const $ = this.$

    let nowOp = []
    let addOp = []
  
    for(let i = 0; i < obj.length; i++) {
      nowOp.push(obj[i].value)
    }
  
    if(allOps[0] !== null && typeof allOps[0] === 'object') {
      addOp = allOps.filter(allOp => nowOp.indexOf(allOp.value) == -1)
    } else {
      addOp = allOps.filter(allOp => nowOp.indexOf(allOp) == -1)
    }

  
    if(addOp.length >= 1) {
      let addEle = ""
      for (let i = 0; i < addOp.length; i++) {
        
        let key = addOp[i].key? ` data-id="${addOp[i].key}"`: ''
        let value = addOp[i].value? addOp[i].value: addOp[i]

        addEle += '<option class="select_status" value="' + value + '"' + key +'>' + value + '</option>'
      }
      $(addEle).appendTo("#Status")
    }
  }

  addDataOption(obj, allOps) {
    const $ = this.$

    let nowOp = []
    let addOp = {}
    
    for (let i = 0; i < obj.length; i++) {
      nowOp.push(obj[i].value)
    }
    for(let key in allOps) {
      let flagNoExits = ""
      for(let num = 0; num < nowOp.length; num++) {
        if(key == nowOp[num]) {
          flagNoExits = 0
          break
        } else {
          flagNoExits = 1
        }
      }

      if(flagNoExits == 1) {
        addOp[key] = allOps[key]
      }

    }

    if(Object.keys(addOp).length >= 1) {
      let addEle = ""
      for(let key in addOp) {
        addEle += '<option name="MtgType" value="' + key + '" data-type="' + addOp[key] + '">' + key + '</option>'
      }
      $(addEle).appendTo("#MtgType")
    }
  }

  checked_chip(checked_dom) {
    let result = ""
    for(let i = 0; i < checked_dom.length; i++) {
      result += checked_dom[i].dataset.neck + "/"
    }
    return result.slice(0, -1)
  }

  checked_neckCate() {
    const $ = this.$

    for(let i = 0; i < $("[id^='neck_']").length; i++ ) {
      if($("[id^='neck_']")[i].classList.contains('down')) {
        $('[name="ResuCanReasonCate"]').removeClass('required')
        $('[name="ResuCanReasonCate"]').css('background-color','')

        return 0
      }
    }

    return 1
  }

  /*
    クリア系関数
  */

  clearInput(clear_array) {
    for(let i = 0; i < clear_array.length; i++) {
      clear_array[i].value = ""
    }
  }

  clearSelect(clear_array) {
    const $ = this.$

    if(clear_array instanceof $) {
      for(let i = 0; i < clear_array.length; i++) {
        clear_array.eq(i).prop('selectedIndex', 0)
      }
    } else {
      for(let i = 0; i < clear_array.length; i++) {
        clear_array[i].selectedIndex = 0
      }
    }
  }
  
  clearFollower() {
    const $ = this.$

    $('#feild_FollowerName').slideUp(100)
    $('#searchFollowerName').val('')
    $('#comment').text('苗字を入力→エンターキーで検索')
    this.checkRemove($('[name="ListFollower"]'))
    $('.ListFollower').remove() 
  }

  // 同行者リストの表示
  createFollowerList(res) {
    const $ = this.$

    for(let i = 0; i < res.length; i++) {
      let user_name = ""
      let user_emoji = typeof res[i].user_emoji == 'String'? res[i].user_emoji: ''

      /* 20230427現在、first_nameとlast_nameが逆になっているのでいったんこの処理のなかだけで順序変更
      if(res[i].business_name) {
        user_name = res[i].business_name + "(" + res[i].last_name + ")" + res[i].first_name + user_emoji
      } else {
          user_name = res[i].last_name + res[i].first_name + user_emoji
      }*/

      if(res[i].business_name && res[i].business_name != "None") {
        user_name = res[i].business_name + "(" + res[i].first_name + ")" + res[i].last_name + user_emoji
      } else {
          user_name = res[i].first_name + res[i].last_name + user_emoji
      }
      
      let ele_input = '<input class="required" type="radio" name="ListFollower" value="' + user_name + '" data-user=\'{\"user_id\":\"' + res[i].id + '\",\"first_name\":\"' + res[i].first_name + '\",\"last_name\":\"' + res[i].last_name + '\",\"bn\":\"' + res[i].business_name + '\",\"mail\":\"' + res[i].mail + '\",\"user_emoji\":\"' + user_emoji + '\"}\'>'
      
      let ele_span = '<span>' + user_name + '</span>'
      
      $('<label class="pd5_rl ListFollower c-choice">' + ele_input + ele_span + '</label>').appendTo("#feild_FollowerName")
    }
  }

  clearFollower() {
    const $ = this.$

    $('#feild_FollowerName').slideUp(100)
    $('#searchFollowerName').val('')
    this.checkRemove($('[name="ListFollower"]'))
    $('.ListFollower').remove() 
  }

  clearAllOption() {
    const $ = this.$

    this.clearInput($('#form_ap').find('textarea, input[type="text"], input[type="tel"], input[type="url"], input[type="date"], input[type="Number"]'))
    this.clearSelect($('#form_ap').find('select'))
    this.checkOut($('#form_ap').find('input[type="radio"], input[type="checkbox"]'))

    this.clearFollower()
    $('#feild_hook, #feild_suggestion, #feild_FollowerName, #visit_area, .field_taimen, #wrap2, .autoG, .gotAP, .form_wrap').slideUp(100)
    $('#MtgStart, #MtgEnd').timepicker({ //時間初期化
      'timeFormat': 'H:i',
      'step':'15',
      'minTime':'8:00',
      'maxTime':'20:00'
    })
  }

  clearAry(obj) {
    for(let i in obj) {
      obj[i] = ''
    }
  }

  clearChips() {
    const $ = this.$
    
    $('.chip').removeClass('grey teal lighten-2 -semi-active -active')
    $('[id^="neck_"]').hide(400)
    $('[id^="neck_"]').removeClass("down")
    $('[id^="neck_"]').addClass("up")
  }

  searchPOSTURL(url, postID) {
    let searchURL = url.match(/https?:\/\/[^\/]+\/[^\/]+\//)[0]
    searchURL = `${searchURL}search?keyword=${postID}`
    return searchURL
  }


  //エラー投稿
  errorPost(subject, error_message) {
    if(!userName) return

    try {
      fetch('https://call-in.jp/js/error_post.php', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        },
        body: JSON.stringify({
          userName: userName,
          subject: subject,
          message: `\n\n${error_message}`
        })
      }).then(data => {
        console.log('エラーを通知しました。')
      }).catch(data => {
        console.log('エラーを通知できませんでした。', data)
      })
    } catch(e) {
      console.log(e)
    }
  }
}

import dataEditFunc from './dataEditFunction/dataEditFunction.class'
import OfficeDatas  from "./officeData"

export default {
  formFunctions
}

console.log('load formFunctions.class.js')