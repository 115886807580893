/*
  受注前回答フォーム用のデータ取得
*/

import formDataStore from "../../formDataStore"
import RequestBody from "../../requestBody.class"

//シートのダミーデータ
import sheetData from "./dummySheetData"

export default class {
  constructor(obj) {
    this.formTypeForRequestBody = 'juchuumaeKaitou'

    this.parentObj = obj
    this.$ = this.parentObj.parentObj.$
    this.postID = obj.postID
    this.subjectsID = obj.subjectsID
    this.params = obj.params
    this.abortController = new AbortController()
    this.isProd = this.parentObj.isProd
    this.demo = this.isProd ? "" : "Demo"
    this.key = formDataStore.state.apis[`loginKey${this.demo}`]
    
    // フォーム情報
    this.thisFormParams = this.parentObj.parentObj.vueObj
    .thisFormParams
    //this.groupURL = this.thisFormParams[1] // talknoteグループURL
    //this.groupID = this.groupURL.split('feed/')[1].split('/')[0] // 

    // 各種API
    this.spreadsheetInsertApi = formDataStore.state.apis[`spreadsheet${this.demo}`]
    this.spreadsheetUpdateApi = formDataStore.state.apis[`spreadsheet${this.demo}`].replace('insert', 'update')
    this.spreadsheetGetApi = formDataStore.state.apis[`spreadsheet${this.demo}`].replace('insert', 'get')
    this.DBUpdateApi = formDataStore.state.apis[`dbUpdate${this.demo}`]

    this.requestFunc = new RequestBody.requestBody()
    this.nowTime = this.requestFunc.nowTime()
    this.createdAt = this.requestFunc.nowTime('created_at')

    // スプレッドシート用
    this.spreadData = this.requestFunc.setSpreadData(this.formTypeForRequestBody, this.isProd)
    this.spreadData.id = this.spreadData.spreadsheetID
    delete this.spreadData.spreadsheetID
    delete this.spreadData.is_prod
    
  }

  /**
   * データ取得
   *   
   */
  async getData() {

    // シートから取得
    await this.getSheetData()

    console.log(this.insertSheetData)

    if(Object.keys(this.insertSheetData).length == 0) return false

    return true

  }

   /**
   * 受注前回答で編集対象のデータをスプレッドシートから取得 
   */
  async getSheetData() {   

    this.insertSheetData = {}
/*
    this.insertSheetData = sheetData['455639_tn-post-ee1fa76f'][0]

    return this.insertSheetData
*/
    this.getSheetDataQuery = {
      "spread": this.spreadData,
      "target_lines": [
        {"column": '投稿ID', "value": this.postID},
        {"column": '削除日時', "value": ''},
      ],
    }
 
    await fetch(this.spreadsheetGetApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.getSheetDataQuery)
    })
    .then((res) => res.json())
    .then((res) => {
      if(res.length > 0) {
        this.insertSheetDataTmp = res[0]
        console.log('シートデータ取得成功', this.insertSheetDataTmp )

        for(let key in this.insertSheetDataTmp) {
          this.insertSheetData[key] = {"id": "", "val": this.insertSheetDataTmp[key]}
        }
      } else {
        console.log('該当するシートデータがありません')
        alert('該当するシートデータがありません\nリロードします')
        //location.reload()
        return false
      }
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

    return this.insertSheetData
  }

  
   /**
   * フォームにデータ適用 
   */
  applyDataToForm() {
    const $ = this.$

    // モデル
    this.editableItems = this.parentObj.parentObj.vueObj.editableItems
    
    const notEdit = document.querySelectorAll('.js-notEditable') 
    notEdit.forEach((elm) => {
      elm.style.display = 'none'
    })

    // 案件ID
    this.subjectsID = this.insertSheetData['案件ID'].val
    this.parentObj.parentObj.addSubjectsIDBox(this.subjectsID)
    this.editableItems.subjectID = this.subjectsID
    
    // 受注形態
    document.querySelector(`[name="orders_type"][value="${this.insertSheetData['回答種別'].val}"]`).click()

    // 次回アクション日
    let nextActionDate = this.insertSheetData['次回アクション日'].val
    if(nextActionDate) {
      nextActionDate = nextActionDate.match(/[0-9]{4}.+?[0-9]{2}.+?[0-9]{2}/)[0]
      this.editableItems.next_action_date = nextActionDate.split('/').join('-')
    }

    // 開始時間
    this.editableItems.next_action_time = this.insertSheetData['開始時間'].val
  }


  /**
   * クエリ構築
   */
  buildQuery() {
    /*DB*/
    try {
      // 案件ID
      let editedSubjectID = document.getElementById('editSubjectsID')

      editedSubjectID = editedSubjectID.value
    
      let WeekChars = [ "日", "月", "火", "水", "木", "金", "土" ]
   
      // シート用
      this.insertSheetData['投稿日時'].val = this.createdAt

      this.insertSheetData['案件ID'].val = editedSubjectID
     
      this.insertSheetData['回答種別'].val = this.editableItems.orders_type

      let nextActionTimeValue = document.getElementById('next_action_time_tag').value
      if(this.editableItems.orders_type == '失注') {
        this.editableItems.next_action_date = ''
        nextActionTimeValue = ''
      } 

      // 曜日追加
      let nextActionDate = ''
      let next_action_date_db = ''
      if(this.editableItems.next_action_date) {
        nextActionDate = this.editableItems.next_action_date.split('-').join('/') + "（" + WeekChars[new Date(this.editableItems.next_action_date).getDay()]+"）"
        next_action_date_db = this.editableItems.next_action_date.split("（")[0].replace("/", "-").replace("/", "-")
      }
      this.insertSheetData['次回アクション日'].val = nextActionDate

      this.insertSheetData['開始時間'].val = nextActionTimeValue
      
      {
        let calText = this.insertSheetData['全文'].val

        calText = calText.replace(/\[[^\]]+\]/, `[${formDataStore.state.version}]`)

        calText = calText.replace(/案件ID：[^\\\n]+/, `案件ID：${this.insertSheetData['案件ID'].val}`)

        calText = calText.replace(/回答種別：[^\\\n]+/, `回答種別：${this.insertSheetData['回答種別'].val}`)

        calText = calText.replace(/次回アクション日：[^\\\n]+/, `次回アクション日：${this.insertSheetData['次回アクション日'].val}`)

        this.insertSheetData['全文'].val = calText
      }

      // シート格納用にクエリ生成
      const sheetData = this.requestFunc.createBody(this.formTypeForRequestBody, this.isProd, [this.insertSheetData]) 

      // DB用
      let queryObj = [
        {
          "table_name": "order_pre_responses",
          "non_sub_query": { 
            "set": {
              "subjects_id": editedSubjectID,
              "next_action_date": next_action_date_db ? next_action_date_db : '',
              "started_at": nextActionTimeValue ? nextActionTimeValue : ''
            },
            "where": {
              "post_id": this.postID
            }
          },
          "sub_query": {
            "set": {
            "responses_type": "SELECT id FROM order_pre_responses_statuses WHERE name = '" + this.editableItems.orders_type + "'"
            }
          },
          "support_data": this.params.support_data,
          "authC": this.params.authC
        }
      ]

      this.query = {
        toDBUpdate: queryObj,
        toSheetUpdate: {
          'spread': sheetData.spread,
          'target_lines': [
            {"column": "投稿ID", "value": this.postID}
          ],
          "update_cells": [
            {"column": "削除日時", "value": this.createdAt},
          ]
        },
        toSheetInsert: sheetData 
  
      }
  console.log(this.query)
      return this.query
    } catch(e) {
      alert('クエリ構築に失敗しました')
      console.log('クエリ構築に失敗しました')
      console.error(e)
    }
  }


    /**
   * 論理削除用クエリ構築
   */
  async buildDeleteQuery() {
   
    // スプレッドシート用
    this.delSheetQuery = {
      'spread': this.spreadData,
      'target_lines': [
        {"column": "投稿ID", "value": this.postID},
        {"column": "削除日時", "value": ''},
      ],
      "update_cells": [
        {"column": "削除日時", "value": this.createdAt},
      ]
    }

    this.dbDeleteQueryObj = [
      {
        "table_name": "order_pre_responses",
        "non_sub_query": { 
          "set": {
            "deleted_at": this.createdAt,
          },
          "where": {
            "post_id": this.postID
          }
        },
        "sub_query": {
        },
        "support_data": this.params.support_data,
        "authC": this.params.authC
      }
    ]

  }

  /*
    DBをアップデート
  */
  async updateDB() {
    return fetch(this.DBUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toDBUpdate)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'DBアップデート失敗 Discordメッセージ確認'
      }

      console.log('DBアップデート成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }

  /*
    DBに挿入
  */
  async insertDB() {
    // DBなし
    return true
  }

  /*
    シートをアップデート
  */
  async updateSheet() {
    if(this.query.toSheetUpdate.length == 0) return true

    return fetch(this.spreadsheetUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toSheetUpdate)
    })
    .then((res) => res.text())
    .then((res) => {

      if(res - 0 == 99) {
        throw 'シート更新失敗 Discordメッセージ確認'
      }

      console.log('シート更新成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

  }

  /*
    シートに挿入
  */
  async insertSheet() {
    if(this.query.toSheetInsert.length == 0) return true

    return fetch(this.spreadsheetInsertApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.query.toSheetInsert)
    })
    .then((res) => res.text())
    .then((res) => {

      if(res - 0 == 99) {
        throw 'シート追加失敗 Discordメッセージ確認'
      }

      console.log('シート追加成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })

  }

  /*
    DBのデータを論理削除
  */
  async deleteDBData() {
    return fetch(this.DBUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.dbDeleteQueryObj)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'DBデータ削除日時セット失敗 Discordメッセージ確認'
      }

      console.log('DBデータ削除日時セット成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }

  /*
    シートのデータを論理削除
  */
  async deleteSheetData() {
    return fetch(this.spreadsheetUpdateApi, { //APIを使ってDBへ反映
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(this.delSheetQuery)
    })
    .then((res) => res.text())
    .then((res) => {
      if(res - 0 == 99) {
        throw 'シートデータ削除日時セット失敗 Discordメッセージ確認'
      }

      console.log('シートデータ削除日時セット成功', res)
      return true
    }).catch((e) => {
      console.error(e)
      return false
    })
  }
}