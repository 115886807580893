/*****
転送チャンネルへ投稿するクラス
******/

export default class {
  constructor(param) {

    this.param = param

    const mention = param.mention? `${param.mention.replace('\n', '')}さんからの投稿です。\n`: ''
    const messageURL = `投稿URL：${param.messageURL}\n\n`
    this.text = mention + messageURL + param.text

    this.channelID = param.channelID
  }
  
  post() {
    if(!this.channelID) {
      console.error('転送先のchannel IDがありません')
      return false
    }  

    try {
      fetch(this.param.slackChannel.postURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `token=${encodeURIComponent(this.param.slackChannel.token)}&channel=${encodeURIComponent(this.channelID)}&username=${encodeURIComponent(this.param.postUser)}&text=${encodeURIComponent(this.text)}&icon_url=${this.param.slackChannel.icon_url}`
      })
      .then(function (response) {
        return response.json()
      }).then(function (response) {

        if(response.message == "not current version") {
          alert(`バージョンが違うようです。\n更新します。`);
          location.reload()
          postResult = false
          return false
        } else {
          
          console.log('転送投稿完了', response)

          return response
        }

      })
      .catch((e) => {
        console.error(e.stack, e.message)
      })
    } catch(e) {
      console.error(e)
    }
  }
}