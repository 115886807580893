/**
  リンクコンポーネント
*/
<template>
  <a class="c-link">
    <slot></slot>
  </a>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({

  });
</script>

<style lang="scss">
.c-link {
  color: $color-link;
  background: none;
  text-decoration: underline;

  @include is-hover {
    text-decoration: none;
  }
}
</style>
