/**
  増減コンポーネント
*/
<template>
  <ul class="c-variables">
    <li class="c-variables__item">
      <slot></slot>
      <div class="c-variables__item-controls">
        <IconAdd></IconAdd>
        <IconRemove></IconRemove>
      </div>
    </li>
  </ul>
</template>

<script>
  import { defineComponent } from 'vue';

  // コンポーネントを読み込む
  import IconAdd from '../atoms/icon-add.vue';
  import IconRemove from '../atoms/icon-remove.vue';

  export default defineComponent({
    components: {
      IconAdd,
      IconRemove,
    }
  });
</script>

<style lang="scss">
.c-variables {
  &__item {
    position: relative;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 12px;
      
      & + .c-info {
        margin-top: -8px;
      }
    }

    > * {
      margin-right: 8px;
    }

    &-controls {
      display: flex;
      align-items: center;
      margin-right: 0;

      > * {
        margin-left: 8px;
      }
    }
  }
}
</style>
