/**
  申し込み用フォーム[SEO]コンポーネント
*/
<template>
  <div id="formSEO" class="form_group">
    <div class="rap1_boder" id="">
      <Section><h1 class="wrap_title pd_7">SEO</h1></Section>
      <Column class="pd3_parts formSEO">
        <label>SEO対象URL</label>
        <input type="text" class="required form_txt" id="seoURL">
      </Column>
      <Column class="pd3_parts formSEO">
        <label>ワード数</label>
        <input type="number" class="required form_txt" inputmode="numeric" id="seoWordNum">
      </Column>

      <Column class="pd3_parts formSEO">
        <Block class="words addBox">
          <label>ワード</label>
          <Flex class="display_yoko defaltOp">
            <input type="text" class="addDelText seoWords form_txt" id="seoWord" placeholder="(例)ラーメン おすすめ 通販">
            <IconAdd :tag="'a'" name="add" value="＋" class="form_add btn-floating btn-small waves-effect waves-light red lighten-3 mg1 "></IconAdd>
            <IconRemove :tag="'a'" name="del" value="－" class="form_del btn-floating btn-small waves-effect waves-light blue lighten-3 "></IconRemove>
          </Flex>
        </Block>
      </Column>

      <Column class="pd3_parts">
        <label>対策期間</label>
        <ul class="c-choices">
          <li class="c-choices__item">
            <label class="c-choice">
              <input type="radio" class="required" name="seo_train" id="seoTrain1" value="1年間">
              <span class="">1年間</span>
            </label>
          </li>
          <li class="c-choices__item">
            <label class="c-choice">
              <input type="radio" class="required" name="seo_train" id="seoTrain2" value="2年間">
              <span class="">2年間</span>
            </label>
          </li>
        </ul>
      </Column>
    </div>
  </div>
</template>


<script>
import { defineComponent } from 'vue'

// コンポーネントを読み込む
import Variables from '../../../molecules/variables.vue';
import Choices from '../../../molecules/choices.vue';
import Box from '../../../molecules/box.vue';
import Section from '../../../molecules/section.vue';
import Column from '../../../molecules/column.vue';
import Group from '../../../molecules/group.vue';
import Flex from '../../../molecules/flex.vue';
import Block from '../../../molecules/block.vue';

import Description from '../../../atoms/description.vue';
import Label from '../../../atoms/label.vue';
import Input from '../../../atoms/input.vue';
import Select from '../../../atoms/select.vue';
import Textarea from '../../../atoms/textarea.vue';
import Button from '../../../atoms/button.vue';
import IconAdd from '../../../atoms/icon-add.vue';
import IconRemove from '../../../atoms/icon-remove.vue';

export default defineComponent({
  name: 'formApplicationSEO',

  components: {
    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,
  },

  methods: {
  },

  mounted() {
  },

  setup(_, context) {
    return {}
  }

})
</script>
