/*
* ぽちボタンクリック後の処理
*/ 

class afrPostMEO {
  constructor(that) {
    this.that = that
    this.formName = "MEO"
    this.that.$('.search_form_wrap').show()
  }

  returnFormName() {
    return this.formName
  }

  getInputData() {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func

    let $meoWords = $('.meoWords')
    let $meoAreas = $('.meoWordAreas')
    let $meoAdds = $('.meoWordAdds')

    this.meo_words = ''
    this.meo_areas = ''
    this.meo_address = ''
    this.meo_words_ary = []

    for (let i = 0; i < $meoWords.length; i++) {
      let word = $meoWords.eq(i).val()
      let area = $meoAreas.eq(i).val()
      let address = $meoAdds.eq(i).val()

      if(word !== "" && area !== "" && address !== "") {
        this.meo_words += '\n' + '（' + (i+1) + '）' + word
        this.meo_areas += '\n' + '（' + (i+1) + '）' + area + '㎞'
        this.meo_address += '\n' + '（' + (i+1) + '）' + address
        this.meo_words_ary[i] = {
          word: func.textEscape(word),
          km: Number(area),
          address: func.textEscape(address)
        }
      }
    }

    this.inputMeoWord =
    `ワード：${func.textEscape(this.meo_words)}
対象範囲：${this.meo_areas}
中心地点住所：${func.textEscape(this.meo_address)}`

    // 値取得：MEO
    store.commit('setValue', ['meos', 'words', this.meo_words_ary, 'words'])
    store.commit('setValue', ['meos', 'url', func.textEscape($('#meoURL').val()), 'url'])
    store.commit('setValue', ['meos', 'word_num', $('#meoWordNum').val(), 'word_num'])
    store.commit('setValue', ['meos', 'input_meo_train', $('input[name="meo_train"]:checked').val(), 'input_meo_train'])
  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    const sendVal = 
`
◎MEO
店舗URL：${store.state.formData.meos.url}
対策期間：${store.state.formData.meos.input_meo_train}
ワード数：${store.state.formData.meos.word_num}
${this.inputMeoWord}
`
    store.commit('setTalknoteMessage', ['meos', sendVal])
  }
}

export default {
  afrPostMEO
}