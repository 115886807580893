/**
  グループコンポーネント
*/
<template>
  <div class="c-group">
    <slot></slot>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({

  });
</script>

<style lang="scss">
.c-group {
  > * {
    &:not(:last-child):not(:empty) {
      margin-bottom: 12px;
    }
  }
}
</style>
