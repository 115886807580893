/*
* ぽちボタンクリック後の処理
*/ 

class afrPostWel {
  constructor(that) {
    this.that = that
    this.formName = "WEL"

    this.that.$('.search_form_wrap').show()
    //this.that.$('.dateUnnecessary').addClass('required')
  //  this.that.store.commit('setValue', ['formType','wel'])
  //  this.that.store.commit('delValue')
  }

  returnFormName() {
    return this.formName
  }

  getInputData() {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func

    store.commit('setValue', ['wel', 'input_wel_cate_num', func.textEscape($("#welCateNum").val()), 'welCateNum'])
    store.commit('setValue', ['wel', 'input_wel_cate', func.textEscape($("#welCate").val()), 'wel_cate'])
    store.commit('setValue', ['wel', 'input_wel_benefits', func.textEscape($("#welBenefits").val()), 'wel_benefits'])
    store.commit('setValue', ['wel', 'input_wel_other_things', func.textEscape($("#welOtherShared").val()), ''])
  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    const sendVal = 
`
◎WEL
カテゴリ数：${store.state.formData.wel.input_wel_cate_num}
カテゴリ：${store.state.formData.wel.input_wel_cate}
特典：${store.state.formData.wel.input_wel_benefits}
備考欄・特記事項：${store.state.formData.wel.input_wel_other_things}
`

    store.commit('setTalknoteMessage', ['wel', sendVal])
  }
}
  
export default {
  afrPostWel 
}