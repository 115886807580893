/**
  ナビゲーションコンポーネント
*/
<template>
  <ul class="c-nav">
    <li class="c-nav__item -bg-account" v-if="showSignOut">
      <button @click="signOut" class="c-nav__item-content">
        サインアウト
      </button>
      <!-- ?modifyUserData=1付与でユーザーデータ更新 -->
    </li>
    <li class="c-nav__item" v-for="item in groupList" v-bind:class="[ item[0].indexOf('demo') != -1 ? '-bg-demo' : '' ]">
      <a @click="viewForm(`${item[99]}`)" href="#" class="c-nav__item-content">
        {{ item[0] }}
      </a>
    </li>
  </ul>
</template>

<script>
  import { defineComponent } from 'vue';
  import { getAuth, signOut } from "firebase/auth";
  import formDataStore from "../../assets/js/formDataStore";

  export default defineComponent({
    props: {
      isTop: Boolean,
      groupList: Object,
      showSignOut: Boolean,
    },

    methods: {
      async viewForm(path) {
        this.$emit('changeForm')
        this.page = this.$route.path

        if(this.page != '/') {
          location.href = path
        } else {
          await this.$router.push({
            path: path
          });
        }
      },
      signOut() {
        signOut(getAuth());
      }
    },

    watch: {
      $route () {
        //if(this.page != '/') location.reload()
      }
    },

  });
</script>

<style lang="scss" scoped>
.c-nav {
  text-align: left;

  .-bg-account {
    color: $color-text;
    background: $color-gray-1;
    text-decoration: underline;
  }

  .-bg-demo {
    background: $color-blue-2;
  }

  &__item {
    $item: &;

    &:not(:last-child) {
      border-bottom: 1px solid $color-border;
    }

    &-content {
      width: 100%;
      text-align: left;
      display: block;
      padding: 12px 20px;

      @include is-mobile {
        padding-right: 10px;
        padding-left: 10px;
      }

      &[href] {
        @include is-hover {
          text-decoration: underline;
        }
      }
    }

    &-group {
      border-top: 1px solid rgba($color-border, .4);
      padding: 0 20px 0 40px;

      @include is-mobile {
        padding-right: 10px;
        padding-left: 20px;
      }

      #{$item}-content {
        padding: 12px 0;
      }
    }
  }
}
</style>
