/**
  レイアウトコンポーネント
*/
<template>
  <Header></Header>
  <div class="l-wrapper">
    <div class="l-content">
      <Section>
        <p class="c-description">
          お疲れさまです。<br>
          <span v-html="displayName"></span>さん
          <span v-if="isMessage">
            <br>
            <br>
            下のリンクから報告を投稿する<br>
            グループをクリックしてください。
          </span>
        </p>
      </Section>

      <button type="button" class="user-info-update-btn" @click="userInfoUpdate">
        ユーザー情報更新
      </button>

      <Box v-if="isList" :noPadding="true">
        <Nav :groupList="groupList" :isTop="isTop"></Nav>
      </Box>

      <Complete v-if="isComplete" :groupTitle="groupTitle" :groupURL="groupURL" :searchURL="searchURL"></Complete>
      <Loading v-if="isLoading" :loaderText="loaderText"></Loading>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import axios from 'axios';
  import formDataStore from "@/assets/js/formDataStore"

  // コンポーネントを読み込む
  import Header from './organisms/header.vue';
  import Nav from './organisms/nav.vue';
  import Loading from './organisms/loading.vue';
  import Box from './molecules/box.vue';
  import Section from './molecules/section.vue';
  import Complete from './complete.vue';

  export default defineComponent({
    name: 'layout',

    components: {
      Header,
      Nav,
      Box,
      Section,
      Loading,
      Complete,
    },

    data() {
      return {
        isTop: true,
        isMain: true,
        isList: true,
        isForm: false,
        isMessage: true,
        isGroupTitle: false,
        isLoading: false,
        isComplete: false,
        talknoteGroupAddress: "",
        displayName: '',
        groupTitle: '',
        groupURL: '',
        groupList: [],
        searchURL: '',
        message: '',
        resetFunction: {},
        formType: '',
        loaderText: ''
      }
    },

    props: {
      user: Object,
      version: String,
      getDataApiURL: String,
    },

    mounted: function() {

      this.store = formDataStore

      if(this.store.state.groupList.length == 0) {

        // このフォームに直接飛んできたときにフォーム情報取得が完了してないことがあるので同期待ち
        this.store.watch(
          (state, getters) => getters.groupList,
          (newValue, oldValue) => {
            console.log('ページ固有のデータ取得');
            //フォームリスト取得
            this.viewGroupList();
          }
        )
      } else {
        //フォームリスト取得
        this.viewGroupList();
      }
    },

    methods: {
      viewForm(path) {
        this.$router.push({
          path: path
        });
      },

      viewComplete(groupTitle, groupURL, searchURL, message) {
        this.groupTitle = groupTitle;
        this.groupURL = groupURL;
        this.searchURL = searchURL;
        this.message = message;
        this.isComplete = true;
        this.hideForm();
      },

      viewGroupList() {
        let i = 0;
        //const sit = setInterval(() => {
          this.displayName = formDataStore.state.userData.displayName;
          this.groupList = formDataStore.state.groupList;

          if(this.groupList.length > 0) {
            this.isComplete = false;
            this.isForm = false;
            this.isList = true;
            this.isMessage = true;
            this.isGroupTitle = false;
          /*} else {
            i++;
            if(i > 100) {
              clearInterval(sit);
              alert('リスト取得がタイムアウトしました。\nリロードします。');
              window.location.reload();
            }*/
          }
        //}, 50);
      },

      viewLoader(loaderText = '') {
        this.isLoading = !this.isLoading;
        this.loaderText = loaderText;
      },

      async userInfoUpdate() {
        let checkPostFlg = window.confirm('ユーザー情報を更新してもよろしいでしょうか？\n実行すると更新のためログインし直します')
        if(!checkPostFlg) { return }

        // ブラウザのユーザーデータをリセットし、強制的にログイン時のユーザーデータ取得処理を実行させる
        let ls = window.localStorage
        ls.userData = JSON.stringify("")
        this.$router.push('/login')
      }
    }
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.l-wrapper {
  text-align: center;
  max-width: $width-wrapper;
  margin-right: auto;
  margin-left: auto;
  padding: 50px 10px;
}

.l-content {
  text-align: center;
  max-width: $width-content;
  margin-right: auto;
  margin-left: auto;
}

.user-info-update-btn {
  margin-bottom: 10px;
  padding: 8px;
  border: 2px solid rgb(11, 135, 200);
  background-color: rgb(11, 135, 200);
  color: white;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
}

.user-info-update-btn:hover {
  background-color:  rgb(5, 75, 120)
}
</style>