// @ts-ignore
import { createRouter, createWebHistory } from 'vue-router';
import layout from './components/layout.vue';
import googleAuth from './components/googleAuth.vue';
//import NotFound from './components/notFound.vue';

// アポ獲得報告
import formApGain from './components/forms/formApGain.vue';
// 再訪
import formSaihou from './components/forms/formSaihou.vue';
// アポ報告
import formApoReport from './components/forms/formApoReport.vue';
// 受注前回答
import formJuchuumaeKaitou from './components/forms/formJuchuumaeKaitou.vue';
// リスキャン
import formReScheduleCancel from './components/forms/formReScheduleCancel.vue';
// 申込書報告
import formApplications from './components/forms/applications/formApplicationMain.vue';
// 戦略室への要望
import formRequestShift from './components/forms/formRequestShift.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { name: 'HOME', path: '/:form(.*)', component: layout },
    { name: 'LOG IN', path: '/login', component: googleAuth },
    { name: 'アポ獲得報告', path: '/apgain/:type(.*)', component: formApGain },
    { name: 'アポ報告', path: '/aporeport/:type(.*)', component: formApoReport },
    { name: '申込書報告', path: '/applications/:type(.*)', component: formApplications },
    { name: '受注前回答', path: '/juchuumaekaitou/:type(.*)', component: formJuchuumaeKaitou },
    { name: '再訪', path: '/saihou/:type(.*)', component: formSaihou },
    { name: 'リスキャン', path: '/rescan/:type(.*)', component: formReScheduleCancel },
    { name: '戦略室への要望', path: '/request-shift/:type(.*)', component: formRequestShift },
    //{ path: '/:notFound(.*)', component: NotFound }
  ],

  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-exact-active',
});

export default router;