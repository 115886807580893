/**
  Googleアカウント認証用コンポーネント
*/
<template>
  <Header :isMain="isMain"></Header>
  <div class="l-wrapper">
    <div class="l-content">
      <div id="firebaseui-auth-container"></div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import firebase from 'firebase/compat/app';
  //import auth from 'firebase/compat/auth';
  import * as firebaseui from 'firebaseui';
  import 'firebaseui/dist/firebaseui.css';

  const firebaseCommonClass = require('../assets/js/libs/ui/common.js');
  const fc = new firebaseCommonClass.default.firebaseCommon();
  //const CLIENT_ID = '609771332191-17ot8r2rngi7j46b8pomr6h8gr5un32a.apps.googleusercontent.com';
  const CLIENT_ID = '751644996339-gs41htbfmrj6sfug0dobiic0ir9iatv9.apps.googleusercontent.com';

  // コンポーネントを読み込む
  import Header from './organisms/header.vue';

  export default defineComponent({
    name: 'googleAuth',

    components: {
      Header
    },

    data() {
      return {
        isMain: false,
      }
    },

    props: {
      isLogin: Boolean,
    },

    mounted: async function() {
      // FirebaseUI config.
      let uiConfig = {
        signInFlow: 'popup',
        // Url to redirect to after a successful sign-in.
        signInSuccessUrl: '/',
        callbacks: {
          uiShown: () => {
          // Show tenant title and additional display info.
          },
          signInSuccess: function(user, credential, redirectUrl) {
            if (window.opener) {
              // The widget has been opened in a popup, so close the window
              // and return false to not redirect the opener.
              window.close();
              return false;
            } else {
              // The widget has been used in redirect mode, so we redirect to the signInSuccessUrl.
              return true;
            }
          }
        },
        signInOptions: [
          // TODO(developer): Remove the providers you don't need for your app.
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            // Required to enable ID token credentials for this provider.
            clientId: CLIENT_ID
          }
        ],
        // Terms of service url.
        tosUrl: 'https://www.google.com',
        credentialHelper: CLIENT_ID && CLIENT_ID != CLIENT_ID ?
            firebaseui.auth.CredentialHelper.GOOGLE_YOLO :
            firebaseui.auth.CredentialHelper.NONE,
        adminRestrictedOperation: {
          status: fc.getAdminRestrictedOperationStatus()
        }
      };

      // Initialize the FirebaseUI Widget using Firebase.
      var ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
      // The start method will wait until the DOM is loaded to include the FirebaseUI sign-in widget
      // within the element corresponding to the selector specified.
      if (!ui.isPendingRedirect()) {
        ui.start('#firebaseui-auth-container', uiConfig);
      }
    }
  });
</script>
