/*
* ぽちボタンクリック後の処理
*/ 

class afrPostRelo {
  constructor(that) {
    this.that = that
    this.formName = "リロ"

    this.that.$('.search_form_wrap').show()
    //this.that.$('.dateUnnecessary').addClass('required')
    //this.that.store.commit('setValue', ['formType','wel'])
    //this.that.store.commit('delValue')
  }

  returnFormName() {
    return this.formName
  }

  getInputData() {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func

    store.commit('setValue', ['relo', 'input_relo_cate_num', func.textEscape($("#reloCateNum").val()), 'reloCateNum'])
    store.commit('setValue', ['relo', 'input_relo_cate', func.textEscape($("#reloCate").val()), 'relo_cate'])
    store.commit('setValue', ['relo', 'input_relo_benefits', func.textEscape($("#reloBenefits").val()), 'relo_benefits'])
    store.commit('setValue', ['relo', 'input_relo_other_things', func.textEscape($("#reloOtherShared").val()), ''])
  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    const sendVal = 
`
◎リロ
カテゴリ数：${store.state.formData.relo.input_relo_cate_num}
カテゴリ：${store.state.formData.relo.input_relo_cate}
特典：${store.state.formData.relo.input_relo_benefits}
備考欄・特記事項：${store.state.formData.relo.input_relo_other_things}
`
    store.commit('setTalknoteMessage', ['relo', sendVal])
  }

}

export default {
  afrPostRelo 
}