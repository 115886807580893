import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "g-modal" }
const _hoisted_2 = { class: "g-modal__container" }
const _hoisted_3 = ["aria-label"]
const _hoisted_4 = { class: "g-modal__inner" }
const _hoisted_5 = { class: "g-modal__inner-scroller" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("div", {
      class: "g-modal__disabled",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (this.$parent[_ctx.modalName] = !this.$parent[_ctx.modalName]))
    }),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "g-modal__close",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (this.$parent[_ctx.modalName] = !this.$parent[_ctx.modalName])),
        "aria-label": _ctx.modalTitle + 'を閉じる'
      }, null, 8, _hoisted_3),
      _createElementVNode("h2", null, _toDisplayString(_ctx.modalTitle), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ])
  ]))
}