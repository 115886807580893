/**
  再訪用フォームコンポーネント
*/
<template>
  <Header></Header>
  <div class="l-wrapper">
    <div class="l-content">

      <Title
      :groupTitle="groupTitle"
      ></Title>

      <Section>
        <Description>
          お疲れさまです。<br>
          <span v-html="displayName"></span>さん
        </Description>
      </Section>

      <form action="" method="get" id="form_ap" autocomplete="off">

        <Box>
          <Section><h1>案件情報</h1></Section>

          <Column>
            <div class="display_yoko set_btm">
              <span class="chara_bottom" style="display: none;">【</span>
              <div>
                <input type="text" id="ap_type" readonly style="display: none;">
              </div>
              <span class="chara_bottom" style="display: none;">アポ】</span>
            </div>
          </Column>

          <Column class="pd3_parts">
            <label id="status_comment">案件IDで案件検索</label>
            <div class="input_SubjectId">
              <input type="text" id="SubjectId" name="SubjectId" class="required" placeholder="（例）AP111111_111111" pattern="^[0-9A-Za-z_]+$" required>
            </div>
            <div class="display_tate" id="result_titles"></div>
          </Column>
        </Box>

        <Box class="form_wrap">

          <Column class="pd3_parts">
            <label >案件名</label>
            <div class="display_yoko">
              <div class="width100">
                <input type="text" id="ComName" class="required" placeholder="サングローブ株式会社" required>
              </div>
            </div>
          </Column>

          <Column class="pd3_parts">
            <label>商談日時</label>
            <Group class="display_yoko pd3_parts">
              <div class="pd4_r">
                <label id="MtgDateCheck"></label>
                <input type="date" id="MtgDate" class="required " required placeholder="日付選択" min="">
              </div>
              <Flex id="MtgTime" class="display_yoko">
                <input type="text" class="time start f_time required " id="MtgStart" placeholder="開始時間" required/>
                <input type="text" class="time end f_time required " id="MtgEnd" placeholder="終了時間" required/>
              </Flex>
            </Group>
          </Column>

          <Column class="pd3_parts">
            <label >再訪種別</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="checkbox" class="required" id="RevisitType_nego" name="RevisitType"  value="商談" data-id="1">
                  <span class="">商談</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="checkbox" class="required" id="RevisitType_mtg" name="RevisitType"  value="打ち合わせ" data-id="2">
                  <span class="">打ち合わせ</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="checkbox" class="required" id="RevisitType_payment" name="RevisitType"  value="決済" data-id="3">
                  <span class="">決済</span>
                </label>
              </li>
            </ul>
          </Column>

          <Column class="pd3_parts" id="feild_hook" style="display:none;">
            <label >フック</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice" id="riro">
                  <input type="radio" name="Hook" value="R" data-type="リロ" data-id="1">
                    <span>R</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="Hook" value="W" data-type="ウェル" data-id="2">
                  <span>W</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="Hook" value="イツザイ" data-type="イツザイ" data-id="3">
                  <span>イツザイ</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="Hook" value="助成金Lab" data-type="助成金" data-id="5">
                  <span>助成金Lab</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="Hook" value="なし" data-type="" data-id="4">
                  <span >なし</span>
                </label>
              </li>
            </ul>
          </Column>

          <Column class="pd3_parts" id="feild_suggestion" style="display:none;">
            <label >提案内容</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="checkbox" name="Suggestion" data-id="1" id="s_cms" value="CMS">
                  <span >CMS</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="checkbox" name="Suggestion" data-id="2" id="s_ec" value="EC">
                  <span >EC</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="checkbox" name="Suggestion" data-id="3" id="s_itzi" class="itzi" value="イツザイ">
                  <span >イツザイ</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="checkbox" name="Suggestion" data-id="5" id="s_sub" class="s_sub" value="助成金">
                  <span class="required_color">助成金</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="checkbox" name="Suggestion" id="s_seo" value="SEO">
                  <span >SEO</span>
                </label>
              </li>
            </ul>
          </Column>

          <Column class="pd3_parts">
            <label >商談形式</label>
            <div class="c-select">
              <select id="MtgType" class="required">
                <option value="" disabled selected>--商談形式 選択--</option>
                <option class="MtgType" value="オンライン" data-type="オンライン" data-id="1">オンライン</option>
                <option class="MtgType" value="来社" data-type="来社" data-id="2">来社</option>
                <option class="MtgType" value="カフェ" data-type="対面" data-id="3">カフェ　　※カフェ名記載必須</option>
                <option class="MtgType" value="訪問" data-type="対面" data-id="4">訪問</option>
              </select>
            </div>
          </Column>

          <Column id="visit_area" style="display:none;" class="pd3_parts">
            <label>来社エリア</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="VisitArea" id="area_kanto" value="東京" data-type="関東">
                  <span >東京</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="VisitArea" id="area_kansai" value="大阪" data-type="関西">
                  <span >大阪</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="VisitArea" id="area_tokai" value="名古屋" data-type="名古屋">
                  <span >名古屋</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="VisitArea" id="area_kyusyu" value="福岡" data-type="福岡">
                  <span >福岡</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input type="radio" name="VisitArea" id="area_yokohama" value="横浜" data-type="横浜">
                  <span >横浜</span>
                </label>
              </li>
            </ul>
          </Column>

          <Column class="pd3_parts field_taimen" style="display:none;">
            <label >商談住所</label>
            <div class="width100">
              <input type="text" name="pref_name" id ="MtgAdd" placeholder="都道府県・市区町村" pattern="(東京都|北海道|(?:京都|大阪)府|.{2,3}県).*">
            </div>
          </Column>

          <Column style="display:none;" class="field_taimen">
            <label>最寄り駅</label>
            <Flex class="display_yoko set_btm">
              <div class="input_wrap">
                <input type="text" id="Station"  placeholder="西新宿">
              </div>
              <span class="chara_bottom fs9em">駅 徒歩</span>
              <div class="width20">
                <input type="number" id="StationMin" min="1"  placeholder="半角数字で入力" >
              </div>
              <span class="chara_bottom">分</span>
            </Flex>
            <p class="c-info c-notice">※バスを使用する場合の徒歩分数は<br>　バス乗車時間および乗車前後の徒歩分数を合計した時間を記載してください</p>
          </Column>

          <Column class="pd3_parts">
            <label>同行者有無</label>
            <ul class="c-choices">
              <li class="c-choices__item">
                <label class="c-choice">
                  <input class="required" type="radio" name="FollowNeed" value="なし">
                  <span >なし</span>
                </label>
              </li>
              <li class="c-choices__item">
                <label class="c-choice">
                  <input class="required" type="radio" name="FollowNeed" value="あり">
                  <span >あり</span>
                </label>
              </li>
            </ul>
          </Column>

          <Column class="pd3_parts" id="feild_FollowerName" style="display:none;">
            <label>同行者名（苗字 or ビジネスネームを入力すると検索開始します）</label>
            <Column>
              <Flex class="display_yoko">
                <i class="chara_bottom material-icons" style="line-height: 50px;">search</i>
                <input type="text" id="searchFollowerName" placeholder="苗字 or ビジネスネーム" pattern="">
              </Flex>
            </Column>
            <label>候補</label>
          </Column>

          <Column class="pd3_parts">
            <label for="">備考</label>
            <textarea style="width:99%; height:80px;" id="Remark" placeholder="メンションは投稿後のコメントからお願いします。"></textarea>
          </Column>
        </Box>

        <div class="form_wrap">
          <div class="pd2 text_center">
            <Button :tag="'a'" class="waves-effect waves-light btn disabled" id="btn">slackへ送信</Button>
          </div>
          <div class="pd2">
            <textarea style="width:99%; height:300px; display:none" id="txt_box"></textarea>
          </div>
        </div>

      </form>

    </div>
  </div>
  <Loading :class="{ '-active': isLoading }" :loaderText="loaderText"></Loading>
  <Complete v-if="isComplete" :groupTitle="groupTitle" :groupURL="groupURL" :searchURL="searchURL" :calURL="calURL"></Complete>
</template>

<script>
  import { defineComponent } from 'vue'
  import moment from "moment"
  import $ from 'jquery'
  import "jquery-ui";
  import sha256 from 'crypto-js/sha256'

  import formFuncClass from "../../assets/js/formFunctions.class.js"
  import saihouClass from "../../assets/js/saihou.class.js"
  import formDataStore from "../../assets/js/formDataStore"

  // コンポーネントを読み込む
  import Header from '../organisms/header.vue';
  import Title from '../organisms/title.vue';
  import Loading from '../organisms/loading.vue';
  import Complete from '../complete.vue';

  import Variables from '../molecules/variables.vue';
  import Choices from '../molecules/choices.vue';
  import Box from '../molecules/box.vue';
  import Section from '../molecules/section.vue';
  import Column from '../molecules/column.vue';
  import Group from '../molecules/group.vue';
  import Flex from '../molecules/flex.vue';
  import Block from '../molecules/block.vue';

  import Description from '../atoms/description.vue';
  import Label from '../atoms/label.vue';
  import Input from '../atoms/input.vue';
  import Select from '../atoms/select.vue';
  import Textarea from '../atoms/textarea.vue';
  import Button from '../atoms/button.vue';
  import IconAdd from '../atoms/icon-add.vue';
  import IconRemove from '../atoms/icon-remove.vue';

  export default defineComponent({
    name: 'formSaihou',
    data() {
      return {
        isMain:true,
        isList: true,
        isForm: false,
        isMessage: true,
        isGroupTitle: false,
        isLoading: false,
        isComplete: false,
        isProd: true,
        talknoteGroupAddress: '',
        displayName: '',
        userEmail: '',
        groupTitle: '',
        groupURL: '',
        groupList: [],
        searchURL: '',
        message: '',
        resetFunction: {},
        formType: '',
        loaderText: '投稿中',
        calURL: '',
        post_url: "", 
        user: {}
      }
    },

    components: {
      Header,
      Title,
      Loading,
      Complete,

      Variables,
      Choices,
      Box,
      Section,
      Column,
      Group,
      Flex,
      Block,

      Description,
      Label,
      Input,
      Select,
      Textarea,
      Button,
      IconAdd,
      IconRemove,
    },

    methods: {
      init() {
        console.log('フォーム初期化開始')
        this.thisFormParams = this.store.state.groupList.filter((obj) => obj[0].trim() == this.$route.params.type.trim())[0]
        
        this.groupTitle = this.thisFormParams[0]
        this.displayName = this.store.state.userData.displayName
        this.userEmail = this.store.state.userData.email

        // フォームが本番かdemoか
        this.isProd = this.thisFormParams[0].indexOf('demo_') == -1

        // 投稿先のslackチャンネル情報
        this.slackChannel = {
          id: this.thisFormParams[6],
          userID: this.store.state.userData.slackUserID,
          name: this.thisFormParams[7],
          postURL: this.store.state.apis.slackPOSTURL,
          workSpaceURL: this.store.state.apis.slackWorkSpaceURL,
          token: this.store.state.apis.slackToken,
          iconURL: this.store.state.userData.emoji,
          forwardID01: this.thisFormParams[8],
          forwardID02: this.thisFormParams[9],
          errorPostID: this.store.state.apis.errorPostSlackChannelID
        }


        document.documentElement.scrollTop = 0

        const func = new formFuncClass.formFunctions(moment, $, sha256)
        new saihouClass.saihou(this, $, func, moment)
        console.log('フォーム初期化完了')
      },

      viewLoader(loaderText = '') {
        this.isLoading = !this.isLoading
        this.loaderText = loaderText
        console.log('ローディング表示')
      },

      viewComplete(param) {
        this.groupTitle = param.groupTitle;
        this.groupURL = param.groupURL;
        this.searchURL = param.searchURL;
        this.calURL = param.calURL;
        this.isBody = false;
        this.isComplete = true;
        console.log('完了画面表示')
      },
    },

    mounted() {

      this.store = formDataStore

      if(this.store.state.groupList.length == 0) {
        this.store.watch(
          (state, getters) => getters.groupList,
          (newValue, oldValue) => {
            console.log('ページ固有のデータ取得');
            this.init()
          }
        )
      } else {
        this.init()
      }
    },

    setup(_, context) {
      return {}
    }

  });
</script>
