/**
  インプットグループコンポーネント
*/
<template>
  <ul class="c-choices">
    <li class="c-choices__item" v-for="(values, key) in objects">
      <Choice :values="values"></Choice>
    </li>
  </ul>
</template>

<script>
  import { defineComponent } from 'vue';

  // コンポーネントを読み込む
  import Choice from '../atoms/choice.vue';

  export default defineComponent({
    components: {
      Choice,
    },

    props: {
      objects: Object
    }
  });
</script>

<style lang="scss">
.c-choices {
  &:not(.-vertical) {
    display: flex;
    flex-wrap: wrap;
    margin: -5px -10px;
  }

  &:not(.-vertical) &__item {
    padding: 5px 10px;
  }

  &.-vertical &__item:not(:last-child) {
    margin-bottom: 12px;
  }
}
</style>
