/**
  カラムコンポーネント
*/
<template>
  <div class="c-column">
    <slot></slot>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';

  export default defineComponent({

  });
</script>

<style lang="scss">
.c-column {
  text-align: left;

  &:not(:last-child) {
    margin-bottom: 25px;
  }
}
</style>
